/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
	T extends { [key: string]: unknown },
	K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
	| T
	| {
			[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
	  }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	/** Date custom scalar type */
	Date: { input: any; output: any }
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSON: { input: any; output: any }
	/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSONObject: { input: any; output: any }
	Upload: { input: any; output: any }
}

export type ActivitiesFilter = {
	createdAt?: InputMaybe<DateOperators>
	createdBy?: InputMaybe<StringOperators>
	on?: InputMaybe<StringOperators>
	type?: InputMaybe<StringOperators>
}

export type ActivitiesSort = {
	createdAt?: InputMaybe<Scalars['Int']['input']>
}

export type Activity = Node & {
	__typename?: 'Activity'
	_id: Scalars['ID']['output']
	attachments?: Maybe<Array<File>>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy?: Maybe<User>
	description?: Maybe<Scalars['String']['output']>
	ipAddress?: Maybe<Scalars['String']['output']>
	isArchived: Scalars['Boolean']['output']
	metadata?: Maybe<Scalars['JSON']['output']>
	name?: Maybe<Scalars['String']['output']>
	on?: Maybe<ActivityOn>
	type: Scalars['String']['output']
	updatedAt?: Maybe<Scalars['Date']['output']>
	userAgent?: Maybe<Scalars['String']['output']>
}

export type ActivityConnection = Connection & {
	__typename?: 'ActivityConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Activity>
}

export type ActivityInput = {
	attachments?: InputMaybe<Array<Scalars['ID']['input']>>
	company?: InputMaybe<Scalars['ID']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	facility?: InputMaybe<Scalars['ID']['input']>
	on?: InputMaybe<Scalars['ID']['input']>
	onModel?: InputMaybe<Scalars['String']['input']>
	subsidiary?: InputMaybe<Scalars['ID']['input']>
	type: ActivityType
}

export type ActivityOn = Issue | Service

export enum ActivityType {
	Comment = 'COMMENT',
	Delete = 'DELETE',
	Share = 'SHARE',
	Update = 'UPDATE',
}

export type Address = {
	__typename?: 'Address'
	city?: Maybe<Scalars['String']['output']>
	country?: Maybe<Scalars['String']['output']>
	municipality?: Maybe<Scalars['String']['output']>
	number?: Maybe<Scalars['String']['output']>
	state?: Maybe<Scalars['String']['output']>
	street?: Maybe<Scalars['String']['output']>
	suburb?: Maybe<Scalars['String']['output']>
	zipCode?: Maybe<Scalars['String']['output']>
}

export type AddressInput = {
	city?: InputMaybe<Scalars['String']['input']>
	country?: InputMaybe<Scalars['String']['input']>
	municipality?: InputMaybe<Scalars['String']['input']>
	number?: InputMaybe<Scalars['String']['input']>
	state?: InputMaybe<Scalars['String']['input']>
	street?: InputMaybe<Scalars['String']['input']>
	suburb?: InputMaybe<Scalars['String']['input']>
	zipCode?: InputMaybe<Scalars['String']['input']>
}

export type ApplicationInput = {
	dosis?: InputMaybe<Scalars['String']['input']>
	duration?: InputMaybe<Scalars['Int']['input']>
	method?: InputMaybe<Scalars['String']['input']>
	quantity?: InputMaybe<Scalars['Float']['input']>
	sites?: InputMaybe<Array<Scalars['String']['input']>>
}

export type ApplicationType = {
	__typename?: 'ApplicationType'
	dosis?: Maybe<Scalars['String']['output']>
	duration?: Maybe<Scalars['String']['output']>
	method?: Maybe<Scalars['String']['output']>
	plagues?: Maybe<Array<Scalars['String']['output']>>
	quantity?: Maybe<Scalars['Float']['output']>
	sites?: Maybe<Array<Scalars['String']['output']>>
	throwput?: Maybe<Scalars['String']['output']>
}

export type Area = Node & {
	__typename?: 'Area'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived instead */
	active: Scalars['Boolean']['output']
	category?: Maybe<Scalars['Int']['output']>
	company: Client
	createdAt?: Maybe<Scalars['Date']['output']>
	facility: Facility
	isArchived: Scalars['Boolean']['output']
	isExterior: Scalars['Boolean']['output']
	name: Scalars['String']['output']
	services?: Maybe<ServiceConnection>
	type: Scalars['Int']['output']
	units?: Maybe<UnitConnection>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type AreaServicesArgs = {
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<ServicesSort>
}

export type AreaConnection = Connection & {
	__typename?: 'AreaConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Area>
}

export type AreaInput = {
	category?: InputMaybe<Scalars['Int']['input']>
	company?: InputMaybe<Scalars['ID']['input']>
	facility: Scalars['ID']['input']
	isExterior: Scalars['Boolean']['input']
	name: Scalars['String']['input']
	type: Scalars['Int']['input']
}

export type AreasFilter = {
	/** @deprecated */
	active?: InputMaybe<BooleanOperators>
	category?: InputMaybe<StringOperators>
	facility?: InputMaybe<StringOperators>
	isArchived?: InputMaybe<BooleanOperators>
	isExterior?: InputMaybe<BooleanOperators>
	type?: InputMaybe<IntOperators>
}

export type Asset = {
	__typename?: 'Asset'
	key?: Maybe<Scalars['String']['output']>
	url?: Maybe<Scalars['String']['output']>
}

export type AssetInput = {
	key?: InputMaybe<Scalars['String']['input']>
	url?: InputMaybe<Scalars['String']['input']>
}

export type Attachment = {
	__typename?: 'Attachment'
	description?: Maybe<Scalars['String']['output']>
	key?: Maybe<Scalars['String']['output']>
	type?: Maybe<Scalars['String']['output']>
	url?: Maybe<Scalars['String']['output']>
}

export type AuthToken = {
	__typename?: 'AuthToken'
	exp: Scalars['Date']['output']
	id: Scalars['ID']['output']
	jwt: Scalars['String']['output']
}

export type Badge = {
	__typename?: 'Badge'
	count: Scalars['Int']['output']
	service: Scalars['String']['output']
}

export type BadgeConnection = {
	__typename?: 'BadgeConnection'
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes?: Maybe<Array<Badge>>
}

export type BooleanOperators = {
	eq?: InputMaybe<Scalars['Boolean']['input']>
	exists?: InputMaybe<Scalars['Boolean']['input']>
	ne?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CacheControlScope {
	Private = 'PRIVATE',
	Public = 'PUBLIC',
}

export type CancelInvoiceInput = {
	reason?: InputMaybe<Scalars['String']['input']>
	related?: InputMaybe<Scalars['ID']['input']>
}

export type Client = Node & {
	__typename?: 'Client'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived instead */
	active?: Maybe<Scalars['Boolean']['output']>
	address?: Maybe<Address>
	contacts?: Maybe<ContactConnection>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy?: Maybe<User>
	emails?: Maybe<Array<Email>>
	facilities: FacilityConnection
	invoices?: Maybe<Array<Invoice>>
	/** @deprecated Use isArchived instead */
	isActive?: Maybe<Scalars['Boolean']['output']>
	isArchived: Scalars['Boolean']['output']
	legalName: Scalars['String']['output']
	metadata?: Maybe<Scalars['JSON']['output']>
	name: Scalars['String']['output']
	phones?: Maybe<Array<Maybe<Phone>>>
	/** @deprecated Use taxId instead */
	rfc?: Maybe<Scalars['String']['output']>
	role?: Maybe<CompanyRole>
	services: ServiceConnection
	status?: Maybe<Scalars['String']['output']>
	tags?: Maybe<TagConnection>
	taxId?: Maybe<Scalars['String']['output']>
	taxSystem?: Maybe<Scalars['String']['output']>
	transports?: Maybe<ClientTransportConnection>
	type?: Maybe<Array<Scalars['String']['output']>>
	updatedAt?: Maybe<Scalars['Date']['output']>
	website?: Maybe<Scalars['String']['output']>
}

export type ClientFacilitiesArgs = {
	filter?: InputMaybe<FacilitiesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type ClientServicesArgs = {
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type ClientConnection = Connection & {
	__typename?: 'ClientConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Client>
}

export type ClientInput = {
	address: AddressInput
	emails?: InputMaybe<Array<EmailInput>>
	legalName: Scalars['String']['input']
	name: Scalars['String']['input']
	phones?: InputMaybe<Array<InputMaybe<PhoneNumberInput>>>
	/** @deprecated */
	rfc?: InputMaybe<Scalars['String']['input']>
	role: CompanyRole
	roles?: InputMaybe<Array<CompanyRole>>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	taxId?: InputMaybe<Scalars['String']['input']>
	taxSystem?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<Array<Scalars['String']['input']>>
	website?: InputMaybe<Scalars['String']['input']>
}

export type ClientTransport = Node & {
	__typename?: 'ClientTransport'
	_id: Scalars['ID']['output']
	createdAt?: Maybe<Scalars['Date']['output']>
	isArchived: Scalars['Boolean']['output']
	name?: Maybe<Scalars['String']['output']>
	services: ServiceConnection
	unit?: Maybe<Unit>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type ClientTransportServicesArgs = {
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type ClientTransportConnection = Connection & {
	__typename?: 'ClientTransportConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<ClientTransport>
}

export type CompaniesFilter = {
	/** @deprecated */
	active?: InputMaybe<BooleanOperators>
	id?: InputMaybe<StringOperators>
	/** @deprecated */
	isActive?: InputMaybe<BooleanOperators>
	isArchived?: InputMaybe<BooleanOperators>
	legalName?: InputMaybe<StringOperators>
	name?: InputMaybe<StringOperators>
	role?: InputMaybe<CompanyRole>
	search?: InputMaybe<SearchOperators>
	tags?: InputMaybe<StringArrayOperators>
	taxId?: InputMaybe<StringOperators>
	type?: InputMaybe<StringArrayOperators>
}

export enum CompanyRole {
	Customer = 'CUSTOMER',
	Vendor = 'VENDOR',
}

export type CompletionInput = {
	prompt?: InputMaybe<Scalars['String']['input']>
}

export type Connection = {
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Node>
}

export type Contact = Node & {
	__typename?: 'Contact'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived instead */
	active?: Maybe<Scalars['Boolean']['output']>
	charge: Scalars['String']['output']
	comments?: Maybe<Scalars['String']['output']>
	company: Client
	createdAt?: Maybe<Scalars['Date']['output']>
	department: Scalars['String']['output']
	/** @deprecated Use emails instead */
	email?: Maybe<Scalars['String']['output']>
	emails?: Maybe<Array<Email>>
	facilities?: Maybe<FacilityConnection>
	isArchived: Scalars['Boolean']['output']
	/** @deprecated Use phoneNumber instead */
	mobileNumber?: Maybe<Scalars['String']['output']>
	name: Scalars['String']['output']
	/** @deprecated Use phones instead */
	phoneNumber?: Maybe<Scalars['String']['output']>
	phones?: Maybe<Array<Phone>>
	prefix?: Maybe<Scalars['String']['output']>
	/** @deprecated Use tags instead */
	primaryContact?: Maybe<Scalars['Boolean']['output']>
	surname: Scalars['String']['output']
	tags?: Maybe<TagConnection>
	updatedAt?: Maybe<Scalars['Date']['output']>
	user?: Maybe<User>
}

export type ContactConnection = Connection & {
	__typename?: 'ContactConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Contact>
}

export type ContactInput = {
	charge: Scalars['String']['input']
	company?: InputMaybe<Scalars['ID']['input']>
	department: Scalars['String']['input']
	/** @deprecated */
	email?: InputMaybe<Scalars['String']['input']>
	emails?: InputMaybe<Array<EmailInput>>
	name: Scalars['String']['input']
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	phones?: InputMaybe<Array<PhoneInput>>
	prefix?: InputMaybe<Scalars['String']['input']>
	primaryContact?: InputMaybe<Scalars['Boolean']['input']>
	surname: Scalars['String']['input']
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ContactsFilter = {
	/** @deprecated */
	active?: InputMaybe<BooleanOperators>
	company?: InputMaybe<StringOperators>
	facility?: InputMaybe<StringOperators>
	id?: InputMaybe<StringOperators>
	/** @deprecated */
	inactive?: InputMaybe<Scalars['Boolean']['input']>
	isArchived?: InputMaybe<BooleanOperators>
	search?: InputMaybe<SearchOperators>
	tags?: InputMaybe<StringArrayOperators>
	user?: InputMaybe<StringOperators>
}

export type ContactsSort = {
	name?: InputMaybe<Scalars['Int']['input']>
}

export type CreateCustomerPortalSessionInput = {
	flowType?: InputMaybe<CustomerPortalSessionFlowType>
}

export type CreateStripeAccountLinkInput = {
	origin: Scalars['String']['input']
}

export type Credentials = {
	__typename?: 'Credentials'
	accessToken: AuthToken
	idToken: AuthToken
	organization?: Maybe<Organization>
	refreshToken: AuthToken
	/** @deprecated Use accessToken instead */
	token?: Maybe<Scalars['String']['output']>
	user: User
}

export type CredentialsInput = {
	email: Scalars['String']['input']
	password: Scalars['String']['input']
}

export enum Currency {
	Mxn = 'MXN',
	Usd = 'USD',
}

export type CustomField = {
	__typename?: 'CustomField'
	name?: Maybe<Scalars['String']['output']>
	value?: Maybe<Scalars['String']['output']>
}

export type CustomerPortalSession = {
	__typename?: 'CustomerPortalSession'
	id: Scalars['String']['output']
	livemode: Scalars['Boolean']['output']
	return_url: Scalars['String']['output']
	url: Scalars['String']['output']
}

export enum CustomerPortalSessionFlowType {
	SubscriptionUpdate = 'SUBSCRIPTION_UPDATE',
}

export enum DateGranurality {
	Day_1 = 'DAY_1',
	Week_1 = 'WEEK_1',
}

export type DateOperators = {
	eq?: InputMaybe<Scalars['String']['input']>
	exists?: InputMaybe<Scalars['Boolean']['input']>
	gt?: InputMaybe<Scalars['String']['input']>
	gte?: InputMaybe<Scalars['String']['input']>
	lt?: InputMaybe<Scalars['String']['input']>
	lte?: InputMaybe<Scalars['String']['input']>
	ne?: InputMaybe<Scalars['String']['input']>
}

export type Device = {
	__typename?: 'Device'
	createdAt?: Maybe<Scalars['Date']['output']>
	id?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	notificationsToken?: Maybe<Scalars['String']['output']>
	platform?: Maybe<Platform>
}

export type DeviceInput = {
	id?: InputMaybe<Scalars['String']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	notificationsToken?: InputMaybe<Scalars['String']['input']>
	platform?: InputMaybe<Platform>
}

export type Discount = {
	__typename?: 'Discount'
	_id: Scalars['ID']['output']
}

export type DocumentMemos = {
	__typename?: 'DocumentMemos'
	QT?: Maybe<Scalars['String']['output']>
}

export type DocumentMemosInput = {
	QT?: InputMaybe<Scalars['String']['input']>
}

export type DownloadFile = {
	__typename?: 'DownloadFile'
	key?: Maybe<Scalars['String']['output']>
	thumbnailUrl?: Maybe<Scalars['String']['output']>
	url?: Maybe<Scalars['String']['output']>
}

export type Email = {
	__typename?: 'Email'
	_id?: Maybe<Scalars['ID']['output']>
	address?: Maybe<Scalars['String']['output']>
	isVerified?: Maybe<Scalars['Boolean']['output']>
	label: Scalars['String']['output']
	value: Scalars['String']['output']
}

export type EmailInput = {
	label: Scalars['String']['input']
	value: Scalars['String']['input']
}

export type EmailNotificationPreferenceInput = {
	services?: InputMaybe<NotificationPreferenceInput>
}

export type EmailNotificationSetting = {
	__typename?: 'EmailNotificationSetting'
	services?: Maybe<NotificationSetting>
}

export type Employee = Node & {
	__typename?: 'Employee'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived instead */
	active: Scalars['Boolean']['output']
	address?: Maybe<Address>
	charge?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	curp?: Maybe<Scalars['String']['output']>
	email?: Maybe<Scalars['String']['output']>
	/** @deprecated Use ssn instead */
	imss?: Maybe<Scalars['String']['output']>
	isArchived: Scalars['Boolean']['output']
	name: Scalars['String']['output']
	phoneNumber?: Maybe<Scalars['String']['output']>
	services?: Maybe<ServiceConnection>
	signature?: Maybe<Attachment>
	ssn?: Maybe<Scalars['String']['output']>
	surname: Scalars['String']['output']
	tags?: Maybe<Array<Tag>>
	taxId?: Maybe<Scalars['String']['output']>
	taxSystem?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
	user?: Maybe<User>
}

export type EmployeeServicesArgs = {
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type EmployeeConnection = Connection & {
	__typename?: 'EmployeeConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Employee>
}

export type EmployeeInput = {
	address: AddressInput
	charge?: InputMaybe<Scalars['String']['input']>
	curp: Scalars['String']['input']
	email?: InputMaybe<Scalars['String']['input']>
	imss?: InputMaybe<Scalars['String']['input']>
	name: Scalars['String']['input']
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	rfc?: InputMaybe<Scalars['String']['input']>
	ssn?: InputMaybe<Scalars['String']['input']>
	surname: Scalars['String']['input']
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	taxId?: InputMaybe<Scalars['String']['input']>
	taxSystem?: InputMaybe<Scalars['String']['input']>
}

export type EmployeesFilter = {
	/** @deprecated */
	active?: InputMaybe<BooleanOperators>
	isArchived?: InputMaybe<BooleanOperators>
	search?: InputMaybe<SearchOperators>
	user?: InputMaybe<StringOperators>
}

export enum ExportFormat {
	Csv = 'csv',
	Html = 'html',
	Xlsx = 'xlsx',
}

export type ExportInput = {
	client?: InputMaybe<Array<Scalars['ID']['input']>>
	companyType?: InputMaybe<CompanyRole>
	date?: InputMaybe<DateOperators>
	format: ExportFormat
	model: ExportModel
	type?: InputMaybe<Array<Scalars['Int']['input']>>
}

export enum ExportModel {
	Client = 'Client',
	Contact = 'Contact',
	Employee = 'Employee',
	Service = 'Service',
	Vendor = 'Vendor',
}

export type FacilitiesFilter = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	company?: InputMaybe<Scalars['ID']['input']>
	isArchived?: InputMaybe<BooleanOperators>
	isTransport?: InputMaybe<Scalars['Boolean']['input']>
}

export type Facility = Node & {
	__typename?: 'Facility'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived instead */
	active: Scalars['Boolean']['output']
	address?: Maybe<Address>
	areas?: Maybe<AreaConnection>
	company: Client
	contacts: ContactConnection
	createdAt?: Maybe<Scalars['Date']['output']>
	emails?: Maybe<Array<Scalars['String']['output']>>
	files?: Maybe<Array<File>>
	invoices?: Maybe<Invoice>
	isArchived: Scalars['Boolean']['output']
	isTransport: Scalars['Boolean']['output']
	location?: Maybe<GeoPolygon>
	name: Scalars['String']['output']
	notifications?: Maybe<Array<Notification>>
	/** @deprecated Use phones instead */
	phoneNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>
	phones?: Maybe<Array<Maybe<Phone>>>
	/** @deprecated No longer supported */
	services?: Maybe<Array<Service>>
	status?: Maybe<Scalars['Int']['output']>
	toleranceLimits: ToleranceLimits
	type?: Maybe<FacilityType>
	units?: Maybe<UnitConnection>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type FacilityServicesArgs = {
	month?: InputMaybe<Scalars['Int']['input']>
	year?: InputMaybe<Scalars['Int']['input']>
}

export type FacilityConnection = Connection & {
	__typename?: 'FacilityConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Facility>
}

export type FacilityInput = {
	address?: InputMaybe<AddressInput>
	company: Scalars['ID']['input']
	emails?: InputMaybe<Array<Scalars['String']['input']>>
	isTransport?: InputMaybe<Scalars['Boolean']['input']>
	name: Scalars['String']['input']
	phones?: InputMaybe<Array<PhoneInput>>
	type?: InputMaybe<FacilityType>
}

export enum FacilityType {
	Commerce = 'COMMERCE',
	Home = 'HOME',
	Industry = 'INDUSTRY',
	Transport = 'TRANSPORT',
}

export type File = Node & {
	__typename?: 'File'
	_id: Scalars['ID']['output']
	acceptance?: Maybe<Scalars['Int']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	/** @deprecated Use isArchived instead */
	deleted: Scalars['Boolean']['output']
	description?: Maybe<Scalars['String']['output']>
	facility?: Maybe<Facility>
	isArchived: Scalars['Boolean']['output']
	/** @deprecated Use url instead */
	key?: Maybe<Scalars['String']['output']>
	lastUpdated?: Maybe<Scalars['Date']['output']>
	mimetype?: Maybe<Scalars['String']['output']>
	name: Scalars['String']['output']
	parent?: Maybe<File>
	signature?: Maybe<Scalars['String']['output']>
	size?: Maybe<Scalars['Int']['output']>
	thumbnail?: Maybe<Scalars['String']['output']>
	type?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
	url?: Maybe<Scalars['String']['output']>
}

export enum FileAccess {
	Private = 'PRIVATE',
	Public = 'PUBLIC',
	Secret = 'SECRET',
}

export type FileConnection = Connection & {
	__typename?: 'FileConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<File>
}

export type FileInput = {
	acceptance?: InputMaybe<Scalars['Int']['input']>
	activity?: InputMaybe<Scalars['ID']['input']>
	company?: InputMaybe<Scalars['ID']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	facility?: InputMaybe<Scalars['ID']['input']>
	key?: InputMaybe<Scalars['String']['input']>
	mimetype?: InputMaybe<Scalars['String']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	parent?: InputMaybe<Scalars['ID']['input']>
	service?: InputMaybe<Scalars['ID']['input']>
	size?: InputMaybe<Scalars['Int']['input']>
	type?: InputMaybe<Scalars['String']['input']>
	url?: InputMaybe<Scalars['String']['input']>
}

export type FilesFilter = {
	company?: InputMaybe<StringOperators>
	/** @deprecated */
	deleted?: InputMaybe<BooleanOperators>
	facility?: InputMaybe<StringOperators>
	id?: InputMaybe<StringOperators>
	isArchived?: InputMaybe<BooleanOperators>
	parent?: InputMaybe<StringOperators>
	search?: InputMaybe<SearchOperators>
	type?: InputMaybe<StringOperators>
}

export type FilesOrder = {
	type?: InputMaybe<Scalars['String']['input']>
}

export type FilesSort = {
	date?: InputMaybe<DateOperators>
}

export type GenerateCalendarInput = {
	facility: Scalars['ID']['input']
	year: Scalars['Int']['input']
}

export type GeoPolygon = {
	__typename?: 'GeoPolygon'
	coordinates?: Maybe<
		Array<Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>>
	>
	type?: Maybe<Scalars['String']['output']>
}

export type Image = {
	__typename?: 'Image'
	url?: Maybe<Scalars['String']['output']>
}

export type InsightsChart = {
	__typename?: 'InsightsChart'
	id: Scalars['ID']['output']
	size?: Maybe<Scalars['String']['output']>
	title?: Maybe<Scalars['String']['output']>
}

export type IntOperators = {
	eq?: InputMaybe<Scalars['Int']['input']>
	in?: InputMaybe<Array<Scalars['Int']['input']>>
	ne?: InputMaybe<Scalars['Int']['input']>
	nin?: InputMaybe<Array<Scalars['Int']['input']>>
}

export enum IntegrationProviders {
	Belvo = 'BELVO',
	Facturapi = 'FACTURAPI',
	Paypal = 'PAYPAL',
	Stripe = 'STRIPE',
}

export enum IntegrationStatus {
	Active = 'active',
	Inactive = 'inactive',
	Pending = 'pending',
	TokenRequired = 'tokenRequired',
	Unconfirmed = 'unconfirmed',
}

export type IntegrationTokenInput = {
	provider?: InputMaybe<IntegrationProviders>
	token?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<Scalars['String']['input']>
}

export type IntegrationTokenRequestInput = {
	provider?: InputMaybe<IntegrationProviders>
}

export type IntelligenceChoise = {
	__typename?: 'IntelligenceChoise'
	text?: Maybe<Scalars['String']['output']>
}

export type IntelligenceResult = {
	__typename?: 'IntelligenceResult'
	_id: Scalars['ID']['output']
	choices?: Maybe<Array<Maybe<IntelligenceChoise>>>
}

export type Invoice = Node & {
	__typename?: 'Invoice'
	_id: Scalars['ID']['output']
	cancelation?: Maybe<InvoiceCancelation>
	collectedAt?: Maybe<Scalars['Date']['output']>
	comments?: Maybe<Scalars['String']['output']>
	company?: Maybe<Client>
	complements?: Maybe<Array<InvoiceComplement>>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy?: Maybe<User>
	currency?: Maybe<Scalars['String']['output']>
	date?: Maybe<Scalars['Date']['output']>
	discountAmount?: Maybe<Scalars['Float']['output']>
	employee?: Maybe<Employee>
	file?: Maybe<DownloadFile>
	folio?: Maybe<Scalars['String']['output']>
	isArchived: Scalars['Boolean']['output']
	isCanceled?: Maybe<Scalars['Boolean']['output']>
	isDraft?: Maybe<Scalars['Boolean']['output']>
	isSaleNote?: Maybe<Scalars['Boolean']['output']>
	items?: Maybe<Array<InvoiceItem>>
	name?: Maybe<Scalars['String']['output']>
	number?: Maybe<ResourceNumber>
	paymentConditions?: Maybe<Scalars['String']['output']>
	paymentForm?: Maybe<Scalars['String']['output']>
	paymentMethod?: Maybe<InvoicePaymentMethod>
	paymentStatus?: Maybe<Scalars['String']['output']>
	paymentType?: Maybe<Scalars['String']['output']>
	payroll?: Maybe<InvoicesPayroll>
	providerResourceId?: Maybe<Scalars['String']['output']>
	relations?: Maybe<Array<InvoiceRelation>>
	series?: Maybe<Scalars['String']['output']>
	services?: Maybe<ServiceConnection>
	showSanitaryRegistry?: Maybe<Scalars['Boolean']['output']>
	stamp?: Maybe<InvoiceStamp>
	subTotal?: Maybe<Scalars['Float']['output']>
	tags?: Maybe<Array<Tag>>
	taxAmount?: Maybe<Scalars['Float']['output']>
	total?: Maybe<Scalars['Float']['output']>
	totalAmount?: Maybe<Scalars['Float']['output']>
	transactions?: Maybe<Array<Maybe<Transaction>>>
	type: InvoiceType
	updatedAt?: Maybe<Scalars['Date']['output']>
	use?: Maybe<Scalars['String']['output']>
	/** @deprecated Use providerResourceId instead */
	uuid?: Maybe<Scalars['String']['output']>
	version?: Maybe<Scalars['Float']['output']>
	xml?: Maybe<Scalars['String']['output']>
}

export type InvoiceCancelation = {
	__typename?: 'InvoiceCancelation'
	reason?: Maybe<Scalars['String']['output']>
	related?: Maybe<Invoice>
	status?: Maybe<InvoiceCancelationStatus>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export enum InvoiceCancelationStatus {
	Accepted = 'ACCEPTED',
	Expired = 'EXPIRED',
	None = 'NONE',
	Pending = 'PENDING',
	Rejected = 'REJECTED',
}

export type InvoiceComplement = {
	__typename?: 'InvoiceComplement'
	paymentForm?: Maybe<Scalars['String']['output']>
	relations?: Maybe<Array<InvoiceComplementRelation>>
	type?: Maybe<Scalars['String']['output']>
}

export type InvoiceComplementInput = {
	paymentForm?: InputMaybe<Scalars['String']['input']>
	relations?: InputMaybe<Array<InvoiceComplementRelationInput>>
}

export type InvoiceComplementRelation = {
	__typename?: 'InvoiceComplementRelation'
	amount?: Maybe<Scalars['Float']['output']>
	currency?: Maybe<Scalars['String']['output']>
	installment?: Maybe<Scalars['Int']['output']>
	invoice?: Maybe<Invoice>
	lastBalance?: Maybe<Scalars['Float']['output']>
	taxes?: Maybe<Array<Tax>>
}

export type InvoiceComplementRelationInput = {
	amount?: InputMaybe<Scalars['Float']['input']>
	currency?: InputMaybe<Scalars['String']['input']>
	installment?: InputMaybe<Scalars['Int']['input']>
	invoice?: InputMaybe<Scalars['ID']['input']>
	lastBalance?: InputMaybe<Scalars['Float']['input']>
	taxes?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum InvoiceComplementType {
	Custom = 'CUSTOM',
	Payment = 'PAYMENT',
	Payroll = 'PAYROLL',
}

export type InvoiceConnection = Connection & {
	__typename?: 'InvoiceConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Invoice>
}

export type InvoiceInput = {
	comments?: InputMaybe<Scalars['String']['input']>
	company?: InputMaybe<Scalars['ID']['input']>
	complements?: InputMaybe<Array<InvoiceComplementInput>>
	conditions?: InputMaybe<Scalars['String']['input']>
	currency?: InputMaybe<Currency>
	date: Scalars['Date']['input']
	employee?: InputMaybe<Scalars['ID']['input']>
	exchange?: InputMaybe<Scalars['Float']['input']>
	folio?: InputMaybe<Scalars['String']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	isDraft?: InputMaybe<Scalars['Boolean']['input']>
	isSaleNote?: InputMaybe<Scalars['Boolean']['input']>
	items: Array<InvoiceItemInput>
	paymentConditions?: InputMaybe<Scalars['String']['input']>
	paymentForm?: InputMaybe<Scalars['String']['input']>
	paymentMethod?: InputMaybe<InvoicePaymentMethod>
	relations?: InputMaybe<Array<InvoiceRelationInput>>
	series?: InputMaybe<Scalars['String']['input']>
	showSanitaryRegistry?: InputMaybe<Scalars['Boolean']['input']>
	stamp?: InputMaybe<Scalars['Boolean']['input']>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	type: InvoiceType
	use?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceItem = {
	__typename?: 'InvoiceItem'
	amountSubtotal?: Maybe<Scalars['Float']['output']>
	amountTotal?: Maybe<Scalars['Float']['output']>
	currency?: Maybe<Currency>
	description?: Maybe<Scalars['String']['output']>
	discounts?: Maybe<Array<Scalars['ID']['output']>>
	price: Scalars['Float']['output']
	product: Product
	quantity: Scalars['Int']['output']
	taxes?: Maybe<Array<Scalars['ID']['output']>>
}

export type InvoiceItemInput = {
	currency?: InputMaybe<Currency>
	description?: InputMaybe<Scalars['String']['input']>
	discounts?: InputMaybe<Array<Scalars['ID']['input']>>
	price?: InputMaybe<Scalars['Float']['input']>
	product?: InputMaybe<Scalars['ID']['input']>
	quantity?: InputMaybe<Scalars['Int']['input']>
	subTotal?: InputMaybe<Scalars['Float']['input']>
	taxes?: InputMaybe<Array<Scalars['ID']['input']>>
	total?: InputMaybe<Scalars['Float']['input']>
}

export enum InvoicePaymentMethod {
	Multiple = 'MULTIPLE',
	Single = 'SINGLE',
}

export type InvoiceRelation = {
	__typename?: 'InvoiceRelation'
	invoices?: Maybe<Array<Invoice>>
	type: Scalars['String']['output']
}

export type InvoiceRelationInput = {
	invoices?: InputMaybe<Array<Scalars['ID']['input']>>
	type: Scalars['String']['input']
}

export type InvoiceStamp = {
	__typename?: 'InvoiceStamp'
	date?: Maybe<Scalars['Date']['output']>
	number?: Maybe<Scalars['String']['output']>
	provider?: Maybe<Scalars['String']['output']>
	seal?: Maybe<Scalars['String']['output']>
	signature?: Maybe<Scalars['String']['output']>
	uuid?: Maybe<Scalars['String']['output']>
}

export enum InvoiceType {
	Inflow = 'INFLOW',
	Outflow = 'OUTFLOW',
	Payment = 'PAYMENT',
	Payroll = 'PAYROLL',
	Transfer = 'TRANSFER',
}

export type InvoicesFilter = {
	_id?: InputMaybe<StringOperators>
	cashFlow?: InputMaybe<StringOperators>
	collectedAt?: InputMaybe<DateOperators>
	company?: InputMaybe<StringOperators>
	date?: InputMaybe<DateOperators>
	isArchived?: InputMaybe<BooleanOperators>
	isCanceled?: InputMaybe<BooleanOperators>
	isDraft?: InputMaybe<BooleanOperators>
	items?: InputMaybe<StringOperators>
	paymentMethod?: InputMaybe<StringOperators>
	paymentType?: InputMaybe<StringOperators>
	provider?: InputMaybe<StringOperators>
	providerResourceId?: InputMaybe<StringOperators>
	receiverId?: InputMaybe<StringOperators>
	search?: InputMaybe<SearchOperators>
	senderId?: InputMaybe<StringOperators>
	services?: InputMaybe<StringOperators>
	type?: InputMaybe<StringOperators>
}

export type InvoicesPayroll = {
	__typename?: 'InvoicesPayroll'
	amount?: Maybe<Scalars['Float']['output']>
	dateFrom?: Maybe<Scalars['String']['output']>
	dateTo?: Maybe<Scalars['String']['output']>
	days?: Maybe<Scalars['String']['output']>
	paymentDate?: Maybe<Scalars['Date']['output']>
	version?: Maybe<Scalars['Float']['output']>
}

export type InvoicesSort = {
	collectedAt?: InputMaybe<Scalars['Int']['input']>
	date?: InputMaybe<Scalars['Int']['input']>
}

/** Issues */
export type Issue = Node & {
	__typename?: 'Issue'
	_id: Scalars['ID']['output']
	assignedTo: Array<User>
	attachments?: Maybe<FileConnection>
	client?: Maybe<Client>
	closedAt?: Maybe<Scalars['Date']['output']>
	closedBy?: Maybe<User>
	comments?: Maybe<ActivityConnection>
	contacts?: Maybe<Array<User>>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy: User
	date?: Maybe<Scalars['Date']['output']>
	description?: Maybe<Scalars['String']['output']>
	dueDate?: Maybe<Scalars['Date']['output']>
	facility?: Maybe<Facility>
	isArchived: Scalars['Boolean']['output']
	location?: Maybe<Point>
	name?: Maybe<Scalars['String']['output']>
	number?: Maybe<Scalars['Int']['output']>
	priority?: Maybe<Scalars['Int']['output']>
	rating?: Maybe<Scalars['Int']['output']>
	services?: Maybe<ServiceConnection>
	status: IssueStatus
	tags?: Maybe<TagConnection>
	title: Scalars['String']['output']
	type: Scalars['String']['output']
	units?: Maybe<UnitConnection>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type IssueConnection = Connection & {
	__typename?: 'IssueConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Issue>
}

export type IssueInput = {
	assignedTo?: InputMaybe<Array<Scalars['ID']['input']>>
	attachments?: InputMaybe<Array<Scalars['ID']['input']>>
	client?: InputMaybe<Scalars['ID']['input']>
	contacts?: InputMaybe<Array<Scalars['ID']['input']>>
	description?: InputMaybe<Scalars['String']['input']>
	dueDate?: InputMaybe<Scalars['Date']['input']>
	facility?: InputMaybe<Scalars['ID']['input']>
	location?: InputMaybe<LocationInput>
	name?: InputMaybe<Scalars['String']['input']>
	priority?: InputMaybe<Scalars['Int']['input']>
	rating?: InputMaybe<Scalars['Int']['input']>
	services?: InputMaybe<Array<Scalars['ID']['input']>>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	title: Scalars['String']['input']
	type: Scalars['String']['input']
	units?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum IssueStatus {
	Cancelled = 'cancelled',
	Closed = 'closed',
	Open = 'open',
	Overdue = 'overdue',
}

export type IssuesFilter = {
	assignedTo?: InputMaybe<StringOperators>
	client?: InputMaybe<StringOperators>
	date?: InputMaybe<DateOperators>
	facility?: InputMaybe<StringOperators>
	priority?: InputMaybe<IntOperators>
	search?: InputMaybe<SearchOperators>
	services?: InputMaybe<StringOperators>
	status?: InputMaybe<StringOperators>
	tags?: InputMaybe<StringOperators>
	type?: InputMaybe<StringOperators>
	units?: InputMaybe<StringOperators>
}

export type IssuesSort = {
	createdAt?: InputMaybe<Scalars['Int']['input']>
	date?: InputMaybe<Scalars['Int']['input']>
	dueDate?: InputMaybe<Scalars['Int']['input']>
}

export type LocalEmployee = {
	__typename?: 'LocalEmployee'
	_id?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	surname?: Maybe<Scalars['String']['output']>
}

export type LocalTeams = {
	__typename?: 'LocalTeams'
	_id?: Maybe<Scalars['String']['output']>
	employees?: Maybe<Array<Maybe<LocalEmployee>>>
}

export type Location = Node & {
	__typename?: 'Location'
	_id: Scalars['ID']['output']
	activity?: Maybe<Scalars['String']['output']>
	altitude?: Maybe<Scalars['Float']['output']>
	at: Scalars['Date']['output']
	battery?: Maybe<Scalars['Float']['output']>
	coordinates: Array<Scalars['Float']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	heading?: Maybe<Scalars['Float']['output']>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	isMoving?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	speed?: Maybe<Scalars['Float']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type LocationConnection = Connection & {
	__typename?: 'LocationConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Location>
}

export type LocationInput = {
	altitude?: InputMaybe<Scalars['Float']['input']>
	coordinates: Array<Scalars['Float']['input']>
	heading?: InputMaybe<Scalars['Float']['input']>
	speed?: InputMaybe<Scalars['Float']['input']>
}

export type LocationsFilter = {
	date?: InputMaybe<DateOperators>
	user?: InputMaybe<Scalars['ID']['input']>
}

export type LoginCredential = {
	__typename?: 'LoginCredential'
	id: Scalars['String']['output']
	transports?: Maybe<Array<Scalars['String']['output']>>
	type: Scalars['String']['output']
}

export type LoginInput = {
	credentials?: InputMaybe<CredentialsInput>
	device?: InputMaybe<DeviceInput>
	oAuth?: InputMaybe<OAuthInput>
	organization: Scalars['String']['input']
	webAuthn?: InputMaybe<WebAuthInput>
}

export type LoginRequest = {
	__typename?: 'LoginRequest'
	allowCredentials?: Maybe<Array<LoginCredential>>
	challenge?: Maybe<Scalars['String']['output']>
	rpId?: Maybe<Scalars['String']['output']>
	timeout?: Maybe<Scalars['Int']['output']>
	userVerification?: Maybe<Scalars['String']['output']>
}

export type MxInvoicingInput = {
	/** @deprecated */
	cer?: InputMaybe<Scalars['Upload']['input']>
	cerPath: Scalars['String']['input']
	/** @deprecated */
	key?: InputMaybe<Scalars['Upload']['input']>
	keyPath: Scalars['String']['input']
	password: Scalars['String']['input']
	taxSystem: Scalars['String']['input']
}

export type MagicLinkInput = {
	hostname: Scalars['String']['input']
	organization?: InputMaybe<Scalars['String']['input']>
	user: Scalars['String']['input']
}

export type Message = {
	__typename?: 'Message'
	_id: Scalars['ID']['output']
}

export type MessageTemplate = {
	__typename?: 'MessageTemplate'
	_id: Scalars['ID']['output']
	body: Scalars['String']['output']
	createdAt: Scalars['Date']['output']
	createdBy: User
	design?: Maybe<Scalars['String']['output']>
	name: Scalars['String']['output']
	relatedModel: Scalars['ID']['output']
	subject?: Maybe<Scalars['String']['output']>
	type: Scalars['String']['output']
	updatedAt: Scalars['Date']['output']
}

export type MessageTemplateConnection = {
	__typename?: 'MessageTemplateConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes?: Maybe<Array<MessageTemplate>>
}

export type MessageTemplateInput = {
	body: Scalars['String']['input']
	design?: InputMaybe<Scalars['String']['input']>
	name: Scalars['String']['input']
	relatedModel: Scalars['String']['input']
	subject?: InputMaybe<Scalars['String']['input']>
	type: Scalars['String']['input']
}

export type MessageToFilter = {
	companies?: InputMaybe<CompaniesFilter>
	contacts?: InputMaybe<ContactsFilter>
	facilities?: InputMaybe<FacilitiesFilter>
	users?: InputMaybe<UsersFilter>
}

export enum MessageType {
	Email = 'EMAIL',
	Sms = 'SMS',
}

export type Metadata = {
	__typename?: 'Metadata'
	priceId?: Maybe<Scalars['String']['output']>
	productId?: Maybe<Scalars['String']['output']>
}

export type Mutation = {
	__typename?: 'Mutation'
	activity: Activity
	area: Area
	calendar: Scalars['String']['output']
	cancelInvoice: Invoice
	client: Client
	closeIssue: Issue
	contact: Contact
	createCustomerPortalSession: CustomerPortalSession
	createStripeAccountLink: Scalars['String']['output']
	createStripeAccountLoginLink: Scalars['String']['output']
	createWebAuth: Scalars['String']['output']
	employee: Employee
	export: Scalars['String']['output']
	facility: Facility
	fetchTransactions: Scalars['Boolean']['output']
	file: File
	invite: User
	invoice: Invoice
	issue: Issue
	login: Credentials
	magicLink: Scalars['String']['output']
	messageTemplate: MessageTemplate
	organization: Credentials
	presignedUploadUrl: PresignedUrl
	product: Product
	quote: Quote
	requestIntegrationToken: Scalars['String']['output']
	requestLogin: LoginRequest
	requestResetPassword: Scalars['String']['output']
	requestVerifyUserAttribute?: Maybe<Scalars['Boolean']['output']>
	resetPassword: Credentials
	role: Role
	sendMessage: Scalars['Boolean']['output']
	sendReport?: Maybe<Array<DownloadFile>>
	service: Array<Service>
	share: ShareResult
	signOut: Scalars['Boolean']['output']
	signUp: Credentials
	tag: Tag
	transaction: Transaction
	unit: Unit
	unitService?: Maybe<UnitService>
	updateAreas: Array<Area>
	updateClient: Client
	updateContact: Contact
	updateEmployee: Employee
	updateFacility: Facility
	/** @deprecated Use file instead */
	updateFile: File
	updateInvoice: Invoice
	updateIssue: Issue
	updateMessageTemplate: MessageTemplate
	updateOrganization: Organization
	updateOrganizationInvoicing: Organization
	updateProduct: Product
	updateQuote: Quote
	updateRole: Role
	updateService: Service
	updateServices: Array<Service>
	updateTag: Tag
	updateTransaction: Transaction
	updateUnit: Unit
	updateUnitService?: Maybe<UnitService>
	updateUser: User
	/** @deprecated Use file instead */
	uploadFile: File
	verifyUserAttribute?: Maybe<Scalars['Boolean']['output']>
}

export type MutationActivityArgs = {
	input: ActivityInput
}

export type MutationAreaArgs = {
	input: AreaInput
}

export type MutationCalendarArgs = {
	input: GenerateCalendarInput
}

export type MutationCancelInvoiceArgs = {
	id: Scalars['ID']['input']
	input: CancelInvoiceInput
}

export type MutationClientArgs = {
	input: ClientInput
}

export type MutationCloseIssueArgs = {
	id: Scalars['ID']['input']
}

export type MutationContactArgs = {
	input: ContactInput
}

export type MutationCreateCustomerPortalSessionArgs = {
	input?: InputMaybe<CreateCustomerPortalSessionInput>
}

export type MutationCreateStripeAccountLinkArgs = {
	input: CreateStripeAccountLinkInput
}

export type MutationEmployeeArgs = {
	input: EmployeeInput
}

export type MutationExportArgs = {
	input: ExportInput
}

export type MutationFacilityArgs = {
	input: FacilityInput
}

export type MutationFileArgs = {
	input: FileInput
}

export type MutationInviteArgs = {
	input: UserInviteInput
}

export type MutationInvoiceArgs = {
	input: InvoiceInput
}

export type MutationIssueArgs = {
	input: IssueInput
}

export type MutationLoginArgs = {
	input: LoginInput
}

export type MutationMagicLinkArgs = {
	input: MagicLinkInput
}

export type MutationMessageTemplateArgs = {
	input: MessageTemplateInput
}

export type MutationOrganizationArgs = {
	input: OrganizationInput
}

export type MutationPresignedUploadUrlArgs = {
	input: PresignedUploadUrlInput
}

export type MutationProductArgs = {
	input: ProductInput
}

export type MutationQuoteArgs = {
	input: QuoteInput
}

export type MutationRequestIntegrationTokenArgs = {
	input: IntegrationTokenRequestInput
}

export type MutationRequestLoginArgs = {
	input: RequestLoginInput
}

export type MutationRequestResetPasswordArgs = {
	input: RequestResetPasswordInput
}

export type MutationRequestVerifyUserAttributeArgs = {
	input: VerifyUserAttributeInput
	user: Scalars['ID']['input']
}

export type MutationResetPasswordArgs = {
	input: ResetPasswordInput
}

export type MutationRoleArgs = {
	input: RoleInput
}

export type MutationSendMessageArgs = {
	input: SendMessageInput
}

export type MutationSendReportArgs = {
	input: SendReportInput
}

export type MutationServiceArgs = {
	input: ServiceInput
}

export type MutationShareArgs = {
	input: ShareInput
}

export type MutationSignOutArgs = {
	input?: InputMaybe<SignOutInput>
}

export type MutationSignUpArgs = {
	input: SignUpInput
}

export type MutationTagArgs = {
	input: TagInput
}

export type MutationTransactionArgs = {
	input: TransactionInput
}

export type MutationUnitArgs = {
	input: UnitInput
}

export type MutationUnitServiceArgs = {
	input: UnitServiceInput
}

export type MutationUpdateAreasArgs = {
	input: UpdateAreasInput
}

export type MutationUpdateClientArgs = {
	id: Scalars['ID']['input']
	input: UpdateClientInput
}

export type MutationUpdateContactArgs = {
	id: Scalars['ID']['input']
	input: UpdateContactInput
}

export type MutationUpdateEmployeeArgs = {
	id: Scalars['ID']['input']
	input: UpdateEmployeeInput
}

export type MutationUpdateFacilityArgs = {
	id: Scalars['ID']['input']
	input: UpdateFacilityInput
}

export type MutationUpdateFileArgs = {
	id: Scalars['ID']['input']
	input: UpdateFileInput
}

export type MutationUpdateInvoiceArgs = {
	id: Scalars['ID']['input']
	input: UpdateInvoiceInput
}

export type MutationUpdateIssueArgs = {
	id: Scalars['ID']['input']
	input: UpdateIssueInput
}

export type MutationUpdateMessageTemplateArgs = {
	id: Scalars['ID']['input']
	input?: InputMaybe<UpdateMessageTemplateInput>
}

export type MutationUpdateOrganizationArgs = {
	id: Scalars['ID']['input']
	input: UpdateOrganizationInput
}

export type MutationUpdateOrganizationInvoicingArgs = {
	id: Scalars['ID']['input']
	input: UpdateOrganizationInvoicingInput
}

export type MutationUpdateProductArgs = {
	id: Scalars['ID']['input']
	input: UpdateProductInput
}

export type MutationUpdateQuoteArgs = {
	id: Scalars['ID']['input']
	input: UpdateQuoteInput
}

export type MutationUpdateRoleArgs = {
	id: Scalars['ID']['input']
	input: UpdateRoleInput
}

export type MutationUpdateServiceArgs = {
	id: Scalars['ID']['input']
	input: UpdateServiceInput
}

export type MutationUpdateServicesArgs = {
	ids: Array<Scalars['ID']['input']>
	input: UpdateServicesInput
}

export type MutationUpdateTagArgs = {
	id: Scalars['ID']['input']
	input: UpdateTagInput
}

export type MutationUpdateTransactionArgs = {
	id: Scalars['ID']['input']
	input: UpdateTransactionInput
}

export type MutationUpdateUnitArgs = {
	id: Scalars['ID']['input']
	input: UpdateUnitInput
}

export type MutationUpdateUnitServiceArgs = {
	id: Scalars['ID']['input']
	input: UpdateUnitServiceInput
}

export type MutationUpdateUserArgs = {
	id: Scalars['ID']['input']
	input: UpdateUserInput
}

export type MutationUploadFileArgs = {
	input: UploadFileInput
}

export type MutationVerifyUserAttributeArgs = {
	input: VerifyUserAttributeInput
	user: Scalars['ID']['input']
}

export type Node = {
	_id: Scalars['ID']['output']
	createdAt?: Maybe<Scalars['Date']['output']>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type Notification = Node & {
	__typename?: 'Notification'
	_id: Scalars['ID']['output']
	body?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	employee?: Maybe<Employee>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	priority?: Maybe<Scalars['Int']['output']>
	resource?: Maybe<Scalars['String']['output']>
	type: Scalars['String']['output']
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type NotificationConnection = Connection & {
	__typename?: 'NotificationConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Notification>
}

export type NotificationFilter = {
	facility?: InputMaybe<Scalars['ID']['input']>
	unseen?: InputMaybe<Scalars['Boolean']['input']>
}

export type NotificationPreferenceInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>
	webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type NotificationSetting = {
	__typename?: 'NotificationSetting'
	enabled: Scalars['Boolean']['output']
	webhookUrl?: Maybe<Scalars['String']['output']>
}

export type NotificationsOrgPreferences = {
	__typename?: 'NotificationsOrgPreferences'
	email?: Maybe<EmailNotificationSetting>
	slack?: Maybe<NotificationSetting>
}

export type OAuthInput = {
	code?: InputMaybe<Scalars['String']['input']>
	provider: Scalars['String']['input']
	token: Scalars['String']['input']
}

export type OrgInformation = {
	__typename?: 'OrgInformation'
	description?: Maybe<Scalars['String']['output']>
	email?: Maybe<Scalars['String']['output']>
	phoneNumber?: Maybe<Scalars['String']['output']>
	website?: Maybe<Scalars['String']['output']>
}

export type OrgInformationInput = {
	description?: InputMaybe<Scalars['String']['input']>
	email?: InputMaybe<Scalars['String']['input']>
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	website?: InputMaybe<Scalars['String']['input']>
}

export type OrgInsights = {
	__typename?: 'OrgInsights'
	charts?: Maybe<Array<Maybe<InsightsChart>>>
}

export type OrgIntegration = {
	__typename?: 'OrgIntegration'
	_id?: Maybe<Scalars['ID']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	expiresAt?: Maybe<Scalars['Date']['output']>
	extra?: Maybe<Scalars['JSON']['output']>
	name?: Maybe<Scalars['String']['output']>
	provider: IntegrationProviders
	providerResourceId?: Maybe<Scalars['String']['output']>
	publicKey?: Maybe<Scalars['String']['output']>
	status?: Maybe<IntegrationStatus>
	type: Scalars['String']['output']
}

export type OrgPreferences = {
	__typename?: 'OrgPreferences'
	billingEmail?: Maybe<Scalars['String']['output']>
	documentMemos?: Maybe<DocumentMemos>
	language?: Maybe<Scalars['String']['output']>
	notifications?: Maybe<NotificationsOrgPreferences>
	slackWebHook?: Maybe<Scalars['String']['output']>
	timeZone?: Maybe<Scalars['String']['output']>
}

export type OrgPreferencesInput = {
	billingEmail?: InputMaybe<Scalars['String']['input']>
	documentMemos?: InputMaybe<DocumentMemosInput>
	language?: InputMaybe<Scalars['String']['input']>
	notifications?: InputMaybe<OrgPreferencesNotificationsInput>
	timeZone?: InputMaybe<Scalars['String']['input']>
}

export type OrgPreferencesNotificationsInput = {
	email?: InputMaybe<EmailNotificationPreferenceInput>
	slack?: InputMaybe<NotificationPreferenceInput>
}

export type OrgSubscriptionInput = {
	paymentMethodId?: InputMaybe<Scalars['String']['input']>
	priceId?: InputMaybe<Scalars['String']['input']>
	quantity?: InputMaybe<Scalars['Int']['input']>
}

export type OrgTransportInput = {
	_id?: InputMaybe<Scalars['ID']['input']>
	cost?: InputMaybe<Scalars['Float']['input']>
	name: Scalars['String']['input']
	surfaceArea?: InputMaybe<Scalars['Float']['input']>
}

export type Organization = Node & {
	__typename?: 'Organization'
	_id: Scalars['ID']['output']
	address?: Maybe<Address>
	apps?: Maybe<Array<Scalars['String']['output']>>
	createdAt?: Maybe<Scalars['Date']['output']>
	discounts?: Maybe<Array<Discount>>
	features?: Maybe<Array<Scalars['String']['output']>>
	information?: Maybe<OrgInformation>
	insights?: Maybe<OrgInsights>
	integrations?: Maybe<Array<OrgIntegration>>
	isArchived: Scalars['Boolean']['output']
	isInvoicingActive?: Maybe<Scalars['Boolean']['output']>
	isPayoutOnboardingCompleted?: Maybe<Scalars['Boolean']['output']>
	legalName: Scalars['String']['output']
	logo?: Maybe<Asset>
	logoLight?: Maybe<Asset>
	name: Scalars['String']['output']
	owner: User
	preferences?: Maybe<OrgPreferences>
	slug: Scalars['String']['output']
	subscription?: Maybe<Subscription>
	taxId?: Maybe<Scalars['String']['output']>
	taxSystem?: Maybe<Scalars['String']['output']>
	taxes?: Maybe<Array<Tax>>
	type?: Maybe<OrganizationType>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type OrganizationInput = {
	address?: InputMaybe<AddressInput>
	businessName: Scalars['String']['input']
	industry?: InputMaybe<Scalars['String']['input']>
	information?: InputMaybe<OrgInformationInput>
	legalName: Scalars['String']['input']
	owner: OwnerInput
	size?: InputMaybe<Scalars['String']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<OrganizationType>
	website?: InputMaybe<Scalars['String']['input']>
}

export enum OrganizationType {
	Company = 'COMPANY',
	Individual = 'INDIVIDUAL',
}

export type OwnerInput = {
	email: Scalars['String']['input']
	name: Scalars['String']['input']
	password: Scalars['String']['input']
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	surname: Scalars['String']['input']
}

export type Payment = {
	__typename?: 'Payment'
	cost?: Maybe<Scalars['Float']['output']>
	discount?: Maybe<Scalars['Float']['output']>
	/** @deprecated No longer supported */
	method?: Maybe<Scalars['Int']['output']>
	status?: Maybe<Scalars['Int']['output']>
	tax?: Maybe<Scalars['Float']['output']>
}

export type PaymentInput = {
	cost?: InputMaybe<Scalars['Float']['input']>
	discount?: InputMaybe<Scalars['Float']['input']>
	status?: InputMaybe<Scalars['Int']['input']>
	tax?: InputMaybe<Scalars['Float']['input']>
}

export type Permission = {
	__typename?: 'Permission'
	action: Scalars['String']['output']
	effect: Scalars['String']['output']
	resource: Scalars['String']['output']
}

export type PermissionsInput = {
	action: Scalars['String']['input']
	effect: Scalars['String']['input']
	resource: Scalars['String']['input']
}

export type Phone = {
	__typename?: 'Phone'
	_id?: Maybe<Scalars['ID']['output']>
	isVerified?: Maybe<Scalars['Boolean']['output']>
	label: Scalars['String']['output']
	number: Scalars['String']['output']
	value?: Maybe<Scalars['String']['output']>
}

export type PhoneInput = {
	label: Scalars['String']['input']
	number: Scalars['String']['input']
}

export type PhoneNumberInput = {
	label?: InputMaybe<Scalars['String']['input']>
	number: Scalars['String']['input']
}

export type Plague = Node & {
	__typename?: 'Plague'
	_id: Scalars['ID']['output']
	createdAt?: Maybe<Scalars['Date']['output']>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export enum PlagueCategory {
	Birds = 'BIRDS',
	Insects = 'INSECTS',
	Rodents = 'RODENTS',
	Wildlife = 'WILDLIFE',
}

export type PlagueConnection = Connection & {
	__typename?: 'PlagueConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Plague>
}

export type PlagueCount = {
	__typename?: 'PlagueCount'
	area?: Maybe<Area>
	count: Scalars['Int']['output']
	plagueType: Scalars['String']['output']
	plagues?: Maybe<PlagueConnection>
	type?: Maybe<Scalars['String']['output']>
}

export type PlagueCountInput = {
	area?: InputMaybe<Scalars['ID']['input']>
	count: Scalars['Int']['input']
	type: Scalars['String']['input']
}

export type PlaguePresence = Node & {
	__typename?: 'PlaguePresence'
	_id: Scalars['ID']['output']
	createdAt?: Maybe<Scalars['Date']['output']>
	date: Scalars['Date']['output']
	isArchived?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	plagueCount?: Maybe<Array<PlagueCount>>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type PlaguePresenceConnection = Connection & {
	__typename?: 'PlaguePresenceConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<PlaguePresence>
}

export type PlaguePresenceFilter = {
	areas?: InputMaybe<StringArrayOperators>
	date?: InputMaybe<DateOperators>
	facilities?: InputMaybe<StringArrayOperators>
	plagueCategory?: InputMaybe<PlagueCategory>
}

export type PlaguePresenceOptions = {
	granularity?: InputMaybe<DateGranurality>
}

export enum Platform {
	Android = 'ANDROID',
	Ios = 'IOS',
	Web = 'WEB',
}

export type Point = {
	__typename?: 'Point'
	coordinates?: Maybe<Array<Scalars['Float']['output']>>
}

export type PolygonInput = {
	coordinates: Array<Array<Array<Scalars['Float']['input']>>>
}

export type Preferences = {
	__typename?: 'Preferences'
	language: Scalars['String']['output']
	summaringFreq: Array<Scalars['Int']['output']>
}

export type PresignedUploadUrlInput = {
	access?: InputMaybe<FileAccess>
	mimetype: Scalars['String']['input']
	name?: InputMaybe<Scalars['String']['input']>
}

export type PresignedUrl = {
	__typename?: 'PresignedUrl'
	key: Scalars['String']['output']
	url: Scalars['String']['output']
}

export type PrivacyUserPreferences = {
	__typename?: 'PrivacyUserPreferences'
	tracking?: Maybe<TrackingUserPreferences>
}

export type PrivacyUserPreferencesInput = {
	tracking?: InputMaybe<TrackingUserPreferencesInput>
}

export type Product = Node & {
	__typename?: 'Product'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived instead */
	active?: Maybe<Scalars['Boolean']['output']>
	activeIngredient?: Maybe<Scalars['String']['output']>
	applications?: Maybe<Array<ApplicationType>>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy?: Maybe<User>
	currency?: Maybe<Currency>
	description?: Maybe<Scalars['String']['output']>
	hasTax?: Maybe<Scalars['Boolean']['output']>
	isArchived: Scalars['Boolean']['output']
	metadata?: Maybe<Metadata>
	name: Scalars['String']['output']
	notes?: Maybe<Scalars['String']['output']>
	price?: Maybe<Scalars['Float']['output']>
	producer?: Maybe<Scalars['String']['output']>
	sanitaryRegistry?: Maybe<Scalars['String']['output']>
	shortDescription?: Maybe<Scalars['String']['output']>
	subType?: Maybe<Scalars['String']['output']>
	tags?: Maybe<Array<Tag>>
	tax?: Maybe<ProductTax>
	tradeName?: Maybe<Scalars['String']['output']>
	type?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type ProductApplicationInput = {
	dosis?: InputMaybe<Scalars['String']['input']>
	method?: InputMaybe<Scalars['String']['input']>
	plagues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	throwput?: InputMaybe<Scalars['String']['input']>
}

export type ProductConnection = Connection & {
	__typename?: 'ProductConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Product>
}

export type ProductInput = {
	activeIngredient?: InputMaybe<Scalars['String']['input']>
	currency?: InputMaybe<Currency>
	description?: InputMaybe<Scalars['String']['input']>
	hasTax?: InputMaybe<Scalars['Boolean']['input']>
	name: Scalars['String']['input']
	notes?: InputMaybe<Scalars['String']['input']>
	price?: InputMaybe<Scalars['Float']['input']>
	producer?: InputMaybe<Scalars['String']['input']>
	sanitaryRegistry?: InputMaybe<Scalars['String']['input']>
	shortDescription?: InputMaybe<Scalars['String']['input']>
	subType?: InputMaybe<Scalars['String']['input']>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	tax?: InputMaybe<ProductTaxInput>
	tradeName?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<Scalars['String']['input']>
}

export type ProductTax = {
	__typename?: 'ProductTax'
	key?: Maybe<Scalars['String']['output']>
	unitKey?: Maybe<Scalars['String']['output']>
}

export type ProductTaxInput = {
	key: Scalars['String']['input']
	unitKey: Scalars['String']['input']
}

export type ProductsFilter = {
	id?: InputMaybe<StringOperators>
	producer?: InputMaybe<Scalars['String']['input']>
	search?: InputMaybe<SearchOperators>
	tags?: InputMaybe<StringOperators>
}

export type Query = {
	__typename?: 'Query'
	_empty?: Maybe<Scalars['String']['output']>
	activities: ActivityConnection
	activity: Activity
	area: Area
	areas: AreaConnection
	badges?: Maybe<BadgeConnection>
	client: Client
	clients: ClientConnection
	completedPayoutOnboarding?: Maybe<Scalars['Boolean']['output']>
	completion?: Maybe<IntelligenceResult>
	contact: Contact
	contacts: ContactConnection
	employee: Employee
	employees: EmployeeConnection
	facilities: FacilityConnection
	facility: Facility
	file: File
	files: FileConnection
	invoice: Invoice
	invoices: InvoiceConnection
	issue: Issue
	issues: IssueConnection
	locations: LocationConnection
	messageTemplate: MessageTemplate
	messageTemplates: MessageTemplateConnection
	notifications?: Maybe<NotificationConnection>
	oAuth?: Maybe<Scalars['String']['output']>
	organization: Organization
	organizations: Array<Organization>
	plaguePresence: PlaguePresenceConnection
	product: Product
	products: ProductConnection
	quote: Quote
	quotes: QuoteConnection
	role?: Maybe<Role>
	roles?: Maybe<RoleConnection>
	search: SearchResultConnection
	selectedFacility?: Maybe<Scalars['String']['output']>
	self: User
	service: Service
	services: ServiceConnection
	tags: TagConnection
	teams?: Maybe<LocalTeams>
	transaction: Transaction
	transactions: TransactionConnection
	transport: Transport
	transports: TransportConnection
	unit: Unit
	unitServices: UnitServiceConnection
	units: UnitConnection
	user: User
}

export type QueryActivitiesArgs = {
	filter?: InputMaybe<ActivitiesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<ActivitiesSort>
}

export type QueryActivityArgs = {
	id: Scalars['ID']['input']
}

export type QueryAreaArgs = {
	id: Scalars['ID']['input']
}

export type QueryAreasArgs = {
	filter?: InputMaybe<AreasFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryClientArgs = {
	id: Scalars['ID']['input']
}

export type QueryClientsArgs = {
	filter?: InputMaybe<CompaniesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCompletionArgs = {
	input: CompletionInput
}

export type QueryContactArgs = {
	id: Scalars['ID']['input']
}

export type QueryContactsArgs = {
	filter?: InputMaybe<ContactsFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<ContactsSort>
}

export type QueryEmployeeArgs = {
	id: Scalars['ID']['input']
}

export type QueryEmployeesArgs = {
	filter?: InputMaybe<EmployeesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryFacilitiesArgs = {
	filter?: InputMaybe<FacilitiesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryFacilityArgs = {
	id: Scalars['ID']['input']
}

export type QueryFileArgs = {
	id: Scalars['ID']['input']
}

export type QueryFilesArgs = {
	filter?: InputMaybe<FilesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	order?: InputMaybe<FilesOrder>
	sort?: InputMaybe<FilesSort>
}

export type QueryInvoiceArgs = {
	id: Scalars['ID']['input']
}

export type QueryInvoicesArgs = {
	filter?: InputMaybe<InvoicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<InvoicesSort>
}

export type QueryIssueArgs = {
	id: Scalars['ID']['input']
}

export type QueryIssuesArgs = {
	filter?: InputMaybe<IssuesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<IssuesSort>
}

export type QueryLocationsArgs = {
	filter?: InputMaybe<LocationsFilter>
}

export type QueryMessageTemplateArgs = {
	id: Scalars['ID']['input']
}

export type QueryMessageTemplatesArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryNotificationsArgs = {
	filter?: InputMaybe<NotificationFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryOrganizationArgs = {
	id: Scalars['ID']['input']
}

export type QueryPlaguePresenceArgs = {
	filter: PlaguePresenceFilter
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	options?: InputMaybe<PlaguePresenceOptions>
}

export type QueryProductArgs = {
	id: Scalars['ID']['input']
}

export type QueryProductsArgs = {
	filter?: InputMaybe<ProductsFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryQuoteArgs = {
	id: Scalars['ID']['input']
}

export type QueryQuotesArgs = {
	filter?: InputMaybe<QuotesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRoleArgs = {
	id: Scalars['ID']['input']
}

export type QueryRolesArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchArgs = {
	input: SearchInput
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryServiceArgs = {
	id: Scalars['ID']['input']
}

export type QueryServicesArgs = {
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<ServicesSort>
}

export type QueryTagsArgs = {
	filter?: InputMaybe<TagsFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTransactionArgs = {
	id: Scalars['ID']['input']
}

export type QueryTransactionsArgs = {
	filter?: InputMaybe<TransactionsFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<TransactionsSort>
}

export type QueryTransportArgs = {
	id: Scalars['ID']['input']
}

export type QueryTransportsArgs = {
	filter?: InputMaybe<TransportFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type QueryUnitArgs = {
	id: Scalars['ID']['input']
}

export type QueryUnitServicesArgs = {
	filter?: InputMaybe<UnitServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryUnitsArgs = {
	filter?: InputMaybe<UnitsFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryUserArgs = {
	id: Scalars['ID']['input']
}

export type Quote = Node & {
	__typename?: 'Quote'
	_id: Scalars['ID']['output']
	company?: Maybe<Client>
	contact?: Maybe<Contact>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy?: Maybe<User>
	currency?: Maybe<Currency>
	customFields?: Maybe<Array<CustomField>>
	date?: Maybe<Scalars['Date']['output']>
	description?: Maybe<Scalars['String']['output']>
	expiresAt?: Maybe<Scalars['Date']['output']>
	file?: Maybe<DownloadFile>
	hideBreakdown?: Maybe<Scalars['Boolean']['output']>
	invoice?: Maybe<Invoice>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	items?: Maybe<Array<InvoiceItem>>
	memo?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	number?: Maybe<ResourceNumber>
	status: QuoteStatus
	subTotal?: Maybe<Scalars['Float']['output']>
	tags?: Maybe<Array<Tag>>
	total?: Maybe<Scalars['Float']['output']>
	transactions?: Maybe<Array<Transaction>>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type QuoteConnection = Connection & {
	__typename?: 'QuoteConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Quote>
}

export type QuoteCustomFieldInput = {
	name?: InputMaybe<Scalars['String']['input']>
	value?: InputMaybe<Scalars['String']['input']>
}

export type QuoteInput = {
	company?: InputMaybe<Scalars['ID']['input']>
	contact?: InputMaybe<Scalars['ID']['input']>
	currency?: InputMaybe<Currency>
	customFields?: InputMaybe<Array<QuoteCustomFieldInput>>
	expiresAt?: InputMaybe<Scalars['Date']['input']>
	hideBreakdown?: InputMaybe<Scalars['Boolean']['input']>
	invoice?: InputMaybe<Scalars['ID']['input']>
	items?: InputMaybe<Array<InvoiceItemInput>>
	memo?: InputMaybe<Scalars['String']['input']>
	status: QuoteStatus
	subTotal?: InputMaybe<Scalars['Float']['input']>
	total?: InputMaybe<Scalars['Float']['input']>
}

export enum QuoteStatus {
	Canceled = 'canceled',
	Draft = 'draft',
	Expired = 'expired',
	Open = 'open',
	Paid = 'paid',
}

export type QuotesFilter = {
	company?: InputMaybe<StringOperators>
	id?: InputMaybe<StringOperators>
	search?: InputMaybe<SearchOperators>
	tags?: InputMaybe<StringOperators>
}

export type RecipientShareInput = {
	contact: Scalars['ID']['input']
	medium: ShareMedium
}

export type RecipientShareResult = {
	__typename?: 'RecipientShareResult'
	companyCount: Scalars['Int']['output']
	contactsCount: Scalars['Int']['output']
	emailsCount: Scalars['Int']['output']
	facilityCount: Scalars['Int']['output']
	phoneNumbersCount: Scalars['Int']['output']
}

export type RegisterWebAuthInput = {
	attestation: Scalars['String']['input']
	clientDataJSON: Scalars['String']['input']
	id: Scalars['String']['input']
	name?: InputMaybe<Scalars['String']['input']>
	rawId: Scalars['String']['input']
	type?: InputMaybe<Scalars['String']['input']>
}

export enum RelatedModel {
	Company = 'COMPANY',
	Contact = 'CONTACT',
	Employee = 'EMPLOYEE',
	Facility = 'FACILITY',
	User = 'USER',
}

export type RequestLoginInput = {
	organization?: InputMaybe<Scalars['ID']['input']>
	type: RequestLoginType
	user: Scalars['ID']['input']
}

export enum RequestLoginType {
	Email = 'EMAIL',
	WebAuth = 'WEB_AUTH',
}

export type RequestResetPasswordInput = {
	email: Scalars['String']['input']
	hostname: Scalars['String']['input']
	organization: Scalars['String']['input']
}

export type ResetPasswordInput = {
	hostname?: InputMaybe<Scalars['String']['input']>
	password: Scalars['String']['input']
	token: Scalars['String']['input']
}

export type ResourceNumber = {
	__typename?: 'ResourceNumber'
	company?: Maybe<Scalars['String']['output']>
	organization?: Maybe<Scalars['String']['output']>
}

export type ResourceShareInput = {
	assetTypes: Array<Scalars['String']['input']>
	id: Scalars['ID']['input']
	type: ShareResourceType
}

export type Role = Node & {
	__typename?: 'Role'
	_id: Scalars['ID']['output']
	createdAt?: Maybe<Scalars['Date']['output']>
	description?: Maybe<Scalars['String']['output']>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	metadata?: Maybe<Scalars['JSON']['output']>
	name?: Maybe<Scalars['String']['output']>
	permissions: Array<Permission>
	title?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
	users?: Maybe<UserConnection>
}

export type RoleConnection = Connection & {
	__typename?: 'RoleConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Role>
}

export type RoleInput = {
	description?: InputMaybe<Scalars['String']['input']>
	metadata?: InputMaybe<Scalars['JSON']['input']>
	permissions: Array<PermissionsInput>
	title: Scalars['String']['input']
}

export enum Subscription_Status {
	Active = 'ACTIVE',
	Canceled = 'CANCELED',
	Incomplete = 'INCOMPLETE',
	IncompleteExpired = 'INCOMPLETE_EXPIRED',
	PastDue = 'PAST_DUE',
	Trialing = 'TRIALING',
	Unpaid = 'UNPAID',
}

export type SearchInput = {
	areaType?: InputMaybe<StringOperators>
	collection?: InputMaybe<StringOperators>
	company?: InputMaybe<StringOperators>
	facility?: InputMaybe<StringOperators>
	stamp?: InputMaybe<BooleanOperators>
	text?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<StringOperators>
}

export type SearchOperators = {
	autocomplete?: InputMaybe<Scalars['String']['input']>
}

export type SearchResult =
	| Area
	| Client
	| Contact
	| Employee
	| Facility
	| File
	| Invoice
	| Issue
	| Product
	| Quote
	| Tag
	| Transaction
	| Unit

export type SearchResultConnection = {
	__typename?: 'SearchResultConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<SearchResult>
}

export type SendMessageInput = {
	body: Scalars['String']['input']
	files?: InputMaybe<Array<Scalars['ID']['input']>>
	relatedModel?: InputMaybe<RelatedModel>
	subject: Scalars['String']['input']
	template?: InputMaybe<Scalars['ID']['input']>
	to: MessageToFilter
	type?: InputMaybe<MessageType>
}

export type SendReportInput = {
	facility: Scalars['ID']['input']
	formats: Array<Scalars['Int']['input']>
	from: Scalars['String']['input']
	to: Scalars['String']['input']
	type: Scalars['Int']['input']
}

export type Service = Node & {
	__typename?: 'Service'
	_id: Scalars['ID']['output']
	application?: Maybe<ApplicationType>
	areas: AreaConnection
	arrivedAt?: Maybe<Scalars['Date']['output']>
	/** @deprecated Use 'employees' */
	assignedTo: EmployeeConnection
	attachments?: Maybe<Array<File>>
	checks: UnitServiceConnection
	client: Client
	comments?: Maybe<Scalars['String']['output']>
	completedAt?: Maybe<Scalars['Date']['output']>
	completionTime?: Maybe<Scalars['Float']['output']>
	corrective?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy: User
	date: Scalars['Date']['output']
	dose?: Maybe<Scalars['Int']['output']>
	employees: EmployeeConnection
	enRouteAt?: Maybe<Scalars['Date']['output']>
	estimatedCompletionTime?: Maybe<Scalars['Float']['output']>
	eta?: Maybe<Scalars['Int']['output']>
	expirationAt?: Maybe<Scalars['Date']['output']>
	facility?: Maybe<Facility>
	frequency?: Maybe<Scalars['Int']['output']>
	invoices?: Maybe<InvoiceConnection>
	isArchived: Scalars['Boolean']['output']
	lot?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	notes?: Maybe<Scalars['String']['output']>
	payment?: Maybe<Payment>
	plagues?: Maybe<Array<PlagueCount>>
	products?: Maybe<ProductConnection>
	productsCount: Scalars['Int']['output']
	progress?: Maybe<Scalars['Float']['output']>
	showAmount?: Maybe<Scalars['Boolean']['output']>
	showSignature?: Maybe<Scalars['Boolean']['output']>
	signatureKey?: Maybe<Scalars['String']['output']>
	status?: Maybe<Scalars['Int']['output']>
	tags?: Maybe<TagConnection>
	thumbnail?: Maybe<Scalars['String']['output']>
	transport?: Maybe<TransportApplication>
	type: Scalars['Int']['output']
	units?: Maybe<UnitConnection>
	updatedAt?: Maybe<Scalars['Date']['output']>
	url?: Maybe<Scalars['String']['output']>
}

export type ServiceEtaArgs = {
	location: LocationInput
}

export type ServiceConnection = Connection & {
	__typename?: 'ServiceConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Service>
}

export type ServiceInput = {
	application?: InputMaybe<ApplicationInput>
	areas?: InputMaybe<Array<Scalars['ID']['input']>>
	attachments?: InputMaybe<Array<Scalars['ID']['input']>>
	client: Scalars['ID']['input']
	comments?: InputMaybe<Scalars['String']['input']>
	completedAt?: InputMaybe<Scalars['Date']['input']>
	createdBy?: InputMaybe<Scalars['ID']['input']>
	date?: InputMaybe<Scalars['Date']['input']>
	dates?: InputMaybe<Array<Scalars['String']['input']>>
	employees?: InputMaybe<Array<Scalars['ID']['input']>>
	expirationAt?: InputMaybe<Scalars['Date']['input']>
	facility?: InputMaybe<Scalars['ID']['input']>
	frequency?: InputMaybe<Scalars['Int']['input']>
	invoices?: InputMaybe<Array<Scalars['ID']['input']>>
	notes?: InputMaybe<Scalars['String']['input']>
	notify?: InputMaybe<Scalars['Boolean']['input']>
	payment?: InputMaybe<PaymentInput>
	products?: InputMaybe<Array<Scalars['ID']['input']>>
	showAmount?: InputMaybe<Scalars['Boolean']['input']>
	showSignature?: InputMaybe<Scalars['Boolean']['input']>
	team?: InputMaybe<Array<Scalars['ID']['input']>>
	transport?: InputMaybe<TransportInput>
	type: Scalars['Int']['input']
	unit?: InputMaybe<Scalars['ID']['input']>
}

export type ServicesFilter = {
	client?: InputMaybe<StringOperators>
	completedAt?: InputMaybe<DateOperators>
	date?: InputMaybe<DateOperators>
	employee?: InputMaybe<StringOperators>
	facility?: InputMaybe<StringOperators>
	/** @deprecated */
	from?: InputMaybe<Scalars['String']['input']>
	invoices?: InputMaybe<StringOperators>
	tags?: InputMaybe<StringArrayOperators>
	/** @deprecated */
	to?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<IntOperators>
	unit?: InputMaybe<StringOperators>
	url?: InputMaybe<StringOperators>
}

export type ServicesSort = {
	date?: InputMaybe<SortDirection>
}

export type Session = {
	__typename?: 'Session'
	expiresAt: Scalars['ID']['output']
	id: Scalars['ID']['output']
	lastUsedAt?: Maybe<Scalars['ID']['output']>
	name?: Maybe<Scalars['String']['output']>
	notificationsToken?: Maybe<Scalars['String']['output']>
}

export type ShareInput = {
	message?: InputMaybe<Scalars['String']['input']>
	recipients: Array<RecipientShareInput>
	resources?: InputMaybe<Array<ResourceShareInput>>
}

export enum ShareMedium {
	Email = 'EMAIL',
}

export enum ShareResourceType {
	File = 'FILE',
	Invoice = 'INVOICE',
	PaymentLink = 'PAYMENT_LINK',
	Quote = 'QUOTE',
	Service = 'SERVICE',
}

export type ShareResult = {
	__typename?: 'ShareResult'
	recipients: RecipientShareResult
}

export type SignOutDeviceInput = {
	id?: InputMaybe<Scalars['String']['input']>
	notificationsToken?: InputMaybe<Scalars['String']['input']>
}

export type SignOutInput = {
	device?: InputMaybe<DeviceInput>
}

export type SignUpInput = {
	password: Scalars['String']['input']
	token: Scalars['String']['input']
}

export enum SortDirection {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type StringArrayOperators = {
	in?: InputMaybe<Array<Scalars['String']['input']>>
	nin?: InputMaybe<Array<Scalars['String']['input']>>
}

export type StringOperators = {
	eq?: InputMaybe<Scalars['String']['input']>
	exists?: InputMaybe<Scalars['Boolean']['input']>
	in?: InputMaybe<Array<Scalars['String']['input']>>
	ne?: InputMaybe<Scalars['String']['input']>
	nin?: InputMaybe<Array<Scalars['String']['input']>>
}

export type Subscription = {
	__typename?: 'Subscription'
	id: Scalars['ID']['output']
	status: Subscription_Status
}

export type Tag = Node & {
	__typename?: 'Tag'
	_id: Scalars['ID']['output']
	collection: Scalars['String']['output']
	color: Scalars['String']['output']
	createdAt?: Maybe<Scalars['Date']['output']>
	description?: Maybe<Scalars['String']['output']>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	name: Scalars['String']['output']
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type TagConnection = Connection & {
	__typename?: 'TagConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Tag>
}

export type TagInput = {
	collection: Scalars['String']['input']
	color: Scalars['String']['input']
	description?: InputMaybe<Scalars['String']['input']>
	name: Scalars['String']['input']
}

export type TagsFilter = {
	collection?: InputMaybe<StringOperators>
	isArchived?: InputMaybe<BooleanOperators>
	search?: InputMaybe<SearchOperators>
}

export type Tax = {
	__typename?: 'Tax'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived instead */
	active: Scalars['Boolean']['output']
	description?: Maybe<Scalars['String']['output']>
	factor: TaxFactor
	isArchived: Scalars['Boolean']['output']
	isWithholding: Scalars['Boolean']['output']
	name: Scalars['String']['output']
	rate: Scalars['Float']['output']
	region: Scalars['String']['output']
	type?: Maybe<Scalars['String']['output']>
}

export enum TaxFactor {
	Exempt = 'EXEMPT',
	Fee = 'FEE',
	Rate = 'RATE',
}

export type TaxInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	factor?: InputMaybe<TaxFactor>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	isWithholding?: InputMaybe<Scalars['Boolean']['input']>
	name: Scalars['String']['input']
	rate: Scalars['Float']['input']
	region: Scalars['String']['input']
}

export type ToleranceLimitInput = {
	birds?: InputMaybe<Scalars['Int']['input']>
	insects?: InputMaybe<Scalars['Int']['input']>
	rodents?: InputMaybe<Scalars['Int']['input']>
}

export type ToleranceLimits = {
	__typename?: 'ToleranceLimits'
	birds?: Maybe<Scalars['Int']['output']>
	insects?: Maybe<Scalars['Int']['output']>
	rodents?: Maybe<Scalars['Int']['output']>
}

export type TrackingUserPreferences = {
	__typename?: 'TrackingUserPreferences'
	crash?: Maybe<Scalars['Boolean']['output']>
	performance?: Maybe<Scalars['Boolean']['output']>
	product?: Maybe<Scalars['Boolean']['output']>
}

export type TrackingUserPreferencesInput = {
	crash?: InputMaybe<Scalars['Boolean']['input']>
	performance?: InputMaybe<Scalars['Boolean']['input']>
	product?: InputMaybe<Scalars['Boolean']['input']>
}

export type Transaction = Node & {
	__typename?: 'Transaction'
	_id: Scalars['ID']['output']
	account?: Maybe<Scalars['String']['output']>
	accountName?: Maybe<Scalars['String']['output']>
	accountNumber?: Maybe<Scalars['String']['output']>
	accountType?: Maybe<Scalars['String']['output']>
	/** @deprecated Use total */
	amount?: Maybe<Scalars['Float']['output']>
	category?: Maybe<Scalars['String']['output']>
	company?: Maybe<Client>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy?: Maybe<User>
	currency?: Maybe<Scalars['String']['output']>
	date?: Maybe<Scalars['Date']['output']>
	description?: Maybe<Scalars['String']['output']>
	institution?: Maybe<Scalars['String']['output']>
	/** @deprecated Use invoices */
	invoice?: Maybe<Scalars['ID']['output']>
	invoices: Array<Invoice>
	isArchived: Scalars['Boolean']['output']
	isTotal?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	paymentForm?: Maybe<Scalars['String']['output']>
	paymentLink?: Maybe<Scalars['String']['output']>
	quote?: Maybe<Scalars['ID']['output']>
	reference?: Maybe<Scalars['String']['output']>
	status?: Maybe<Scalars['String']['output']>
	tags: Array<Tag>
	total?: Maybe<Scalars['Float']['output']>
	type?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type TransactionConnection = Connection & {
	__typename?: 'TransactionConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Transaction>
}

export enum TransactionDocumentType {
	Invoice = 'INVOICE',
	Quote = 'QUOTE',
}

export type TransactionInput = {
	accountNumber?: InputMaybe<Scalars['String']['input']>
	amount: Scalars['Float']['input']
	company?: InputMaybe<Scalars['ID']['input']>
	currency: Scalars['String']['input']
	date?: InputMaybe<Scalars['Date']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	institution?: InputMaybe<Scalars['String']['input']>
	invoice?: InputMaybe<Scalars['ID']['input']>
	invoices?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
	isTotal?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	paymentForm?: InputMaybe<Scalars['String']['input']>
	provider?: InputMaybe<Scalars['String']['input']>
	status?: InputMaybe<TransactionStatus>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	type?: InputMaybe<Scalars['String']['input']>
	usePaymentLink?: InputMaybe<Scalars['Boolean']['input']>
}

export enum TransactionStatus {
	Cancelled = 'CANCELLED',
	Completed = 'COMPLETED',
	Failed = 'FAILED',
	Other = 'OTHER',
	Pending = 'PENDING',
}

export enum TransactionType {
	Inflow = 'INFLOW',
	Outflow = 'OUTFLOW',
	Transfer = 'TRANSFER',
}

export type TransactionsFilter = {
	company?: InputMaybe<StringOperators>
	createdBy?: InputMaybe<StringOperators>
	date?: InputMaybe<DateOperators>
	invoice?: InputMaybe<StringOperators>
	isArchived?: InputMaybe<BooleanOperators>
	search?: InputMaybe<SearchOperators>
	tags?: InputMaybe<StringOperators>
}

export type TransactionsSort = {
	date?: InputMaybe<Scalars['Int']['input']>
}

export type Transport = Node & {
	__typename?: 'Transport'
	_id: Scalars['ID']['output']
	cost?: Maybe<Scalars['Float']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	name: Scalars['String']['output']
	/** @deprecated Never existed */
	number?: Maybe<Scalars['String']['output']>
	/** @deprecated Never existed */
	seal?: Maybe<Scalars['String']['output']>
	surfaceArea?: Maybe<Scalars['Float']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type TransportApplication = {
	__typename?: 'TransportApplication'
	name?: Maybe<Scalars['String']['output']>
	number?: Maybe<Scalars['String']['output']>
	seal?: Maybe<Scalars['String']['output']>
}

export type TransportConnection = Connection & {
	__typename?: 'TransportConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Transport>
}

export type TransportFilter = {
	cost?: InputMaybe<Scalars['Float']['input']>
}

export type TransportInput = {
	name?: InputMaybe<Scalars['String']['input']>
	number: Scalars['String']['input']
	seal?: InputMaybe<Scalars['String']['input']>
}

export type Unit = Node & {
	__typename?: 'Unit'
	_id: Scalars['ID']['output']
	/** @deprecated Use isArchived */
	active: Scalars['Boolean']['output']
	area?: Maybe<Area>
	checks: UnitServiceConnection
	company: Client
	createdAt?: Maybe<Scalars['Date']['output']>
	facility?: Maybe<Facility>
	isArchived: Scalars['Boolean']['output']
	location?: Maybe<Point>
	name?: Maybe<Scalars['String']['output']>
	/** @deprecated Use unit.services with filter and sort */
	nextService?: Maybe<Service>
	number: Scalars['String']['output']
	services: ServiceConnection
	status?: Maybe<UnitServiceStatus>
	type: Scalars['String']['output']
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type UnitChecksArgs = {
	filter?: InputMaybe<UnitServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<UnitServicesSort>
}

export type UnitServicesArgs = {
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<ServicesSort>
}

export type UnitConnection = Connection & {
	__typename?: 'UnitConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<Unit>
}

export type UnitInput = {
	area?: InputMaybe<Scalars['ID']['input']>
	company: Scalars['ID']['input']
	facility?: InputMaybe<Scalars['ID']['input']>
	isTransport?: InputMaybe<Scalars['Boolean']['input']>
	location?: InputMaybe<LocationInput>
	number: Scalars['String']['input']
	type: Scalars['String']['input']
}

export type UnitService = Node & {
	__typename?: 'UnitService'
	_id: Scalars['ID']['output']
	comments?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['Date']['output']>
	date?: Maybe<Scalars['Date']['output']>
	employee?: Maybe<Employee>
	infestation?: Maybe<Scalars['Float']['output']>
	isArchived?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	plagues?: Maybe<Array<UnitServicePlague>>
	service?: Maybe<Service>
	status?: Maybe<UnitServiceStatus>
	unit: Unit
	updatedAt?: Maybe<Scalars['Date']['output']>
}

export type UnitServiceConnection = Connection & {
	__typename?: 'UnitServiceConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<UnitService>
}

export type UnitServiceInput = {
	date?: InputMaybe<Scalars['Date']['input']>
	employee: Scalars['ID']['input']
	infestation?: InputMaybe<Scalars['Float']['input']>
	plagues?: InputMaybe<Array<UnitServicePlagueInput>>
	service: Scalars['ID']['input']
	source?: InputMaybe<Scalars['String']['input']>
	status: UnitServiceStatusInput
	unit: Scalars['ID']['input']
}

export type UnitServicePlague = {
	__typename?: 'UnitServicePlague'
	count?: Maybe<Scalars['Int']['output']>
	type?: Maybe<Scalars['String']['output']>
}

export type UnitServicePlagueInput = {
	count: Scalars['Int']['input']
	type: Scalars['String']['input']
}

export type UnitServiceStatus = {
	__typename?: 'UnitServiceStatus'
	general: Scalars['String']['output']
	specific?: Maybe<Array<Scalars['String']['output']>>
}

export type UnitServiceStatusInput = {
	general: Scalars['String']['input']
	specific?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UnitServicesFilter = {
	service: Scalars['ID']['input']
	unit?: InputMaybe<StringArrayOperators>
}

export type UnitServicesSort = {
	date?: InputMaybe<SortDirection>
}

export type UnitsFilter = {
	_id?: InputMaybe<StringArrayOperators>
	/** @deprecated */
	active?: InputMaybe<BooleanOperators>
	client?: InputMaybe<Scalars['ID']['input']>
	facility?: InputMaybe<StringOperators>
	isArchived?: InputMaybe<BooleanOperators>
}

export type UpdateAreaInput = {
	_id: Scalars['String']['input']
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	/** @deprecated */
	company?: InputMaybe<Scalars['ID']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	isExterior?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateAreasInput = {
	areas: Array<UpdateAreaInput>
}

export type UpdateClientInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	address?: InputMaybe<AddressInput>
	emails?: InputMaybe<Array<EmailInput>>
	/** @deprecated */
	isActive?: InputMaybe<BooleanOperators>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	legalName?: InputMaybe<Scalars['String']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	phones?: InputMaybe<Array<InputMaybe<PhoneNumberInput>>>
	/** @deprecated */
	rfc?: InputMaybe<Scalars['String']['input']>
	role?: InputMaybe<CompanyRole>
	roles?: InputMaybe<Array<CompanyRole>>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	taxId?: InputMaybe<Scalars['String']['input']>
	taxSystem?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<Array<Scalars['String']['input']>>
	website?: InputMaybe<Scalars['String']['input']>
}

export type UpdateContactInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	charge?: InputMaybe<Scalars['String']['input']>
	department?: InputMaybe<Scalars['String']['input']>
	email?: InputMaybe<Scalars['String']['input']>
	emails?: InputMaybe<Array<EmailInput>>
	facilities?: InputMaybe<Array<Scalars['ID']['input']>>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	mobileNumber?: InputMaybe<Scalars['String']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	/** @deprecated */
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	phones?: InputMaybe<Array<PhoneInput>>
	prefix?: InputMaybe<Scalars['String']['input']>
	primaryContact?: InputMaybe<Scalars['Boolean']['input']>
	surname?: InputMaybe<Scalars['String']['input']>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateEmployeeInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	address?: InputMaybe<AddressInput>
	charge?: InputMaybe<Scalars['String']['input']>
	curp?: InputMaybe<Scalars['String']['input']>
	email?: InputMaybe<Scalars['String']['input']>
	/** @deprecated */
	imss?: InputMaybe<Scalars['String']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	/** @deprecated */
	rfc?: InputMaybe<Scalars['String']['input']>
	/** @deprecated */
	signature?: InputMaybe<Scalars['Upload']['input']>
	ssn?: InputMaybe<Scalars['String']['input']>
	surname?: InputMaybe<Scalars['String']['input']>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	taxId?: InputMaybe<Scalars['String']['input']>
	taxSystem?: InputMaybe<Scalars['String']['input']>
}

export type UpdateFacilityInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	address?: InputMaybe<AddressInput>
	company?: InputMaybe<Scalars['ID']['input']>
	emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	isTransport?: InputMaybe<Scalars['Boolean']['input']>
	location?: InputMaybe<PolygonInput>
	name?: InputMaybe<Scalars['String']['input']>
	phones?: InputMaybe<Array<PhoneInput>>
	toleranceLimits?: InputMaybe<ToleranceLimitInput>
	type?: InputMaybe<FacilityType>
}

export type UpdateFileInput = {
	acceptance?: InputMaybe<Scalars['Int']['input']>
	/** @deprecated */
	deleted?: InputMaybe<Scalars['Boolean']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	facility?: InputMaybe<Scalars['ID']['input']>
	/** @deprecated */
	file?: InputMaybe<Scalars['Upload']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	parent?: InputMaybe<Scalars['ID']['input']>
	type?: InputMaybe<Scalars['String']['input']>
}

export type UpdateInvoiceInput = {
	comments?: InputMaybe<Scalars['String']['input']>
	company?: InputMaybe<Scalars['ID']['input']>
	complements?: InputMaybe<Array<InvoiceComplementInput>>
	conditions?: InputMaybe<Scalars['String']['input']>
	currency?: InputMaybe<Currency>
	date?: InputMaybe<Scalars['Date']['input']>
	employee?: InputMaybe<Scalars['ID']['input']>
	exchange?: InputMaybe<Scalars['Float']['input']>
	folio?: InputMaybe<Scalars['String']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	isDraft?: InputMaybe<Scalars['Boolean']['input']>
	isSaleNote?: InputMaybe<Scalars['Boolean']['input']>
	items?: InputMaybe<Array<InvoiceItemInput>>
	paymentConditions?: InputMaybe<Scalars['String']['input']>
	paymentForm?: InputMaybe<Scalars['String']['input']>
	paymentMethod?: InputMaybe<InvoicePaymentMethod>
	relations?: InputMaybe<Array<InvoiceRelationInput>>
	series?: InputMaybe<Scalars['String']['input']>
	showSanitaryRegistry?: InputMaybe<Scalars['Boolean']['input']>
	stamp?: InputMaybe<Scalars['Boolean']['input']>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	type?: InputMaybe<InvoiceType>
	use?: InputMaybe<Scalars['String']['input']>
}

export type UpdateIssueInput = {
	assignedTo?: InputMaybe<Array<Scalars['ID']['input']>>
	attachments?: InputMaybe<Array<Scalars['ID']['input']>>
	client?: InputMaybe<Scalars['ID']['input']>
	closedAt?: InputMaybe<Scalars['Date']['input']>
	contacts?: InputMaybe<Array<Scalars['ID']['input']>>
	description?: InputMaybe<Scalars['String']['input']>
	dueDate?: InputMaybe<Scalars['Date']['input']>
	facility?: InputMaybe<Scalars['ID']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	priority?: InputMaybe<Scalars['Int']['input']>
	rating?: InputMaybe<Scalars['Int']['input']>
	services?: InputMaybe<Array<Scalars['ID']['input']>>
	status?: InputMaybe<IssueStatus>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	title?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<Scalars['String']['input']>
	units?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateMessageTemplateInput = {
	body?: InputMaybe<Scalars['String']['input']>
	design?: InputMaybe<Scalars['String']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	relatedModel?: InputMaybe<Scalars['String']['input']>
	subject?: InputMaybe<Scalars['String']['input']>
}

export type UpdateOrganizationInput = {
	address?: InputMaybe<AddressInput>
	information?: InputMaybe<OrgInformationInput>
	integrationToken?: InputMaybe<IntegrationTokenInput>
	legalName?: InputMaybe<Scalars['String']['input']>
	logo?: InputMaybe<AssetInput>
	logoLight?: InputMaybe<AssetInput>
	name?: InputMaybe<Scalars['String']['input']>
	preferences?: InputMaybe<OrgPreferencesInput>
	subscription?: InputMaybe<OrgSubscriptionInput>
	taxId?: InputMaybe<Scalars['String']['input']>
	taxes?: InputMaybe<Array<TaxInput>>
	transports?: InputMaybe<Array<OrgTransportInput>>
	type?: InputMaybe<OrganizationType>
}

export type UpdateOrganizationInvoicingInput = {
	mx?: InputMaybe<MxInvoicingInput>
	organization?: InputMaybe<UpdateOrganizationInput>
}

export type UpdateProductInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	activeIngredient?: InputMaybe<Scalars['String']['input']>
	applications?: InputMaybe<Array<ProductApplicationInput>>
	currency?: InputMaybe<Currency>
	description?: InputMaybe<Scalars['String']['input']>
	hasTax?: InputMaybe<Scalars['Boolean']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	notes?: InputMaybe<Scalars['String']['input']>
	price?: InputMaybe<Scalars['Float']['input']>
	producer?: InputMaybe<Scalars['String']['input']>
	sanitaryRegistry?: InputMaybe<Scalars['String']['input']>
	shortDescription?: InputMaybe<Scalars['String']['input']>
	subType?: InputMaybe<Scalars['String']['input']>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	tax?: InputMaybe<UpdateProductTaxInput>
	tradeName?: InputMaybe<Scalars['String']['input']>
	type?: InputMaybe<Scalars['String']['input']>
}

export type UpdateProductTaxInput = {
	key?: InputMaybe<Scalars['String']['input']>
	unitKey?: InputMaybe<Scalars['String']['input']>
}

export type UpdateQuoteInput = {
	company?: InputMaybe<Scalars['ID']['input']>
	contact?: InputMaybe<Scalars['ID']['input']>
	currency?: InputMaybe<Currency>
	customFields?: InputMaybe<Array<QuoteCustomFieldInput>>
	expiresAt?: InputMaybe<Scalars['Date']['input']>
	hideBreakdown?: InputMaybe<Scalars['Boolean']['input']>
	invoice?: InputMaybe<Scalars['ID']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	items?: InputMaybe<Array<InvoiceItemInput>>
	memo?: InputMaybe<Scalars['String']['input']>
	number?: InputMaybe<Scalars['String']['input']>
	status?: InputMaybe<QuoteStatus>
	subTotal?: InputMaybe<Scalars['Float']['input']>
	total?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateRoleInput = {
	description?: InputMaybe<Scalars['String']['input']>
	metadata?: InputMaybe<Scalars['JSON']['input']>
	permissions?: InputMaybe<Array<PermissionsInput>>
	title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateServiceInput = {
	application?: InputMaybe<ApplicationInput>
	areas?: InputMaybe<Array<Scalars['ID']['input']>>
	arrivedAt?: InputMaybe<Scalars['Date']['input']>
	/** @deprecated */
	attachments?: InputMaybe<Array<Scalars['Upload']['input']>>
	/** @deprecated */
	attachmentsReferences?: InputMaybe<Array<Scalars['ID']['input']>>
	comments?: InputMaybe<Scalars['String']['input']>
	completedAt?: InputMaybe<Scalars['Date']['input']>
	corrective?: InputMaybe<Scalars['String']['input']>
	date?: InputMaybe<Scalars['Date']['input']>
	delete?: InputMaybe<Scalars['Boolean']['input']>
	employees?: InputMaybe<Array<Scalars['ID']['input']>>
	enRouteAt?: InputMaybe<Scalars['Date']['input']>
	enRouteLocation?: InputMaybe<Array<Scalars['Float']['input']>>
	expirationAt?: InputMaybe<Scalars['Date']['input']>
	frequency?: InputMaybe<Scalars['Int']['input']>
	generateDocument?: InputMaybe<Scalars['Boolean']['input']>
	invoices?: InputMaybe<Array<Scalars['ID']['input']>>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	lot?: InputMaybe<Scalars['String']['input']>
	notes?: InputMaybe<Scalars['String']['input']>
	plagues?: InputMaybe<Array<PlagueCountInput>>
	products?: InputMaybe<Array<Scalars['ID']['input']>>
	/** @deprecated */
	signature?: InputMaybe<Scalars['Upload']['input']>
	signatureKey?: InputMaybe<Scalars['String']['input']>
	signedAt?: InputMaybe<Scalars['Date']['input']>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateServicesInput = {
	areas?: InputMaybe<Array<Scalars['ID']['input']>>
	employees?: InputMaybe<Array<Scalars['ID']['input']>>
	invoices?: InputMaybe<Array<Scalars['ID']['input']>>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	products?: InputMaybe<Array<Scalars['ID']['input']>>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateTagInput = {
	color?: InputMaybe<Scalars['String']['input']>
	/** @deprecated */
	deleted?: InputMaybe<Scalars['Boolean']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTransactionInput = {
	accountNumber?: InputMaybe<Scalars['String']['input']>
	amount?: InputMaybe<Scalars['Float']['input']>
	currency?: InputMaybe<Scalars['String']['input']>
	date?: InputMaybe<Scalars['Date']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	institution?: InputMaybe<Scalars['String']['input']>
	invoice?: InputMaybe<Scalars['ID']['input']>
	invoices?: InputMaybe<Array<Scalars['ID']['input']>>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	isTotal?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	paymentForm?: InputMaybe<Scalars['String']['input']>
	provider?: InputMaybe<Scalars['String']['input']>
	status?: InputMaybe<TransactionStatus>
	tags?: InputMaybe<Array<Scalars['ID']['input']>>
	type?: InputMaybe<Scalars['String']['input']>
	usePaymentLink?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateUnitInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	area?: InputMaybe<Scalars['ID']['input']>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	location?: InputMaybe<LocationInput>
	number?: InputMaybe<Scalars['Int']['input']>
	type?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUnitServiceInput = {
	employee?: InputMaybe<Scalars['ID']['input']>
	infestation?: InputMaybe<Scalars['Float']['input']>
	plagues?: InputMaybe<Array<UnitServicePlagueInput>>
	source?: InputMaybe<Scalars['String']['input']>
	status?: InputMaybe<UnitServiceStatusInput>
}

export type UpdateUserInput = {
	/** @deprecated */
	active?: InputMaybe<Scalars['Boolean']['input']>
	contact?: InputMaybe<UpdateContactInput>
	devices?: InputMaybe<Array<DeviceInput>>
	isArchived?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	password?: InputMaybe<Scalars['String']['input']>
	permissions?: InputMaybe<Array<PermissionsInput>>
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	/** @deprecated */
	photo?: InputMaybe<Scalars['Upload']['input']>
	photoFile?: InputMaybe<FileInput>
	preferences?: InputMaybe<UserPreferencesInput>
	roles?: InputMaybe<Array<Scalars['ID']['input']>>
	surname?: InputMaybe<Scalars['String']['input']>
	webAuthn?: InputMaybe<RegisterWebAuthInput>
}

export type UploadFileInput = {
	acceptance?: InputMaybe<Scalars['Int']['input']>
	company?: InputMaybe<Scalars['ID']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	facility?: InputMaybe<Scalars['ID']['input']>
	/** @deprecated */
	file?: InputMaybe<Scalars['Upload']['input']>
	name: Scalars['String']['input']
	parent?: InputMaybe<Scalars['ID']['input']>
	type?: InputMaybe<Scalars['String']['input']>
}

export type User = Node & {
	__typename?: 'User'
	_id: Scalars['ID']['output']
	_v?: Maybe<Scalars['Int']['output']>
	/** @deprecated Use permissions or roles instead */
	access?: Maybe<Scalars['Int']['output']>
	/** @deprecated Use isArchived instead */
	active?: Maybe<Scalars['Boolean']['output']>
	company?: Maybe<Client>
	contact?: Maybe<Contact>
	createdAt?: Maybe<Scalars['Date']['output']>
	createdBy?: Maybe<User>
	/** @deprecated Use devices instead */
	device?: Maybe<Scalars['String']['output']>
	devices?: Maybe<Array<Device>>
	email: Scalars['String']['output']
	employee?: Maybe<Employee>
	facilities?: Maybe<FacilityConnection>
	hasActiveToken: Scalars['Boolean']['output']
	isArchived?: Maybe<Scalars['Boolean']['output']>
	isEmailBouncing?: Maybe<Scalars['Boolean']['output']>
	lastLogin?: Maybe<Scalars['Date']['output']>
	locations?: Maybe<LocationConnection>
	mobileNumber?: Maybe<Scalars['String']['output']>
	name: Scalars['String']['output']
	organization: Organization
	permissions?: Maybe<Array<Permission>>
	phoneNumber?: Maybe<Scalars['String']['output']>
	photo?: Maybe<Attachment>
	preferences?: Maybe<UserPreferences>
	roles: Array<Role>
	selectedFacility?: Maybe<Scalars['String']['output']>
	sessions: Array<Session>
	surname?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['Date']['output']>
	username: Scalars['String']['output']
	webAuthn: Array<WebAuthn>
}

export type UserLocationsArgs = {
	filter?: InputMaybe<LocationsFilter>
}

export type UserConnection = Connection & {
	__typename?: 'UserConnection'
	_key: Scalars['ID']['output']
	count: Scalars['Int']['output']
	hasMore: Scalars['Boolean']['output']
	nodes: Array<User>
}

export type UserInviteInput = {
	email: Scalars['String']['input']
	password?: InputMaybe<Scalars['String']['input']>
	roles?: InputMaybe<Array<Scalars['String']['input']>>
	type: UserType
}

export type UserPreferences = {
	__typename?: 'UserPreferences'
	language?: Maybe<Scalars['String']['output']>
	privacy?: Maybe<PrivacyUserPreferences>
	timeZone?: Maybe<Scalars['String']['output']>
}

export type UserPreferencesInput = {
	language?: InputMaybe<Scalars['String']['input']>
	privacy?: InputMaybe<PrivacyUserPreferencesInput>
	summaringFrequency?: InputMaybe<Array<Scalars['Int']['input']>>
	timeZone?: InputMaybe<Scalars['String']['input']>
}

export enum UserType {
	Contact = 'CONTACT',
	Employee = 'EMPLOYEE',
}

export type UsersFilter = {
	id?: InputMaybe<StringOperators>
}

export type VerifyUserAttributeInput = {
	attribute: Scalars['String']['input']
	code?: InputMaybe<Scalars['String']['input']>
}

export type WebAuthInput = {
	authenticatorData: Scalars['String']['input']
	clientDataJSON: Scalars['String']['input']
	id: Scalars['String']['input']
	rawId?: InputMaybe<Scalars['String']['input']>
	signature: Scalars['String']['input']
	type?: InputMaybe<Scalars['String']['input']>
}

export type WebAuthn = {
	__typename?: 'WebAuthn'
	createdAt?: Maybe<Scalars['Date']['output']>
	name: Scalars['String']['output']
}

export type UpdateAreasMutationVariables = Exact<{
	input: UpdateAreasInput
}>

export type UpdateAreasMutation = {
	__typename?: 'Mutation'
	updateAreas: Array<{
		__typename?: 'Area'
		_id: string
		name: string
		type: number
		isExterior: boolean
		active: boolean
		units?: {
			__typename?: 'UnitConnection'
			_key: string
			count: number
		} | null
	}>
}

export type FacilityUnitFragmentFragment = {
	__typename?: 'Unit'
	_id: string
	number: string
	type: string
	active: boolean
	area?: {
		__typename?: 'Area'
		_id: string
		name: string
		isExterior: boolean
	} | null
}

export type UnitsAndSelfQueryVariables = Exact<{
	filter: UnitsFilter
	id: Scalars['ID']['input']
}>

export type UnitsAndSelfQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		organization: {
			__typename?: 'Organization'
			_id: string
			logo?: { __typename?: 'Asset'; url?: string | null } | null
		}
	}
	facility: { __typename?: 'Facility'; _id: string; isTransport: boolean }
	units: {
		__typename?: 'UnitConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Unit'
			_id: string
			number: string
			type: string
			active: boolean
			area?: {
				__typename?: 'Area'
				_id: string
				name: string
				isExterior: boolean
			} | null
		}>
	}
}

export type ClientDetailFragmentFragment = {
	__typename?: 'Client'
	_id: string
	name: string
	legalName: string
	taxId?: string | null
	taxSystem?: string | null
	active?: boolean | null
	type?: Array<string> | null
	role?: CompanyRole | null
	website?: string | null
	address?: {
		__typename?: 'Address'
		street?: string | null
		number?: string | null
		city?: string | null
		suburb?: string | null
		municipality?: string | null
		zipCode?: string | null
		state?: string | null
	} | null
	phones?: Array<{
		__typename?: 'Phone'
		label: string
		number: string
	} | null> | null
}

export type GetClientAndTagsQueryVariables = Exact<{
	id: Scalars['ID']['input']
	tagsFilter?: InputMaybe<TagsFilter>
	servicesFilter?: InputMaybe<ServicesFilter>
}>

export type GetClientAndTagsQuery = {
	__typename?: 'Query'
	tags: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	}
	client: {
		__typename?: 'Client'
		metadata?: any | null
		_id: string
		name: string
		legalName: string
		taxId?: string | null
		taxSystem?: string | null
		active?: boolean | null
		type?: Array<string> | null
		role?: CompanyRole | null
		website?: string | null
		services: { __typename?: 'ServiceConnection'; _key: string; count: number }
		contacts?: {
			__typename?: 'ContactConnection'
			_key: string
			count: number
		} | null
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			city?: string | null
			suburb?: string | null
			municipality?: string | null
			zipCode?: string | null
			state?: string | null
		} | null
		phones?: Array<{
			__typename?: 'Phone'
			label: string
			number: string
		} | null> | null
	}
}

export type UpdateClientMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateClientInput
}>

export type UpdateClientMutation = {
	__typename?: 'Mutation'
	updateClient: {
		__typename?: 'Client'
		_id: string
		name: string
		legalName: string
		taxId?: string | null
		taxSystem?: string | null
		active?: boolean | null
		type?: Array<string> | null
		role?: CompanyRole | null
		website?: string | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			city?: string | null
			suburb?: string | null
			municipality?: string | null
			zipCode?: string | null
			state?: string | null
		} | null
		phones?: Array<{
			__typename?: 'Phone'
			label: string
			number: string
		} | null> | null
	}
}

export type ContactBaseFragmentFragment = {
	__typename?: 'Contact'
	_id: string
	name: string
	surname: string
	user?: {
		__typename?: 'User'
		_id: string
		lastLogin?: any | null
		hasActiveToken: boolean
		permissions?: Array<{
			__typename?: 'Permission'
			effect: string
			action: string
			resource: string
		}> | null
	} | null
}

export type EmployeeBaseFragmentFragment = {
	__typename?: 'Employee'
	_id: string
	name: string
	surname: string
	taxId?: string | null
	taxSystem?: string | null
	user?: { __typename?: 'User'; _id: string } | null
}

export type UserPermissionsFragmentFragment = {
	__typename?: 'User'
	_id: string
	roles: Array<{
		__typename?: 'Role'
		_id: string
		title?: string | null
		description?: string | null
		permissions: Array<{
			__typename?: 'Permission'
			effect: string
			action: string
			resource: string
		}>
	}>
	permissions?: Array<{
		__typename?: 'Permission'
		effect: string
		action: string
		resource: string
	}> | null
}

export type GetEmployeePermissionsQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetEmployeePermissionsQuery = {
	__typename?: 'Query'
	employee: {
		__typename?: 'Employee'
		_id: string
		user?: {
			__typename?: 'User'
			lastLogin?: any | null
			_id: string
			roles: Array<{
				__typename?: 'Role'
				_id: string
				title?: string | null
				description?: string | null
				permissions: Array<{
					__typename?: 'Permission'
					effect: string
					action: string
					resource: string
				}>
			}>
			permissions?: Array<{
				__typename?: 'Permission'
				effect: string
				action: string
				resource: string
			}> | null
		} | null
	}
}

export type EmployeeUserFragmentFragment = {
	__typename?: 'User'
	_id: string
	active?: boolean | null
	device?: string | null
	email: string
	photo?: { __typename?: 'Attachment'; url?: string | null } | null
}

export type GetOrganizationQuoteConfigQueryVariables = Exact<{
	[key: string]: never
}>

export type GetOrganizationQuoteConfigQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		organization: {
			__typename?: 'Organization'
			_id: string
			discounts?: Array<{ __typename?: 'Discount'; _id: string }> | null
			taxes?: Array<{
				__typename?: 'Tax'
				_id: string
				name: string
				rate: number
				active: boolean
				region: string
				factor: TaxFactor
				isArchived: boolean
				description?: string | null
				isWithholding: boolean
			}> | null
		}
	}
}

export type GetServiceAndEmployeesQueryVariables = Exact<{
	id: Scalars['ID']['input']
	tagsFilter?: InputMaybe<TagsFilter>
	employeesFilter?: InputMaybe<EmployeesFilter>
	hasAnalytics: Scalars['Boolean']['input']
}>

export type GetServiceAndEmployeesQuery = {
	__typename?: 'Query'
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			_id: string
			name: string
			surname: string
			user?: {
				__typename?: 'User'
				_id: string
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
		}>
	}
	tags: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	}
	products: {
		__typename?: 'ProductConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Product'
			_id: string
			name: string
			active?: boolean | null
			applications?: Array<{
				__typename?: 'ApplicationType'
				quantity?: number | null
				method?: string | null
				dosis?: string | null
				duration?: string | null
			}> | null
		}>
	}
	service: {
		__typename?: 'Service'
		estimatedCompletionTime?: number | null
		createdAt?: any | null
		_id: string
		type: number
		date: any
		arrivedAt?: any | null
		enRouteAt?: any | null
		completedAt?: any | null
		frequency?: number | null
		url?: string | null
		lot?: string | null
		notes?: string | null
		comments?: string | null
		corrective?: string | null
		signatureKey?: string | null
		completionTime?: number | null
		attachments?: Array<{
			__typename?: 'File'
			_id: string
			name: string
			type?: string | null
			url?: string | null
			mimetype?: string | null
			thumbnail?: string | null
			lastUpdated?: any | null
			acceptance?: number | null
			deleted: boolean
			facility?: { __typename?: 'Facility'; _id: string } | null
		}> | null
		client: { __typename?: 'Client'; _id: string; name: string }
		units?: {
			__typename?: 'UnitConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Unit'
				_id: string
				number: string
				type: string
			}>
		} | null
		facility?: {
			__typename?: 'Facility'
			_id: string
			name: string
			areas?: {
				__typename?: 'AreaConnection'
				_key: string
				nodes: Array<{
					__typename?: 'Area'
					_id: string
					name: string
					type: number
				}>
			} | null
		} | null
		createdBy: {
			__typename?: 'User'
			_id: string
			name: string
			surname?: string | null
		}
		employees: {
			__typename?: 'EmployeeConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Employee'
				_id: string
				name: string
				surname: string
				user?: { __typename?: 'User'; _id: string } | null
			}>
		}
		application?: {
			__typename?: 'ApplicationType'
			dosis?: string | null
			quantity?: number | null
			method?: string | null
			duration?: string | null
			sites?: Array<string> | null
		} | null
		areas: {
			__typename?: 'AreaConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Area'
				_id: string
				name: string
				active: boolean
			}>
		}
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
		products?: {
			__typename?: 'ProductConnection'
			_key: string
			nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
		} | null
	}
}

export type UnitServiceFragmentFragment = {
	__typename?: 'UnitService'
	_id: string
	infestation?: number | null
	date?: any | null
	status?: {
		__typename?: 'UnitServiceStatus'
		general: string
		specific?: Array<string> | null
	} | null
	plagues?: Array<{
		__typename?: 'UnitServicePlague'
		type?: string | null
		count?: number | null
	}> | null
	employee?: {
		__typename?: 'Employee'
		_id: string
		user?: { __typename?: 'User'; _id: string } | null
	} | null
	unit: { __typename?: 'Unit'; _id: string }
}

export type GetUnitChecksQueryVariables = Exact<{
	filter?: InputMaybe<UnitServicesFilter>
}>

export type GetUnitChecksQuery = {
	__typename?: 'Query'
	unitServices: {
		__typename?: 'UnitServiceConnection'
		_key: string
		nodes: Array<{
			__typename?: 'UnitService'
			_id: string
			infestation?: number | null
			date?: any | null
			status?: {
				__typename?: 'UnitServiceStatus'
				general: string
				specific?: Array<string> | null
			} | null
			plagues?: Array<{
				__typename?: 'UnitServicePlague'
				type?: string | null
				count?: number | null
			}> | null
			employee?: {
				__typename?: 'Employee'
				_id: string
				user?: { __typename?: 'User'; _id: string } | null
			} | null
			unit: { __typename?: 'Unit'; _id: string }
		}>
	}
}

export type ServiceDetailFragmentFragment = {
	__typename?: 'Service'
	_id: string
	type: number
	date: any
	arrivedAt?: any | null
	enRouteAt?: any | null
	completedAt?: any | null
	frequency?: number | null
	url?: string | null
	lot?: string | null
	notes?: string | null
	comments?: string | null
	corrective?: string | null
	signatureKey?: string | null
	completionTime?: number | null
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			_id: string
			name: string
			surname: string
			user?: { __typename?: 'User'; _id: string } | null
		}>
	}
	application?: {
		__typename?: 'ApplicationType'
		dosis?: string | null
		quantity?: number | null
		method?: string | null
		duration?: string | null
		sites?: Array<string> | null
	} | null
	areas: {
		__typename?: 'AreaConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Area'
			_id: string
			name: string
			active: boolean
		}>
	}
	tags?: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	} | null
	products?: {
		__typename?: 'ProductConnection'
		_key: string
		nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
	} | null
}

export type UpdateServicesMutationVariables = Exact<{
	ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
	input: UpdateServicesInput
}>

export type UpdateServicesMutation = {
	__typename?: 'Mutation'
	updateServices: Array<{
		__typename?: 'Service'
		_id: string
		date: any
		type: number
		frequency?: number | null
		comments?: string | null
		signatureKey?: string | null
		notes?: string | null
		url?: string | null
		facility?: { __typename?: 'Facility'; _id: string; name: string } | null
		units?: {
			__typename?: 'UnitConnection'
			_key: string
			count: number
		} | null
		employees: {
			__typename?: 'EmployeeConnection'
			_key: string
			count: number
			nodes: Array<{
				__typename?: 'Employee'
				_id: string
				name: string
				surname: string
				user?: {
					__typename?: 'User'
					_id: string
					photo?: { __typename?: 'Attachment'; url?: string | null } | null
				} | null
			}>
		}
		areas: {
			__typename?: 'AreaConnection'
			_key: string
			count: number
			nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
		}
		products?: {
			__typename?: 'ProductConnection'
			_key: string
			count: number
			nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
		} | null
		client: { __typename?: 'Client'; _id: string; name: string }
		createdBy: {
			__typename?: 'User'
			_id: string
			name: string
			surname?: string | null
		}
		transport?: {
			__typename?: 'TransportApplication'
			name?: string | null
			number?: string | null
		} | null
		application?: {
			__typename?: 'ApplicationType'
			method?: string | null
			duration?: string | null
			dosis?: string | null
		} | null
		payment?: {
			__typename?: 'Payment'
			status?: number | null
			cost?: number | null
			tax?: number | null
		} | null
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
	}>
}

export type GetEmployeesForFilterQueryVariables = Exact<{
	[key: string]: never
}>

export type GetEmployeesForFilterQuery = {
	__typename?: 'Query'
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			_id: string
			name: string
			surname: string
		}>
	}
}

export type GetOrganizationQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		email: string
		organization: {
			__typename?: 'Organization'
			_id: string
			taxId?: string | null
			information?: {
				__typename?: 'OrgInformation'
				website?: string | null
			} | null
			taxes?: Array<{
				__typename?: 'Tax'
				_id: string
				name: string
				region: string
				rate: number
				type?: string | null
				description?: string | null
				active: boolean
				factor: TaxFactor
				isWithholding: boolean
			}> | null
			preferences?: {
				__typename?: 'OrgPreferences'
				billingEmail?: string | null
				documentMemos?: {
					__typename?: 'DocumentMemos'
					QT?: string | null
				} | null
			} | null
			integrations?: Array<{
				__typename?: 'OrgIntegration'
				_id?: string | null
				provider: IntegrationProviders
				status?: IntegrationStatus | null
				type: string
				name?: string | null
				createdAt?: any | null
			}> | null
		}
	}
}

export type RequestIntegrationTokenMutationVariables = Exact<{
	input: IntegrationTokenRequestInput
}>

export type RequestIntegrationTokenMutation = {
	__typename?: 'Mutation'
	requestIntegrationToken: string
}

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetRolesQuery = {
	__typename?: 'Query'
	roles?: {
		__typename?: 'RoleConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Role'
			_id: string
			title?: string | null
			description?: string | null
			permissions: Array<{
				__typename?: 'Permission'
				effect: string
				action: string
				resource: string
			}>
		}>
	} | null
}

export type GetOrganizationTagsQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationTagsQuery = {
	__typename?: 'Query'
	tags: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	}
}

export type GetClientTransportsQueryVariables = Exact<{
	filter?: InputMaybe<CompaniesFilter>
	sFilter?: InputMaybe<ServicesFilter>
}>

export type GetClientTransportsQuery = {
	__typename?: 'Query'
	clients: {
		__typename?: 'ClientConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Client'
			_id: string
			name: string
			transports?: {
				__typename?: 'ClientTransportConnection'
				_key: string
				nodes: Array<{
					__typename?: 'ClientTransport'
					_id: string
					unit?: { __typename?: 'Unit'; _id: string } | null
					services: {
						__typename?: 'ServiceConnection'
						_key: string
						nodes: Array<{
							__typename?: 'Service'
							date: any
							_id: string
							payment?: {
								__typename?: 'Payment'
								status?: number | null
							} | null
						}>
					}
				}>
			} | null
		}>
	}
}

export type GetTransportServicesQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetTransportServicesQuery = {
	__typename?: 'Query'
	unit: {
		__typename?: 'Unit'
		_id: string
		services: {
			__typename?: 'ServiceConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Service'
				_id: string
				date: any
				type: number
				frequency?: number | null
				comments?: string | null
				signatureKey?: string | null
				notes?: string | null
				url?: string | null
				facility?: { __typename?: 'Facility'; _id: string; name: string } | null
				units?: {
					__typename?: 'UnitConnection'
					_key: string
					count: number
				} | null
				employees: {
					__typename?: 'EmployeeConnection'
					_key: string
					count: number
					nodes: Array<{
						__typename?: 'Employee'
						_id: string
						name: string
						surname: string
						user?: {
							__typename?: 'User'
							_id: string
							photo?: { __typename?: 'Attachment'; url?: string | null } | null
						} | null
					}>
				}
				areas: {
					__typename?: 'AreaConnection'
					_key: string
					count: number
					nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
				}
				products?: {
					__typename?: 'ProductConnection'
					_key: string
					count: number
					nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
				} | null
				client: { __typename?: 'Client'; _id: string; name: string }
				createdBy: {
					__typename?: 'User'
					_id: string
					name: string
					surname?: string | null
				}
				transport?: {
					__typename?: 'TransportApplication'
					name?: string | null
					number?: string | null
				} | null
				application?: {
					__typename?: 'ApplicationType'
					method?: string | null
					duration?: string | null
					dosis?: string | null
				} | null
				payment?: {
					__typename?: 'Payment'
					status?: number | null
					cost?: number | null
					tax?: number | null
				} | null
				tags?: {
					__typename?: 'TagConnection'
					_key: string
					nodes: Array<{
						__typename?: 'Tag'
						_id: string
						name: string
						description?: string | null
						color: string
						isArchived?: boolean | null
						collection: string
					}>
				} | null
			}>
		}
	}
}

export type FacilityServiceFragmentFragment = {
	__typename?: 'Service'
	_id: string
	date: any
	type: number
	url?: string | null
	signatureKey?: string | null
	areas: {
		__typename?: 'AreaConnection'
		_key: string
		nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
	}
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			_id: string
			name: string
			surname: string
			user?: {
				__typename?: 'User'
				_id: string
				name: string
				surname?: string | null
			} | null
		}>
	}
}

export type UpdateServiceSignatureMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateServiceInput
}>

export type UpdateServiceSignatureMutation = {
	__typename?: 'Mutation'
	updateService: {
		__typename?: 'Service'
		_id: string
		date: any
		type: number
		url?: string | null
		signatureKey?: string | null
		areas: {
			__typename?: 'AreaConnection'
			_key: string
			nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
		}
		employees: {
			__typename?: 'EmployeeConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Employee'
				_id: string
				name: string
				surname: string
				user?: {
					__typename?: 'User'
					_id: string
					name: string
					surname?: string | null
				} | null
			}>
		}
	}
}

export type GetFacilityAreaAndUnitsQueryVariables = Exact<{
	facility: Scalars['ID']['input']
}>

export type GetFacilityAreaAndUnitsQuery = {
	__typename?: 'Query'
	facility: {
		__typename?: 'Facility'
		_id: string
		toleranceLimits: {
			__typename?: 'ToleranceLimits'
			insects?: number | null
			rodents?: number | null
		}
		areas?: {
			__typename?: 'AreaConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Area'
				_id: string
				name: string
				active: boolean
			}>
		} | null
		units?: {
			__typename?: 'UnitConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Unit'
				_id: string
				number: string
				area?: {
					__typename?: 'Area'
					_id: string
					name: string
					isExterior: boolean
				} | null
				status?: {
					__typename?: 'UnitServiceStatus'
					general: string
					specific?: Array<string> | null
				} | null
			}>
		} | null
	}
}

export type GetFacilityServicesQueryVariables = Exact<{
	filter?: InputMaybe<ServicesFilter>
	sort?: InputMaybe<ServicesSort>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetFacilityServicesQuery = {
	__typename?: 'Query'
	services: {
		__typename?: 'ServiceConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Service'
			_id: string
			date: any
			type: number
			url?: string | null
			signatureKey?: string | null
			areas: {
				__typename?: 'AreaConnection'
				_key: string
				nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
			}
			employees: {
				__typename?: 'EmployeeConnection'
				_key: string
				nodes: Array<{
					__typename?: 'Employee'
					_id: string
					name: string
					surname: string
					user?: {
						__typename?: 'User'
						_id: string
						name: string
						surname?: string | null
					} | null
				}>
			}
		}>
	}
}

export type ClientContactFragmentFragment = {
	__typename?: 'Contact'
	_id: string
	charge: string
	department: string
	phoneNumber?: string | null
	mobileNumber?: string | null
}

export type GetOrganizationSignupQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetOrganizationSignupQuery = {
	__typename?: 'Query'
	organization: {
		__typename?: 'Organization'
		_id: string
		name: string
		logo?: { __typename?: 'Asset'; url?: string | null } | null
		logoLight?: { __typename?: 'Asset'; url?: string | null } | null
	}
}

export type SignUpMutationVariables = Exact<{
	input: SignUpInput
}>

export type SignUpMutation = {
	__typename?: 'Mutation'
	credentials: {
		__typename?: 'Credentials'
		token?: string | null
		user: {
			__typename?: 'User'
			_id: string
			name: string
			surname?: string | null
			email: string
			phoneNumber?: string | null
			isEmailBouncing?: boolean | null
			company?: { __typename?: 'Client'; _id: string; name: string } | null
			photo?: { __typename?: 'Attachment'; url?: string | null } | null
			preferences?: {
				__typename?: 'UserPreferences'
				language?: string | null
				timeZone?: string | null
				privacy?: {
					__typename?: 'PrivacyUserPreferences'
					tracking?: {
						__typename?: 'TrackingUserPreferences'
						performance?: boolean | null
						product?: boolean | null
						crash?: boolean | null
					} | null
				} | null
			} | null
			organization: {
				__typename?: 'Organization'
				_id: string
				apps?: Array<string> | null
				name: string
				legalName: string
				taxId?: string | null
				taxSystem?: string | null
				features?: Array<string> | null
				information?: {
					__typename?: 'OrgInformation'
					website?: string | null
				} | null
				logo?: { __typename?: 'Asset'; url?: string | null } | null
				logoLight?: { __typename?: 'Asset'; url?: string | null } | null
				preferences?: {
					__typename?: 'OrgPreferences'
					language?: string | null
					timeZone?: string | null
					billingEmail?: string | null
				} | null
				subscription?: {
					__typename?: 'Subscription'
					status: Subscription_Status
				} | null
				address?: {
					__typename?: 'Address'
					street?: string | null
					number?: string | null
					city?: string | null
					suburb?: string | null
					municipality?: string | null
					zipCode?: string | null
					state?: string | null
					country?: string | null
				} | null
			}
			facilities?: {
				__typename?: 'FacilityConnection'
				_key: string
				nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
			} | null
		}
	}
}

export type ActivityFragmentFragment = {
	__typename?: 'Activity'
	_id: string
	description?: string | null
	type: string
	createdAt?: any | null
	metadata?: any | null
	attachments?: Array<{
		__typename?: 'File'
		_id: string
		url?: string | null
		thumbnail?: string | null
		mimetype?: string | null
	}> | null
	createdBy?: {
		__typename?: 'User'
		_id: string
		name: string
		surname?: string | null
		photo?: { __typename?: 'Attachment'; url?: string | null } | null
	} | null
}

export type ClientFragmentFragment = {
	__typename?: 'Client'
	_id: string
	name: string
	taxId?: string | null
	taxSystem?: string | null
	active?: boolean | null
	legalName: string
	type?: Array<string> | null
	phones?: Array<{
		__typename?: 'Phone'
		label: string
		number: string
	} | null> | null
	address?: {
		__typename?: 'Address'
		street?: string | null
		number?: string | null
		zipCode?: string | null
		suburb?: string | null
		municipality?: string | null
		city?: string | null
		state?: string | null
		country?: string | null
	} | null
	contacts?: {
		__typename?: 'ContactConnection'
		_key: string
		count: number
		nodes: Array<{
			__typename?: 'Contact'
			_id: string
			name: string
			surname: string
			primaryContact?: boolean | null
		}>
	} | null
	tags?: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	} | null
}

export type ContactFragmentFragment = {
	__typename?: 'Contact'
	prefix?: string | null
	charge: string
	department: string
	email?: string | null
	phoneNumber?: string | null
	active?: boolean | null
	_id: string
	name: string
	surname: string
	company: { __typename?: 'Client'; _id: string; name: string }
	facilities?: {
		__typename?: 'FacilityConnection'
		_key: string
		count: number
	} | null
	tags?: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	} | null
	user?: {
		__typename?: 'User'
		_id: string
		lastLogin?: any | null
		active?: boolean | null
		hasActiveToken: boolean
		permissions?: Array<{
			__typename?: 'Permission'
			effect: string
			action: string
			resource: string
		}> | null
	} | null
}

export type EmployeeFragmentFragment = {
	__typename?: 'Employee'
	charge?: string | null
	phoneNumber?: string | null
	imss?: string | null
	email?: string | null
	curp?: string | null
	active: boolean
	_id: string
	name: string
	surname: string
	taxId?: string | null
	taxSystem?: string | null
	user?: {
		__typename?: 'User'
		_id: string
		active?: boolean | null
		device?: string | null
		email: string
		photo?: { __typename?: 'Attachment'; url?: string | null } | null
	} | null
	services?: {
		__typename?: 'ServiceConnection'
		_key: string
		count: number
	} | null
}

export type FacilityFragmentFragment = {
	__typename?: 'Facility'
	_id: string
	name: string
	emails?: Array<string> | null
	active: boolean
	isTransport: boolean
	type?: FacilityType | null
	phones?: Array<{
		__typename?: 'Phone'
		number: string
		label: string
	} | null> | null
	address?: {
		__typename?: 'Address'
		street?: string | null
		number?: string | null
		city?: string | null
		suburb?: string | null
		municipality?: string | null
		zipCode?: string | null
		state?: string | null
	} | null
	company: { __typename?: 'Client'; _id: string }
	toleranceLimits: {
		__typename?: 'ToleranceLimits'
		insects?: number | null
		rodents?: number | null
		birds?: number | null
	}
}

export type FileFragmentFragment = {
	__typename?: 'File'
	_id: string
	name: string
	type?: string | null
	url?: string | null
	mimetype?: string | null
	thumbnail?: string | null
	lastUpdated?: any | null
	acceptance?: number | null
	deleted: boolean
	facility?: { __typename?: 'Facility'; _id: string } | null
}

export type InvoiceFragmentFragment = {
	__typename?: 'Invoice'
	_id: string
	date?: any | null
	currency?: string | null
	isDraft?: boolean | null
	isArchived: boolean
	isSaleNote?: boolean | null
	isCanceled?: boolean | null
	subTotal?: number | null
	taxAmount?: number | null
	type: InvoiceType
	use?: string | null
	total?: number | null
	comments?: string | null
	series?: string | null
	folio?: string | null
	paymentForm?: string | null
	paymentMethod?: InvoicePaymentMethod | null
	paymentConditions?: string | null
	providerResourceId?: string | null
	employee?: { __typename?: 'Employee'; _id: string } | null
	number?: {
		__typename?: 'ResourceNumber'
		company?: string | null
		organization?: string | null
	} | null
	company?: {
		__typename?: 'Client'
		_id: string
		name: string
		legalName: string
	} | null
	stamp?: {
		__typename?: 'InvoiceStamp'
		date?: any | null
		seal?: string | null
	} | null
	items?: Array<{
		__typename?: 'InvoiceItem'
		price: number
		quantity: number
		currency?: Currency | null
		discounts?: Array<string> | null
		taxes?: Array<string> | null
		description?: string | null
		product: { __typename?: 'Product'; _id: string }
	}> | null
}

export type MessageTemplateFragmentFragment = {
	__typename?: 'MessageTemplate'
	_id: string
	type: string
	name: string
	subject?: string | null
	body: string
	design?: string | null
	relatedModel: string
	createdAt: any
	updatedAt: any
	createdBy: { __typename?: 'User'; _id: string; name: string }
}

export type OrganizationFragmentFragment = {
	__typename?: 'Organization'
	_id: string
	apps?: Array<string> | null
	name: string
	legalName: string
	taxId?: string | null
	taxSystem?: string | null
	features?: Array<string> | null
	information?: {
		__typename?: 'OrgInformation'
		website?: string | null
	} | null
	logo?: { __typename?: 'Asset'; url?: string | null } | null
	logoLight?: { __typename?: 'Asset'; url?: string | null } | null
	preferences?: {
		__typename?: 'OrgPreferences'
		language?: string | null
		timeZone?: string | null
		billingEmail?: string | null
	} | null
	subscription?: {
		__typename?: 'Subscription'
		status: Subscription_Status
	} | null
	address?: {
		__typename?: 'Address'
		street?: string | null
		number?: string | null
		city?: string | null
		suburb?: string | null
		municipality?: string | null
		zipCode?: string | null
		state?: string | null
		country?: string | null
	} | null
}

export type ProductFragmentFragment = {
	__typename?: 'Product'
	_id: string
	active?: boolean | null
	isArchived: boolean
	name: string
	producer?: string | null
	type?: string | null
	price?: number | null
	notes?: string | null
	hasTax?: boolean | null
	subType?: string | null
	currency?: Currency | null
	description?: string | null
	sanitaryRegistry?: string | null
	activeIngredient?: string | null
	shortDescription?: string | null
	metadata?: {
		__typename?: 'Metadata'
		productId?: string | null
		priceId?: string | null
	} | null
	tax?: {
		__typename?: 'ProductTax'
		key?: string | null
		unitKey?: string | null
	} | null
	applications?: Array<{
		__typename?: 'ApplicationType'
		dosis?: string | null
		method?: string | null
		throwput?: string | null
		plagues?: Array<string> | null
	}> | null
	tags?: Array<{
		__typename?: 'Tag'
		_id: string
		name: string
		description?: string | null
		color: string
		isArchived?: boolean | null
		collection: string
	}> | null
}

export type PermissionFragmentFragment = {
	__typename?: 'Permission'
	effect: string
	action: string
	resource: string
}

export type RoleFragmentFragment = {
	__typename?: 'Role'
	_id: string
	title?: string | null
	description?: string | null
	permissions: Array<{
		__typename?: 'Permission'
		effect: string
		action: string
		resource: string
	}>
}

export type ServiceFragmentFragment = {
	__typename?: 'Service'
	_id: string
	date: any
	type: number
	frequency?: number | null
	comments?: string | null
	signatureKey?: string | null
	notes?: string | null
	url?: string | null
	facility?: { __typename?: 'Facility'; _id: string; name: string } | null
	units?: { __typename?: 'UnitConnection'; _key: string; count: number } | null
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		count: number
		nodes: Array<{
			__typename?: 'Employee'
			_id: string
			name: string
			surname: string
			user?: {
				__typename?: 'User'
				_id: string
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
		}>
	}
	areas: {
		__typename?: 'AreaConnection'
		_key: string
		count: number
		nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
	}
	products?: {
		__typename?: 'ProductConnection'
		_key: string
		count: number
		nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
	} | null
	client: { __typename?: 'Client'; _id: string; name: string }
	createdBy: {
		__typename?: 'User'
		_id: string
		name: string
		surname?: string | null
	}
	transport?: {
		__typename?: 'TransportApplication'
		name?: string | null
		number?: string | null
	} | null
	application?: {
		__typename?: 'ApplicationType'
		method?: string | null
		duration?: string | null
		dosis?: string | null
	} | null
	payment?: {
		__typename?: 'Payment'
		status?: number | null
		cost?: number | null
		tax?: number | null
	} | null
	tags?: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	} | null
}

export type TagFragmentFragment = {
	__typename?: 'Tag'
	_id: string
	name: string
	description?: string | null
	color: string
	isArchived?: boolean | null
	collection: string
}

export type UserFragmentFragment = {
	__typename?: 'User'
	_id: string
	name: string
	surname?: string | null
	email: string
	phoneNumber?: string | null
	isEmailBouncing?: boolean | null
	company?: { __typename?: 'Client'; _id: string; name: string } | null
	photo?: { __typename?: 'Attachment'; url?: string | null } | null
	preferences?: {
		__typename?: 'UserPreferences'
		language?: string | null
		timeZone?: string | null
		privacy?: {
			__typename?: 'PrivacyUserPreferences'
			tracking?: {
				__typename?: 'TrackingUserPreferences'
				performance?: boolean | null
				product?: boolean | null
				crash?: boolean | null
			} | null
		} | null
	} | null
	organization: {
		__typename?: 'Organization'
		_id: string
		apps?: Array<string> | null
		name: string
		legalName: string
		taxId?: string | null
		taxSystem?: string | null
		features?: Array<string> | null
		information?: {
			__typename?: 'OrgInformation'
			website?: string | null
		} | null
		logo?: { __typename?: 'Asset'; url?: string | null } | null
		logoLight?: { __typename?: 'Asset'; url?: string | null } | null
		preferences?: {
			__typename?: 'OrgPreferences'
			language?: string | null
			timeZone?: string | null
			billingEmail?: string | null
		} | null
		subscription?: {
			__typename?: 'Subscription'
			status: Subscription_Status
		} | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			city?: string | null
			suburb?: string | null
			municipality?: string | null
			zipCode?: string | null
			state?: string | null
			country?: string | null
		} | null
	}
	facilities?: {
		__typename?: 'FacilityConnection'
		_key: string
		nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
	} | null
}

export type CreatePresignedUploadUrlMutationVariables = Exact<{
	input: PresignedUploadUrlInput
}>

export type CreatePresignedUploadUrlMutation = {
	__typename?: 'Mutation'
	presignedUploadUrl: { __typename?: 'PresignedUrl'; key: string; url: string }
}

export type UploadFileMutationVariables = Exact<{
	input: UploadFileInput
}>

export type UploadFileMutation = {
	__typename?: 'Mutation'
	file: {
		__typename?: 'File'
		_id: string
		name: string
		type?: string | null
		url?: string | null
		mimetype?: string | null
		thumbnail?: string | null
		lastUpdated?: any | null
		acceptance?: number | null
		deleted: boolean
		facility?: { __typename?: 'Facility'; _id: string } | null
	}
}

export type CancelInvoiceMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: CancelInvoiceInput
}>

export type CancelInvoiceMutation = {
	__typename?: 'Mutation'
	cancelInvoice: {
		__typename?: 'Invoice'
		_id: string
		date?: any | null
		isCanceled?: boolean | null
		isDraft?: boolean | null
		isSaleNote?: boolean | null
		type: InvoiceType
		total?: number | null
		currency?: string | null
		subTotal?: number | null
		paymentForm?: string | null
		paymentMethod?: InvoicePaymentMethod | null
		transactions?: Array<{
			__typename?: 'Transaction'
			paymentLink?: string | null
		} | null> | null
		company?: {
			__typename?: 'Client'
			_id: string
			name: string
			legalName: string
		} | null
		stamp?: {
			__typename?: 'InvoiceStamp'
			date?: any | null
			seal?: string | null
		} | null
		file?: { __typename?: 'DownloadFile'; url?: string | null } | null
	}
}

export type CreateActivityMutationVariables = Exact<{
	input: ActivityInput
}>

export type CreateActivityMutation = {
	__typename?: 'Mutation'
	activity: {
		__typename?: 'Activity'
		_id: string
		description?: string | null
		type: string
		createdAt?: any | null
		metadata?: any | null
		attachments?: Array<{
			__typename?: 'File'
			_id: string
			url?: string | null
			thumbnail?: string | null
			mimetype?: string | null
		}> | null
		createdBy?: {
			__typename?: 'User'
			_id: string
			name: string
			surname?: string | null
			photo?: { __typename?: 'Attachment'; url?: string | null } | null
		} | null
	}
}

export type CreateAreaMutationVariables = Exact<{
	input: AreaInput
}>

export type CreateAreaMutation = {
	__typename?: 'Mutation'
	area: {
		__typename?: 'Area'
		_id: string
		name: string
		type: number
		isExterior: boolean
		category?: number | null
	}
}

export type CreateCompanyMutationVariables = Exact<{
	input: ClientInput
}>

export type CreateCompanyMutation = {
	__typename?: 'Mutation'
	client: {
		__typename?: 'Client'
		_id: string
		name: string
		taxId?: string | null
		taxSystem?: string | null
		active?: boolean | null
		legalName: string
		type?: Array<string> | null
		services: { __typename?: 'ServiceConnection'; _key: string; count: number }
		phones?: Array<{
			__typename?: 'Phone'
			label: string
			number: string
		} | null> | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			zipCode?: string | null
			suburb?: string | null
			municipality?: string | null
			city?: string | null
			state?: string | null
			country?: string | null
		} | null
		contacts?: {
			__typename?: 'ContactConnection'
			_key: string
			count: number
			nodes: Array<{
				__typename?: 'Contact'
				_id: string
				name: string
				surname: string
				primaryContact?: boolean | null
			}>
		} | null
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
	}
}

export type CreateContactMutationVariables = Exact<{
	input: ContactInput
}>

export type CreateContactMutation = {
	__typename?: 'Mutation'
	contact: {
		__typename?: 'Contact'
		prefix?: string | null
		charge: string
		department: string
		email?: string | null
		phoneNumber?: string | null
		active?: boolean | null
		_id: string
		name: string
		surname: string
		company: { __typename?: 'Client'; _id: string; name: string }
		facilities?: {
			__typename?: 'FacilityConnection'
			_key: string
			count: number
		} | null
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
		user?: {
			__typename?: 'User'
			_id: string
			lastLogin?: any | null
			active?: boolean | null
			hasActiveToken: boolean
			permissions?: Array<{
				__typename?: 'Permission'
				effect: string
				action: string
				resource: string
			}> | null
		} | null
	}
}

export type CreateCustomerPortalSessionMutationVariables = Exact<{
	[key: string]: never
}>

export type CreateCustomerPortalSessionMutation = {
	__typename?: 'Mutation'
	createCustomerPortalSession: {
		__typename?: 'CustomerPortalSession'
		id: string
		url: string
		return_url: string
		livemode: boolean
	}
}

export type CreateExportMutationVariables = Exact<{
	input: ExportInput
}>

export type CreateExportMutation = { __typename?: 'Mutation'; export: string }

export type CreateFacilityMutationVariables = Exact<{
	input: FacilityInput
}>

export type CreateFacilityMutation = {
	__typename?: 'Mutation'
	facility: {
		__typename?: 'Facility'
		_id: string
		name: string
		emails?: Array<string> | null
		active: boolean
		isTransport: boolean
		type?: FacilityType | null
		phones?: Array<{
			__typename?: 'Phone'
			number: string
			label: string
		} | null> | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			city?: string | null
			suburb?: string | null
			municipality?: string | null
			zipCode?: string | null
			state?: string | null
		} | null
		company: { __typename?: 'Client'; _id: string }
		toleranceLimits: {
			__typename?: 'ToleranceLimits'
			insects?: number | null
			rodents?: number | null
			birds?: number | null
		}
	}
}

export type CreateInvoiceMutationVariables = Exact<{
	input: InvoiceInput
}>

export type CreateInvoiceMutation = {
	__typename?: 'Mutation'
	invoice: {
		__typename?: 'Invoice'
		_id: string
		date?: any | null
		currency?: string | null
		isDraft?: boolean | null
		isArchived: boolean
		isSaleNote?: boolean | null
		isCanceled?: boolean | null
		subTotal?: number | null
		taxAmount?: number | null
		type: InvoiceType
		use?: string | null
		total?: number | null
		comments?: string | null
		series?: string | null
		folio?: string | null
		paymentForm?: string | null
		paymentMethod?: InvoicePaymentMethod | null
		paymentConditions?: string | null
		providerResourceId?: string | null
		employee?: { __typename?: 'Employee'; _id: string } | null
		number?: {
			__typename?: 'ResourceNumber'
			company?: string | null
			organization?: string | null
		} | null
		company?: {
			__typename?: 'Client'
			_id: string
			name: string
			legalName: string
		} | null
		stamp?: {
			__typename?: 'InvoiceStamp'
			date?: any | null
			seal?: string | null
		} | null
		items?: Array<{
			__typename?: 'InvoiceItem'
			price: number
			quantity: number
			currency?: Currency | null
			discounts?: Array<string> | null
			taxes?: Array<string> | null
			description?: string | null
			product: { __typename?: 'Product'; _id: string }
		}> | null
	}
}

export type CreateMagicLinkMutationVariables = Exact<{
	input: MagicLinkInput
}>

export type CreateMagicLinkMutation = {
	__typename?: 'Mutation'
	magicLink: string
}

export type CreateMessageTemplateMutationVariables = Exact<{
	input: MessageTemplateInput
}>

export type CreateMessageTemplateMutation = {
	__typename?: 'Mutation'
	messageTemplate: {
		__typename?: 'MessageTemplate'
		_id: string
		type: string
		name: string
		subject?: string | null
		body: string
		design?: string | null
		relatedModel: string
		createdAt: any
		updatedAt: any
		createdBy: { __typename?: 'User'; _id: string; name: string }
	}
}

export type CreateOrganizationMutationVariables = Exact<{
	input: OrganizationInput
}>

export type CreateOrganizationMutation = {
	__typename?: 'Mutation'
	credentials: {
		__typename?: 'Credentials'
		token?: string | null
		organization?: {
			__typename?: 'Organization'
			_id: string
			slug: string
		} | null
		user: { __typename?: 'User'; _id: string }
	}
}

export type CreateProductMutationVariables = Exact<{
	input: ProductInput
}>

export type CreateProductMutation = {
	__typename?: 'Mutation'
	product: {
		__typename?: 'Product'
		_id: string
		active?: boolean | null
		isArchived: boolean
		name: string
		producer?: string | null
		type?: string | null
		price?: number | null
		notes?: string | null
		hasTax?: boolean | null
		subType?: string | null
		currency?: Currency | null
		description?: string | null
		sanitaryRegistry?: string | null
		activeIngredient?: string | null
		shortDescription?: string | null
		metadata?: {
			__typename?: 'Metadata'
			productId?: string | null
			priceId?: string | null
		} | null
		tax?: {
			__typename?: 'ProductTax'
			key?: string | null
			unitKey?: string | null
		} | null
		applications?: Array<{
			__typename?: 'ApplicationType'
			dosis?: string | null
			method?: string | null
			throwput?: string | null
			plagues?: Array<string> | null
		}> | null
		tags?: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}> | null
	}
}

export type CreateStripeAccountLoginLinkMutationVariables = Exact<{
	[key: string]: never
}>

export type CreateStripeAccountLoginLinkMutation = {
	__typename?: 'Mutation'
	createStripeAccountLoginLink: string
}

export type CreateTagMutationVariables = Exact<{
	input: TagInput
}>

export type CreateTagMutation = {
	__typename?: 'Mutation'
	tag: {
		__typename?: 'Tag'
		_id: string
		name: string
		description?: string | null
		color: string
		isArchived?: boolean | null
		collection: string
	}
}

export type CreateUnitServiceMutationVariables = Exact<{
	input: UnitServiceInput
}>

export type CreateUnitServiceMutation = {
	__typename?: 'Mutation'
	unitService?: {
		__typename?: 'UnitService'
		_id: string
		infestation?: number | null
		date?: any | null
		status?: {
			__typename?: 'UnitServiceStatus'
			general: string
			specific?: Array<string> | null
		} | null
		plagues?: Array<{
			__typename?: 'UnitServicePlague'
			type?: string | null
			count?: number | null
		}> | null
		employee?: {
			__typename?: 'Employee'
			_id: string
			user?: { __typename?: 'User'; _id: string } | null
		} | null
		unit: { __typename?: 'Unit'; _id: string }
	} | null
}

export type ExportDataMutationVariables = Exact<{
	input: ExportInput
}>

export type ExportDataMutation = { __typename?: 'Mutation'; export: string }

export type GenerateCalendarMutationVariables = Exact<{
	input: GenerateCalendarInput
}>

export type GenerateCalendarMutation = {
	__typename?: 'Mutation'
	calendar: string
}

export type LogInMutationVariables = Exact<{
	input: LoginInput
}>

export type LogInMutation = {
	__typename?: 'Mutation'
	credentials: {
		__typename?: 'Credentials'
		token?: string | null
		user: {
			__typename?: 'User'
			_id: string
			name: string
			surname?: string | null
			email: string
			phoneNumber?: string | null
			isEmailBouncing?: boolean | null
			company?: { __typename?: 'Client'; _id: string; name: string } | null
			photo?: { __typename?: 'Attachment'; url?: string | null } | null
			preferences?: {
				__typename?: 'UserPreferences'
				language?: string | null
				timeZone?: string | null
				privacy?: {
					__typename?: 'PrivacyUserPreferences'
					tracking?: {
						__typename?: 'TrackingUserPreferences'
						performance?: boolean | null
						product?: boolean | null
						crash?: boolean | null
					} | null
				} | null
			} | null
			organization: {
				__typename?: 'Organization'
				_id: string
				apps?: Array<string> | null
				name: string
				legalName: string
				taxId?: string | null
				taxSystem?: string | null
				features?: Array<string> | null
				information?: {
					__typename?: 'OrgInformation'
					website?: string | null
				} | null
				logo?: { __typename?: 'Asset'; url?: string | null } | null
				logoLight?: { __typename?: 'Asset'; url?: string | null } | null
				preferences?: {
					__typename?: 'OrgPreferences'
					language?: string | null
					timeZone?: string | null
					billingEmail?: string | null
				} | null
				subscription?: {
					__typename?: 'Subscription'
					status: Subscription_Status
				} | null
				address?: {
					__typename?: 'Address'
					street?: string | null
					number?: string | null
					city?: string | null
					suburb?: string | null
					municipality?: string | null
					zipCode?: string | null
					state?: string | null
					country?: string | null
				} | null
			}
			facilities?: {
				__typename?: 'FacilityConnection'
				_key: string
				nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
			} | null
		}
	}
}

export type RequestResetPasswordMutationVariables = Exact<{
	input: RequestResetPasswordInput
}>

export type RequestResetPasswordMutation = {
	__typename?: 'Mutation'
	success: string
}

export type RequestUserVerificationMutationVariables = Exact<{
	user: Scalars['ID']['input']
	input: VerifyUserAttributeInput
}>

export type RequestUserVerificationMutation = {
	__typename?: 'Mutation'
	requestVerifyUserAttribute?: boolean | null
}

export type ResetPasswordMutationVariables = Exact<{
	input: ResetPasswordInput
}>

export type ResetPasswordMutation = {
	__typename?: 'Mutation'
	credentials: {
		__typename?: 'Credentials'
		token?: string | null
		user: {
			__typename?: 'User'
			_id: string
			name: string
			surname?: string | null
			email: string
			phoneNumber?: string | null
			isEmailBouncing?: boolean | null
			company?: { __typename?: 'Client'; _id: string; name: string } | null
			photo?: { __typename?: 'Attachment'; url?: string | null } | null
			preferences?: {
				__typename?: 'UserPreferences'
				language?: string | null
				timeZone?: string | null
				privacy?: {
					__typename?: 'PrivacyUserPreferences'
					tracking?: {
						__typename?: 'TrackingUserPreferences'
						performance?: boolean | null
						product?: boolean | null
						crash?: boolean | null
					} | null
				} | null
			} | null
			organization: {
				__typename?: 'Organization'
				_id: string
				apps?: Array<string> | null
				name: string
				legalName: string
				taxId?: string | null
				taxSystem?: string | null
				features?: Array<string> | null
				information?: {
					__typename?: 'OrgInformation'
					website?: string | null
				} | null
				logo?: { __typename?: 'Asset'; url?: string | null } | null
				logoLight?: { __typename?: 'Asset'; url?: string | null } | null
				preferences?: {
					__typename?: 'OrgPreferences'
					language?: string | null
					timeZone?: string | null
					billingEmail?: string | null
				} | null
				subscription?: {
					__typename?: 'Subscription'
					status: Subscription_Status
				} | null
				address?: {
					__typename?: 'Address'
					street?: string | null
					number?: string | null
					city?: string | null
					suburb?: string | null
					municipality?: string | null
					zipCode?: string | null
					state?: string | null
					country?: string | null
				} | null
			}
			facilities?: {
				__typename?: 'FacilityConnection'
				_key: string
				nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
			} | null
		}
	}
}

export type SendMessageMutationVariables = Exact<{
	input: SendMessageInput
}>

export type SendMessageMutation = {
	__typename?: 'Mutation'
	sendMessage: boolean
}

export type ShareMutationVariables = Exact<{
	input: ShareInput
}>

export type ShareMutation = {
	__typename?: 'Mutation'
	share: {
		__typename?: 'ShareResult'
		recipients: { __typename?: 'RecipientShareResult'; contactsCount: number }
	}
}

export type UpdateContactMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateContactInput
}>

export type UpdateContactMutation = {
	__typename?: 'Mutation'
	updateContact: {
		__typename?: 'Contact'
		prefix?: string | null
		charge: string
		department: string
		email?: string | null
		phoneNumber?: string | null
		active?: boolean | null
		_id: string
		name: string
		surname: string
		company: { __typename?: 'Client'; _id: string; name: string }
		facilities?: {
			__typename?: 'FacilityConnection'
			_key: string
			count: number
		} | null
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
		user?: {
			__typename?: 'User'
			_id: string
			lastLogin?: any | null
			active?: boolean | null
			hasActiveToken: boolean
			permissions?: Array<{
				__typename?: 'Permission'
				effect: string
				action: string
				resource: string
			}> | null
		} | null
	}
}

export type UpdateEmployeeMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateEmployeeInput
}>

export type UpdateEmployeeMutation = {
	__typename?: 'Mutation'
	updateEmployee: {
		__typename?: 'Employee'
		charge?: string | null
		phoneNumber?: string | null
		imss?: string | null
		email?: string | null
		curp?: string | null
		active: boolean
		_id: string
		name: string
		surname: string
		taxId?: string | null
		taxSystem?: string | null
		user?: {
			__typename?: 'User'
			_id: string
			active?: boolean | null
			device?: string | null
			email: string
			photo?: { __typename?: 'Attachment'; url?: string | null } | null
		} | null
		services?: {
			__typename?: 'ServiceConnection'
			_key: string
			count: number
		} | null
	}
}

export type UpdateFacilityMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateFacilityInput
}>

export type UpdateFacilityMutation = {
	__typename?: 'Mutation'
	updateFacility: {
		__typename?: 'Facility'
		_id: string
		name: string
		emails?: Array<string> | null
		active: boolean
		isTransport: boolean
		type?: FacilityType | null
		phones?: Array<{
			__typename?: 'Phone'
			number: string
			label: string
		} | null> | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			city?: string | null
			suburb?: string | null
			municipality?: string | null
			zipCode?: string | null
			state?: string | null
		} | null
		company: { __typename?: 'Client'; _id: string }
		toleranceLimits: {
			__typename?: 'ToleranceLimits'
			insects?: number | null
			rodents?: number | null
			birds?: number | null
		}
	}
}

export type UpdateFileMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateFileInput
}>

export type UpdateFileMutation = {
	__typename?: 'Mutation'
	updateFile: {
		__typename?: 'File'
		_id: string
		name: string
		type?: string | null
		url?: string | null
		mimetype?: string | null
		thumbnail?: string | null
		lastUpdated?: any | null
		acceptance?: number | null
		deleted: boolean
		facility?: { __typename?: 'Facility'; _id: string } | null
	}
}

export type UpdateInvoiceMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateInvoiceInput
}>

export type UpdateInvoiceMutation = {
	__typename?: 'Mutation'
	updateInvoice: {
		__typename?: 'Invoice'
		_id: string
		date?: any | null
		currency?: string | null
		isDraft?: boolean | null
		isArchived: boolean
		isSaleNote?: boolean | null
		isCanceled?: boolean | null
		subTotal?: number | null
		taxAmount?: number | null
		type: InvoiceType
		use?: string | null
		total?: number | null
		comments?: string | null
		series?: string | null
		folio?: string | null
		paymentForm?: string | null
		paymentMethod?: InvoicePaymentMethod | null
		paymentConditions?: string | null
		providerResourceId?: string | null
		employee?: { __typename?: 'Employee'; _id: string } | null
		number?: {
			__typename?: 'ResourceNumber'
			company?: string | null
			organization?: string | null
		} | null
		company?: {
			__typename?: 'Client'
			_id: string
			name: string
			legalName: string
		} | null
		stamp?: {
			__typename?: 'InvoiceStamp'
			date?: any | null
			seal?: string | null
		} | null
		items?: Array<{
			__typename?: 'InvoiceItem'
			price: number
			quantity: number
			currency?: Currency | null
			discounts?: Array<string> | null
			taxes?: Array<string> | null
			description?: string | null
			product: { __typename?: 'Product'; _id: string }
		}> | null
	}
}

export type UpdateMessageTemplateMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateMessageTemplateInput
}>

export type UpdateMessageTemplateMutation = {
	__typename?: 'Mutation'
	updateMessageTemplate: {
		__typename?: 'MessageTemplate'
		_id: string
		type: string
		name: string
		subject?: string | null
		body: string
		design?: string | null
		relatedModel: string
		createdAt: any
		updatedAt: any
		createdBy: { __typename?: 'User'; _id: string; name: string }
	}
}

export type UpdateOrganizationMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateOrganizationInput
}>

export type UpdateOrganizationMutation = {
	__typename?: 'Mutation'
	updateOrganization: {
		__typename?: 'Organization'
		_id: string
		apps?: Array<string> | null
		name: string
		legalName: string
		taxId?: string | null
		taxSystem?: string | null
		features?: Array<string> | null
		information?: {
			__typename?: 'OrgInformation'
			website?: string | null
		} | null
		logo?: { __typename?: 'Asset'; url?: string | null } | null
		logoLight?: { __typename?: 'Asset'; url?: string | null } | null
		preferences?: {
			__typename?: 'OrgPreferences'
			language?: string | null
			timeZone?: string | null
			billingEmail?: string | null
		} | null
		subscription?: {
			__typename?: 'Subscription'
			status: Subscription_Status
		} | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			city?: string | null
			suburb?: string | null
			municipality?: string | null
			zipCode?: string | null
			state?: string | null
			country?: string | null
		} | null
	}
}

export type UpdateProductMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateProductInput
}>

export type UpdateProductMutation = {
	__typename?: 'Mutation'
	updateProduct: {
		__typename?: 'Product'
		_id: string
		active?: boolean | null
		isArchived: boolean
		name: string
		producer?: string | null
		type?: string | null
		price?: number | null
		notes?: string | null
		hasTax?: boolean | null
		subType?: string | null
		currency?: Currency | null
		description?: string | null
		sanitaryRegistry?: string | null
		activeIngredient?: string | null
		shortDescription?: string | null
		metadata?: {
			__typename?: 'Metadata'
			productId?: string | null
			priceId?: string | null
		} | null
		tax?: {
			__typename?: 'ProductTax'
			key?: string | null
			unitKey?: string | null
		} | null
		applications?: Array<{
			__typename?: 'ApplicationType'
			dosis?: string | null
			method?: string | null
			throwput?: string | null
			plagues?: Array<string> | null
		}> | null
		tags?: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}> | null
	}
}

export type UpdateServiceMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateServiceInput
}>

export type UpdateServiceMutation = {
	__typename?: 'Mutation'
	service: {
		__typename?: 'Service'
		_id: string
		type: number
		date: any
		arrivedAt?: any | null
		enRouteAt?: any | null
		completedAt?: any | null
		frequency?: number | null
		url?: string | null
		lot?: string | null
		notes?: string | null
		comments?: string | null
		corrective?: string | null
		signatureKey?: string | null
		completionTime?: number | null
		employees: {
			__typename?: 'EmployeeConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Employee'
				_id: string
				name: string
				surname: string
				user?: { __typename?: 'User'; _id: string } | null
			}>
		}
		application?: {
			__typename?: 'ApplicationType'
			dosis?: string | null
			quantity?: number | null
			method?: string | null
			duration?: string | null
			sites?: Array<string> | null
		} | null
		areas: {
			__typename?: 'AreaConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Area'
				_id: string
				name: string
				active: boolean
			}>
		}
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
		products?: {
			__typename?: 'ProductConnection'
			_key: string
			nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
		} | null
	}
}

export type UpdateTagMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateTagInput
}>

export type UpdateTagMutation = {
	__typename?: 'Mutation'
	updateTag: {
		__typename?: 'Tag'
		_id: string
		name: string
		description?: string | null
		color: string
		isArchived?: boolean | null
		collection: string
	}
}

export type UpdateUnitServiceMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateUnitServiceInput
}>

export type UpdateUnitServiceMutation = {
	__typename?: 'Mutation'
	updateUnitService?: {
		__typename?: 'UnitService'
		_id: string
		infestation?: number | null
		date?: any | null
		status?: {
			__typename?: 'UnitServiceStatus'
			general: string
			specific?: Array<string> | null
		} | null
		plagues?: Array<{
			__typename?: 'UnitServicePlague'
			type?: string | null
			count?: number | null
		}> | null
		employee?: {
			__typename?: 'Employee'
			_id: string
			user?: { __typename?: 'User'; _id: string } | null
		} | null
		unit: { __typename?: 'Unit'; _id: string }
	} | null
}

export type UpdateUserSignupMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateUserInput
}>

export type UpdateUserSignupMutation = {
	__typename?: 'Mutation'
	updateUser: {
		__typename?: 'User'
		_id: string
		name: string
		phoneNumber?: string | null
	}
}

export type UpdateUserMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateUserInput
}>

export type UpdateUserMutation = {
	__typename?: 'Mutation'
	updateUser: {
		__typename?: 'User'
		_id: string
		name: string
		surname?: string | null
		email: string
		phoneNumber?: string | null
		isEmailBouncing?: boolean | null
		contact?: {
			__typename?: 'Contact'
			_id: string
			charge: string
			department: string
			phoneNumber?: string | null
			mobileNumber?: string | null
		} | null
		company?: { __typename?: 'Client'; _id: string; name: string } | null
		photo?: { __typename?: 'Attachment'; url?: string | null } | null
		preferences?: {
			__typename?: 'UserPreferences'
			language?: string | null
			timeZone?: string | null
			privacy?: {
				__typename?: 'PrivacyUserPreferences'
				tracking?: {
					__typename?: 'TrackingUserPreferences'
					performance?: boolean | null
					product?: boolean | null
					crash?: boolean | null
				} | null
			} | null
		} | null
		organization: {
			__typename?: 'Organization'
			_id: string
			apps?: Array<string> | null
			name: string
			legalName: string
			taxId?: string | null
			taxSystem?: string | null
			features?: Array<string> | null
			information?: {
				__typename?: 'OrgInformation'
				website?: string | null
			} | null
			logo?: { __typename?: 'Asset'; url?: string | null } | null
			logoLight?: { __typename?: 'Asset'; url?: string | null } | null
			preferences?: {
				__typename?: 'OrgPreferences'
				language?: string | null
				timeZone?: string | null
				billingEmail?: string | null
			} | null
			subscription?: {
				__typename?: 'Subscription'
				status: Subscription_Status
			} | null
			address?: {
				__typename?: 'Address'
				street?: string | null
				number?: string | null
				city?: string | null
				suburb?: string | null
				municipality?: string | null
				zipCode?: string | null
				state?: string | null
				country?: string | null
			} | null
		}
		facilities?: {
			__typename?: 'FacilityConnection'
			_key: string
			nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
		} | null
		roles: Array<{
			__typename?: 'Role'
			_id: string
			title?: string | null
			description?: string | null
			permissions: Array<{
				__typename?: 'Permission'
				effect: string
				action: string
				resource: string
			}>
		}>
		permissions?: Array<{
			__typename?: 'Permission'
			effect: string
			action: string
			resource: string
		}> | null
	}
}

export type VerifyUserVerificationMutationVariables = Exact<{
	user: Scalars['ID']['input']
	input: VerifyUserAttributeInput
}>

export type VerifyUserVerificationMutation = {
	__typename?: 'Mutation'
	verifyUserAttribute?: boolean | null
}

export type GetAgendaQueryVariables = Exact<{
	sFilter?: InputMaybe<ServicesFilter>
	eFilter?: InputMaybe<EmployeesFilter>
}>

export type GetAgendaQuery = {
	__typename?: 'Query'
	services: {
		__typename?: 'ServiceConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Service'
			_id: string
			date: any
			arrivedAt?: any | null
			completedAt?: any | null
			enRouteAt?: any | null
			type: number
			url?: string | null
			client: { __typename?: 'Client'; _id: string; name: string }
			facility?: { __typename?: 'Facility'; _id: string; name: string } | null
			employees: {
				__typename?: 'EmployeeConnection'
				_key: string
				nodes: Array<{ __typename?: 'Employee'; _id: string }>
			}
			units?: {
				__typename?: 'UnitConnection'
				_key: string
				nodes: Array<{
					__typename?: 'Unit'
					_id: string
					number: string
					status?: { __typename?: 'UnitServiceStatus'; general: string } | null
				}>
			} | null
		}>
	}
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			_id: string
			name: string
			surname: string
			user?: {
				__typename?: 'User'
				_id: string
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
		}>
	}
}

export type GetCompletionQueryVariables = Exact<{
	input: CompletionInput
}>

export type GetCompletionQuery = {
	__typename?: 'Query'
	completion?: {
		__typename?: 'IntelligenceResult'
		_id: string
		choices?: Array<{
			__typename?: 'IntelligenceChoise'
			text?: string | null
		} | null> | null
	} | null
}

export type GetContactQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetContactQuery = {
	__typename?: 'Query'
	contact: {
		__typename?: 'Contact'
		prefix?: string | null
		charge: string
		department: string
		email?: string | null
		phoneNumber?: string | null
		active?: boolean | null
		_id: string
		name: string
		surname: string
		user?: {
			__typename?: 'User'
			_id: string
			lastLogin?: any | null
			active?: boolean | null
			hasActiveToken: boolean
			permissions?: Array<{
				__typename?: 'Permission'
				effect: string
				action: string
				resource: string
			}> | null
		} | null
		facilities?: {
			__typename?: 'FacilityConnection'
			_key: string
			count: number
			nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
		} | null
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
		company: {
			__typename?: 'Client'
			_id: string
			name: string
			role?: CompanyRole | null
		}
	}
}

export type GetFacilitiesCountQueryVariables = Exact<{
	filter?: InputMaybe<FacilitiesFilter>
}>

export type GetFacilitiesCountQuery = {
	__typename?: 'Query'
	facilities: { __typename?: 'FacilityConnection'; count: number; _key: string }
}

export type GetFacilityQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetFacilityQuery = {
	__typename?: 'Query'
	facility: {
		__typename?: 'Facility'
		_id: string
		name: string
		emails?: Array<string> | null
		active: boolean
		isTransport: boolean
		type?: FacilityType | null
		phones?: Array<{
			__typename?: 'Phone'
			number: string
			label: string
		} | null> | null
		address?: {
			__typename?: 'Address'
			street?: string | null
			number?: string | null
			city?: string | null
			suburb?: string | null
			municipality?: string | null
			zipCode?: string | null
			state?: string | null
		} | null
		company: { __typename?: 'Client'; _id: string }
		toleranceLimits: {
			__typename?: 'ToleranceLimits'
			insects?: number | null
			rodents?: number | null
			birds?: number | null
		}
	}
}

export type GetFileQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetFileQuery = {
	__typename?: 'Query'
	file: {
		__typename?: 'File'
		_id: string
		name: string
		type?: string | null
		url?: string | null
		mimetype?: string | null
		thumbnail?: string | null
		lastUpdated?: any | null
		acceptance?: number | null
		deleted: boolean
		facility?: { __typename?: 'Facility'; _id: string } | null
	}
}

export type GetInvoiceQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetInvoiceQuery = {
	__typename?: 'Query'
	invoice: {
		__typename?: 'Invoice'
		_id: string
		date?: any | null
		isDraft?: boolean | null
		isArchived: boolean
		isSaleNote?: boolean | null
		isCanceled?: boolean | null
		type: InvoiceType
		paymentForm?: string | null
		paymentMethod?: InvoicePaymentMethod | null
		currency?: string | null
		subTotal?: number | null
		total?: number | null
		taxAmount?: number | null
		transactions?: Array<{
			__typename?: 'Transaction'
			paymentLink?: string | null
		} | null> | null
		items?: Array<{
			__typename?: 'InvoiceItem'
			price: number
			quantity: number
			currency?: Currency | null
			discounts?: Array<string> | null
			taxes?: Array<string> | null
			description?: string | null
			product: { __typename?: 'Product'; _id: string }
		}> | null
		company?: {
			__typename?: 'Client'
			_id: string
			name: string
			legalName: string
		} | null
		number?: {
			__typename?: 'ResourceNumber'
			organization?: string | null
			company?: string | null
		} | null
		stamp?: {
			__typename?: 'InvoiceStamp'
			date?: any | null
			seal?: string | null
		} | null
		file?: { __typename?: 'DownloadFile'; url?: string | null } | null
	}
}

export type GetInvoiceEditQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetInvoiceEditQuery = {
	__typename?: 'Query'
	invoice: {
		__typename?: 'Invoice'
		_id: string
		date?: any | null
		currency?: string | null
		isDraft?: boolean | null
		isArchived: boolean
		isSaleNote?: boolean | null
		isCanceled?: boolean | null
		subTotal?: number | null
		taxAmount?: number | null
		type: InvoiceType
		use?: string | null
		total?: number | null
		comments?: string | null
		series?: string | null
		folio?: string | null
		paymentForm?: string | null
		paymentMethod?: InvoicePaymentMethod | null
		paymentConditions?: string | null
		providerResourceId?: string | null
		employee?: { __typename?: 'Employee'; _id: string } | null
		number?: {
			__typename?: 'ResourceNumber'
			company?: string | null
			organization?: string | null
		} | null
		company?: {
			__typename?: 'Client'
			_id: string
			name: string
			legalName: string
		} | null
		stamp?: {
			__typename?: 'InvoiceStamp'
			date?: any | null
			seal?: string | null
		} | null
		items?: Array<{
			__typename?: 'InvoiceItem'
			price: number
			quantity: number
			currency?: Currency | null
			discounts?: Array<string> | null
			taxes?: Array<string> | null
			description?: string | null
			product: { __typename?: 'Product'; _id: string }
		}> | null
	}
}

export type GetLocalFieldsQueryVariables = Exact<{ [key: string]: never }>

export type GetLocalFieldsQuery = {
	__typename?: 'Query'
	oAuth?: string | null
	selectedFacility?: string | null
}

export type GetOrganizationInsightsQueryVariables = Exact<{
	[key: string]: never
}>

export type GetOrganizationInsightsQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		organization: {
			__typename?: 'Organization'
			_id: string
			insights?: {
				__typename?: 'OrgInsights'
				charts?: Array<{
					__typename?: 'InsightsChart'
					id: string
					title?: string | null
					size?: string | null
				} | null> | null
			} | null
		}
	}
}

export type GetOrganizationPreferencesQueryVariables = Exact<{
	[key: string]: never
}>

export type GetOrganizationPreferencesQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		organization: {
			__typename?: 'Organization'
			_id: string
			preferences?: {
				__typename?: 'OrgPreferences'
				language?: string | null
				timeZone?: string | null
				billingEmail?: string | null
				notifications?: {
					__typename?: 'NotificationsOrgPreferences'
					slack?: {
						__typename?: 'NotificationSetting'
						enabled: boolean
						webhookUrl?: string | null
					} | null
				} | null
			} | null
		}
	}
}

export type GetPendingTasksQueryVariables = Exact<{ [key: string]: never }>

export type GetPendingTasksQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		organization: {
			__typename?: 'Organization'
			_id: string
			legalName: string
			taxId?: string | null
			taxSystem?: string | null
			isPayoutOnboardingCompleted?: boolean | null
			address?: { __typename?: 'Address'; zipCode?: string | null } | null
		}
	}
	clients: { __typename?: 'ClientConnection'; _key: string; count: number }
}

export type GetProductQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetProductQuery = {
	__typename?: 'Query'
	product: {
		__typename?: 'Product'
		_id: string
		active?: boolean | null
		isArchived: boolean
		name: string
		producer?: string | null
		type?: string | null
		price?: number | null
		notes?: string | null
		hasTax?: boolean | null
		subType?: string | null
		currency?: Currency | null
		description?: string | null
		sanitaryRegistry?: string | null
		activeIngredient?: string | null
		shortDescription?: string | null
		metadata?: {
			__typename?: 'Metadata'
			productId?: string | null
			priceId?: string | null
		} | null
		tax?: {
			__typename?: 'ProductTax'
			key?: string | null
			unitKey?: string | null
		} | null
		applications?: Array<{
			__typename?: 'ApplicationType'
			dosis?: string | null
			method?: string | null
			throwput?: string | null
			plagues?: Array<string> | null
		}> | null
		tags?: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}> | null
	}
}

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetProfileQuery = {
	__typename?: 'Query'
	oAuth?: string | null
	self: {
		__typename?: 'User'
		_id: string
		email: string
		webAuthn: Array<{ __typename?: 'WebAuthn'; name: string }>
	}
}

export type GetSearchQueryVariables = Exact<{
	input: SearchInput
}>

export type GetSearchQuery = {
	__typename?: 'Query'
	search: {
		__typename?: 'SearchResultConnection'
		_key: string
		nodes: Array<
			| { __typename?: 'Area' }
			| { __typename: 'Client'; _id: string; name: string; legalName: string }
			| {
					__typename: 'Contact'
					_id: string
					name: string
					surname: string
					companyContact: { __typename?: 'Client'; _id: string; name: string }
			  }
			| { __typename: 'Employee'; _id: string; name: string; surname: string }
			| {
					__typename: 'Facility'
					_id: string
					name: string
					company: { __typename?: 'Client'; _id: string; name: string }
			  }
			| { __typename: 'File'; _id: string; url?: string | null; name: string }
			| { __typename?: 'Invoice' }
			| { __typename?: 'Issue' }
			| { __typename: 'Product'; _id: string; name: string }
			| { __typename?: 'Quote' }
			| { __typename?: 'Tag' }
			| { __typename?: 'Transaction' }
			| {
					__typename: 'Unit'
					_id: string
					number: string
					type: string
					company: { __typename?: 'Client'; _id: string; name: string }
			  }
		>
	}
}

export type GetSelfQueryVariables = Exact<{ [key: string]: never }>

export type GetSelfQuery = {
	__typename?: 'Query'
	selectedFacility?: string | null
	self: {
		__typename?: 'User'
		_id: string
		name: string
		surname?: string | null
		email: string
		phoneNumber?: string | null
		isEmailBouncing?: boolean | null
		company?: { __typename?: 'Client'; _id: string; name: string } | null
		photo?: { __typename?: 'Attachment'; url?: string | null } | null
		preferences?: {
			__typename?: 'UserPreferences'
			language?: string | null
			timeZone?: string | null
			privacy?: {
				__typename?: 'PrivacyUserPreferences'
				tracking?: {
					__typename?: 'TrackingUserPreferences'
					performance?: boolean | null
					product?: boolean | null
					crash?: boolean | null
				} | null
			} | null
		} | null
		organization: {
			__typename?: 'Organization'
			_id: string
			apps?: Array<string> | null
			name: string
			legalName: string
			taxId?: string | null
			taxSystem?: string | null
			features?: Array<string> | null
			information?: {
				__typename?: 'OrgInformation'
				website?: string | null
			} | null
			logo?: { __typename?: 'Asset'; url?: string | null } | null
			logoLight?: { __typename?: 'Asset'; url?: string | null } | null
			preferences?: {
				__typename?: 'OrgPreferences'
				language?: string | null
				timeZone?: string | null
				billingEmail?: string | null
			} | null
			subscription?: {
				__typename?: 'Subscription'
				status: Subscription_Status
			} | null
			address?: {
				__typename?: 'Address'
				street?: string | null
				number?: string | null
				city?: string | null
				suburb?: string | null
				municipality?: string | null
				zipCode?: string | null
				state?: string | null
				country?: string | null
			} | null
		}
		facilities?: {
			__typename?: 'FacilityConnection'
			_key: string
			nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
		} | null
	}
}

export type GetServiceUnitsQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetServiceUnitsQuery = {
	__typename?: 'Query'
	service: {
		__typename?: 'Service'
		_id: string
		date: any
		url?: string | null
		employees: {
			__typename?: 'EmployeeConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Employee'
				_id: string
				name: string
				surname: string
				user?: { __typename?: 'User'; _id: string } | null
			}>
		}
		units?: {
			__typename?: 'UnitConnection'
			_key: string
			count: number
			nodes: Array<{
				__typename?: 'Unit'
				_id: string
				number: string
				type: string
			}>
		} | null
	}
}

export type GetServicesCountQueryVariables = Exact<{
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetServicesCountQuery = {
	__typename?: 'Query'
	services: { __typename?: 'ServiceConnection'; _key: string; count: number }
}

export type GetServicesWithReportsQueryVariables = Exact<{
	filter: ServicesFilter
	limit?: InputMaybe<Scalars['Int']['input']>
	sort: ServicesSort
}>

export type GetServicesWithReportsQuery = {
	__typename?: 'Query'
	services: {
		__typename?: 'ServiceConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Service'
			_id: string
			type: number
			url?: string | null
			date: any
			thumbnail?: string | null
		}>
	}
}

export type GetMessageTemplateQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetMessageTemplateQuery = {
	__typename?: 'Query'
	messageTemplate: {
		__typename?: 'MessageTemplate'
		_id: string
		type: string
		name: string
		subject?: string | null
		body: string
		design?: string | null
		relatedModel: string
		createdAt: any
		updatedAt: any
		createdBy: { __typename?: 'User'; _id: string; name: string }
	}
}

export type GetTransportsQueryVariables = Exact<{ [key: string]: never }>

export type GetTransportsQuery = {
	__typename?: 'Query'
	transports: {
		__typename?: 'TransportConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Transport'
			_id: string
			name: string
			cost?: number | null
			surfaceArea?: number | null
		}>
	}
}

export type GetUserQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetUserQuery = {
	__typename?: 'Query'
	user: {
		__typename?: 'User'
		_id: string
		lastLogin?: any | null
		hasActiveToken: boolean
		isEmailBouncing?: boolean | null
		organization: { __typename?: 'Organization'; _id: string }
		contact?: {
			__typename?: 'Contact'
			_id: string
			email?: string | null
			company: {
				__typename?: 'Client'
				_id: string
				facilities: {
					__typename?: 'FacilityConnection'
					_key: string
					nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
				}
			}
		} | null
		roles: Array<{
			__typename?: 'Role'
			_id: string
			title?: string | null
			description?: string | null
			permissions: Array<{
				__typename?: 'Permission'
				effect: string
				action: string
				resource: string
			}>
		}>
		permissions?: Array<{
			__typename?: 'Permission'
			effect: string
			action: string
			resource: string
		}> | null
	}
}

export type ListActivitiesQueryVariables = Exact<{
	filter: ActivitiesFilter
	sort?: InputMaybe<ActivitiesSort>
}>

export type ListActivitiesQuery = {
	__typename?: 'Query'
	activities: {
		__typename?: 'ActivityConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Activity'
			_id: string
			description?: string | null
			type: string
			createdAt?: any | null
			metadata?: any | null
			attachments?: Array<{
				__typename?: 'File'
				_id: string
				url?: string | null
				thumbnail?: string | null
				mimetype?: string | null
			}> | null
			createdBy?: {
				__typename?: 'User'
				_id: string
				name: string
				surname?: string | null
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
		}>
	}
}

export type ListActivityQueryVariables = Exact<{
	filter: ActivitiesFilter
	sort?: InputMaybe<ActivitiesSort>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type ListActivityQuery = {
	__typename?: 'Query'
	activities: {
		__typename?: 'ActivityConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Activity'
			_id: string
			ipAddress?: string | null
			userAgent?: string | null
			createdAt?: any | null
			metadata?: any | null
		}>
	}
}

export type ListAreaDetailQueryVariables = Exact<{
	facility: Scalars['ID']['input']
	filter?: InputMaybe<AreasFilter>
}>

export type ListAreaDetailQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		organization: {
			__typename?: 'Organization'
			_id: string
			logo?: { __typename?: 'Asset'; url?: string | null } | null
		}
	}
	facility: {
		__typename?: 'Facility'
		_id: string
		company: { __typename?: 'Client'; _id: string }
	}
	areas: {
		__typename?: 'AreaConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Area'
			_id: string
			name: string
			type: number
			isExterior: boolean
			active: boolean
			units?: {
				__typename?: 'UnitConnection'
				_key: string
				count: number
			} | null
		}>
	}
}

export type ListAreasQueryVariables = Exact<{
	filter?: InputMaybe<AreasFilter>
}>

export type ListAreasQuery = {
	__typename?: 'Query'
	areas: {
		__typename?: 'AreaConnection'
		_key: string
		count: number
		nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
	}
}

export type ListClientsWithServicesQueryVariables = Exact<{
	servicesFilter?: InputMaybe<ServicesFilter>
	clientsFilter?: InputMaybe<CompaniesFilter>
}>

export type ListClientsWithServicesQuery = {
	__typename?: 'Query'
	clients: {
		__typename?: 'ClientConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Client'
			_id: string
			name: string
			taxId?: string | null
			taxSystem?: string | null
			active?: boolean | null
			legalName: string
			type?: Array<string> | null
			services: {
				__typename?: 'ServiceConnection'
				_key: string
				count: number
			}
			phones?: Array<{
				__typename?: 'Phone'
				label: string
				number: string
			} | null> | null
			address?: {
				__typename?: 'Address'
				street?: string | null
				number?: string | null
				zipCode?: string | null
				suburb?: string | null
				municipality?: string | null
				city?: string | null
				state?: string | null
				country?: string | null
			} | null
			contacts?: {
				__typename?: 'ContactConnection'
				_key: string
				count: number
				nodes: Array<{
					__typename?: 'Contact'
					_id: string
					name: string
					surname: string
					primaryContact?: boolean | null
				}>
			} | null
			tags?: {
				__typename?: 'TagConnection'
				_key: string
				nodes: Array<{
					__typename?: 'Tag'
					_id: string
					name: string
					description?: string | null
					color: string
					isArchived?: boolean | null
					collection: string
				}>
			} | null
		}>
	}
}

export type ListCompaniesQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	filter?: InputMaybe<CompaniesFilter>
}>

export type ListCompaniesQuery = {
	__typename?: 'Query'
	clients: {
		__typename?: 'ClientConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Client'
			_id: string
			name: string
			taxId?: string | null
			legalName: string
			taxSystem?: string | null
			address?: { __typename?: 'Address'; zipCode?: string | null } | null
		}>
	}
}

export type ListCompanyFacilitiesQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type ListCompanyFacilitiesQuery = {
	__typename?: 'Query'
	client: {
		__typename?: 'Client'
		_id: string
		role?: CompanyRole | null
		contacts?: {
			__typename?: 'ContactConnection'
			_key: string
			count: number
		} | null
		facilities: {
			__typename?: 'FacilityConnection'
			_key: string
			count: number
			nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
		}
	}
}

export type ListContactsQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	filter?: InputMaybe<ContactsFilter>
	cFilter?: InputMaybe<CompaniesFilter>
}>

export type ListContactsQuery = {
	__typename?: 'Query'
	contacts: {
		__typename?: 'ContactConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Contact'
			prefix?: string | null
			charge: string
			department: string
			email?: string | null
			phoneNumber?: string | null
			active?: boolean | null
			_id: string
			name: string
			surname: string
			company: { __typename?: 'Client'; _id: string; name: string }
			facilities?: {
				__typename?: 'FacilityConnection'
				_key: string
				count: number
			} | null
			tags?: {
				__typename?: 'TagConnection'
				_key: string
				nodes: Array<{
					__typename?: 'Tag'
					_id: string
					name: string
					description?: string | null
					color: string
					isArchived?: boolean | null
					collection: string
				}>
			} | null
			user?: {
				__typename?: 'User'
				_id: string
				lastLogin?: any | null
				active?: boolean | null
				hasActiveToken: boolean
				permissions?: Array<{
					__typename?: 'Permission'
					effect: string
					action: string
					resource: string
				}> | null
			} | null
		}>
	}
	clients: {
		__typename?: 'ClientConnection'
		_key: string
		nodes: Array<{ __typename?: 'Client'; _id: string; name: string }>
	}
}

export type ListEmployeeAndContactUsersQueryVariables = Exact<{
	eFilter: EmployeesFilter
	cFilter: ContactsFilter
}>

export type ListEmployeeAndContactUsersQuery = {
	__typename?: 'Query'
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			user?: {
				__typename?: 'User'
				_id: string
				name: string
				surname?: string | null
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
		}>
	}
	contacts: {
		__typename?: 'ContactConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Contact'
			user?: {
				__typename?: 'User'
				_id: string
				name: string
				surname?: string | null
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
		}>
	}
}

export type ListEmployeeLocationsQueryVariables = Exact<{
	employeesFilter?: InputMaybe<EmployeesFilter>
	locationsFilter?: InputMaybe<LocationsFilter>
}>

export type ListEmployeeLocationsQuery = {
	__typename?: 'Query'
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			_id: string
			user?: {
				__typename?: 'User'
				_id: string
				name: string
				surname?: string | null
				device?: string | null
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
				locations?: {
					__typename?: 'LocationConnection'
					nodes: Array<{
						__typename?: 'Location'
						coordinates: Array<number>
						at: any
					}>
				} | null
			} | null
		}>
	}
}

export type ListEmployeesQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	filter?: InputMaybe<EmployeesFilter>
}>

export type ListEmployeesQuery = {
	__typename?: 'Query'
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			charge?: string | null
			phoneNumber?: string | null
			imss?: string | null
			email?: string | null
			curp?: string | null
			active: boolean
			_id: string
			name: string
			surname: string
			taxId?: string | null
			taxSystem?: string | null
			user?: {
				__typename?: 'User'
				_id: string
				active?: boolean | null
				device?: string | null
				email: string
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
			services?: {
				__typename?: 'ServiceConnection'
				_key: string
				count: number
			} | null
		}>
	}
}

export type ListFacilitiesQueryVariables = Exact<{
	filter?: InputMaybe<FacilitiesFilter>
}>

export type ListFacilitiesQuery = {
	__typename?: 'Query'
	facilities: {
		__typename?: 'FacilityConnection'
		_key: string
		count: number
		nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
	}
}

export type ListFacilitiesUnitsQueryVariables = Exact<{
	filter?: InputMaybe<FacilitiesFilter>
}>

export type ListFacilitiesUnitsQuery = {
	__typename?: 'Query'
	facilities: {
		__typename?: 'FacilityConnection'
		_key: string
		count: number
		nodes: Array<{
			__typename?: 'Facility'
			_id: string
			name: string
			units?: {
				__typename?: 'UnitConnection'
				_key: string
				count: number
				nodes: Array<{
					__typename?: 'Unit'
					_id: string
					number: string
					type: string
					area?: {
						__typename?: 'Area'
						name: string
						isExterior: boolean
					} | null
				}>
			} | null
		}>
	}
}

export type ListFilesQueryVariables = Exact<{
	filter: FilesFilter
}>

export type ListFilesQuery = {
	__typename?: 'Query'
	files: {
		__typename?: 'FileConnection'
		_key: string
		nodes: Array<{
			__typename?: 'File'
			_id: string
			name: string
			type?: string | null
			url?: string | null
			mimetype?: string | null
			thumbnail?: string | null
			lastUpdated?: any | null
			acceptance?: number | null
			deleted: boolean
			facility?: { __typename?: 'Facility'; _id: string } | null
		}>
	}
}

export type ListInvoicesQueryVariables = Exact<{
	filter?: InputMaybe<InvoicesFilter>
	sort?: InputMaybe<InvoicesSort>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type ListInvoicesQuery = {
	__typename?: 'Query'
	invoices: {
		__typename?: 'InvoiceConnection'
		_key: string
		count: number
		nodes: Array<{
			__typename?: 'Invoice'
			_id: string
			date?: any | null
			currency?: string | null
			isDraft?: boolean | null
			isArchived: boolean
			isSaleNote?: boolean | null
			isCanceled?: boolean | null
			subTotal?: number | null
			taxAmount?: number | null
			type: InvoiceType
			use?: string | null
			total?: number | null
			comments?: string | null
			series?: string | null
			folio?: string | null
			paymentForm?: string | null
			paymentMethod?: InvoicePaymentMethod | null
			paymentConditions?: string | null
			providerResourceId?: string | null
			employee?: { __typename?: 'Employee'; _id: string } | null
			number?: {
				__typename?: 'ResourceNumber'
				company?: string | null
				organization?: string | null
			} | null
			company?: {
				__typename?: 'Client'
				_id: string
				name: string
				legalName: string
			} | null
			stamp?: {
				__typename?: 'InvoiceStamp'
				date?: any | null
				seal?: string | null
			} | null
			items?: Array<{
				__typename?: 'InvoiceItem'
				price: number
				quantity: number
				currency?: Currency | null
				discounts?: Array<string> | null
				taxes?: Array<string> | null
				description?: string | null
				product: { __typename?: 'Product'; _id: string }
			}> | null
		}>
	}
}

export type ListMessageTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type ListMessageTemplatesQuery = {
	__typename?: 'Query'
	messageTemplates: {
		__typename?: 'MessageTemplateConnection'
		_key: string
		nodes?: Array<{
			__typename?: 'MessageTemplate'
			_id: string
			name: string
			body: string
			createdAt: any
			createdBy: { __typename?: 'User'; _id: string; name: string }
		}> | null
	}
}

export type GetProductsQueryVariables = Exact<{
	filter?: InputMaybe<ProductsFilter>
}>

export type GetProductsQuery = {
	__typename?: 'Query'
	products: {
		__typename?: 'ProductConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Product'
			_id: string
			active?: boolean | null
			isArchived: boolean
			name: string
			producer?: string | null
			type?: string | null
			price?: number | null
			notes?: string | null
			hasTax?: boolean | null
			subType?: string | null
			currency?: Currency | null
			description?: string | null
			sanitaryRegistry?: string | null
			activeIngredient?: string | null
			shortDescription?: string | null
			metadata?: {
				__typename?: 'Metadata'
				productId?: string | null
				priceId?: string | null
			} | null
			tax?: {
				__typename?: 'ProductTax'
				key?: string | null
				unitKey?: string | null
			} | null
			applications?: Array<{
				__typename?: 'ApplicationType'
				dosis?: string | null
				method?: string | null
				throwput?: string | null
				plagues?: Array<string> | null
			}> | null
			tags?: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}> | null
		}>
	}
}

export type ListServicesQueryVariables = Exact<{
	filter?: InputMaybe<ServicesFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	sort?: InputMaybe<ServicesSort>
	hasAnalytics: Scalars['Boolean']['input']
}>

export type ListServicesQuery = {
	__typename?: 'Query'
	services: {
		__typename?: 'ServiceConnection'
		_key: string
		count: number
		nodes: Array<{
			__typename?: 'Service'
			completionTime?: number | null
			estimatedCompletionTime?: number | null
			_id: string
			date: any
			type: number
			frequency?: number | null
			comments?: string | null
			signatureKey?: string | null
			notes?: string | null
			url?: string | null
			tags?: {
				__typename?: 'TagConnection'
				_key: string
				nodes: Array<{
					__typename?: 'Tag'
					_id: string
					name: string
					description?: string | null
					color: string
					isArchived?: boolean | null
					collection: string
				}>
			} | null
			facility?: { __typename?: 'Facility'; _id: string; name: string } | null
			units?: {
				__typename?: 'UnitConnection'
				_key: string
				count: number
			} | null
			employees: {
				__typename?: 'EmployeeConnection'
				_key: string
				count: number
				nodes: Array<{
					__typename?: 'Employee'
					_id: string
					name: string
					surname: string
					user?: {
						__typename?: 'User'
						_id: string
						photo?: { __typename?: 'Attachment'; url?: string | null } | null
					} | null
				}>
			}
			areas: {
				__typename?: 'AreaConnection'
				_key: string
				count: number
				nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
			}
			products?: {
				__typename?: 'ProductConnection'
				_key: string
				count: number
				nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
			} | null
			client: { __typename?: 'Client'; _id: string; name: string }
			createdBy: {
				__typename?: 'User'
				_id: string
				name: string
				surname?: string | null
			}
			transport?: {
				__typename?: 'TransportApplication'
				name?: string | null
				number?: string | null
			} | null
			application?: {
				__typename?: 'ApplicationType'
				method?: string | null
				duration?: string | null
				dosis?: string | null
			} | null
			payment?: {
				__typename?: 'Payment'
				status?: number | null
				cost?: number | null
				tax?: number | null
			} | null
		}>
	}
}

export type ListTagsQueryVariables = Exact<{
	filter?: InputMaybe<TagsFilter>
}>

export type ListTagsQuery = {
	__typename?: 'Query'
	tags: {
		__typename?: 'TagConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Tag'
			_id: string
			name: string
			description?: string | null
			color: string
			isArchived?: boolean | null
			collection: string
		}>
	}
}

export type GetSelfAndContactQueryVariables = Exact<{ [key: string]: never }>

export type GetSelfAndContactQuery = {
	__typename?: 'Query'
	self: {
		__typename?: 'User'
		_id: string
		name: string
		surname?: string | null
		email: string
		phoneNumber?: string | null
		isEmailBouncing?: boolean | null
		contact?: {
			__typename?: 'Contact'
			_id: string
			charge: string
			department: string
			phoneNumber?: string | null
			mobileNumber?: string | null
		} | null
		company?: { __typename?: 'Client'; _id: string; name: string } | null
		photo?: { __typename?: 'Attachment'; url?: string | null } | null
		preferences?: {
			__typename?: 'UserPreferences'
			language?: string | null
			timeZone?: string | null
			privacy?: {
				__typename?: 'PrivacyUserPreferences'
				tracking?: {
					__typename?: 'TrackingUserPreferences'
					performance?: boolean | null
					product?: boolean | null
					crash?: boolean | null
				} | null
			} | null
		} | null
		organization: {
			__typename?: 'Organization'
			_id: string
			apps?: Array<string> | null
			name: string
			legalName: string
			taxId?: string | null
			taxSystem?: string | null
			features?: Array<string> | null
			information?: {
				__typename?: 'OrgInformation'
				website?: string | null
			} | null
			logo?: { __typename?: 'Asset'; url?: string | null } | null
			logoLight?: { __typename?: 'Asset'; url?: string | null } | null
			preferences?: {
				__typename?: 'OrgPreferences'
				language?: string | null
				timeZone?: string | null
				billingEmail?: string | null
			} | null
			subscription?: {
				__typename?: 'Subscription'
				status: Subscription_Status
			} | null
			address?: {
				__typename?: 'Address'
				street?: string | null
				number?: string | null
				city?: string | null
				suburb?: string | null
				municipality?: string | null
				zipCode?: string | null
				state?: string | null
				country?: string | null
			} | null
		}
		facilities?: {
			__typename?: 'FacilityConnection'
			_key: string
			nodes: Array<{ __typename?: 'Facility'; _id: string; name: string }>
		} | null
	}
}

export type CreateUnitMutationVariables = Exact<{
	input: UnitInput
}>

export type CreateUnitMutation = {
	__typename?: 'Mutation'
	unit: {
		__typename?: 'Unit'
		_id: string
		number: string
		type: string
		active: boolean
		area?: {
			__typename?: 'Area'
			_id: string
			name: string
			isExterior: boolean
		} | null
	}
}

export type GetFacilityAreasQueryVariables = Exact<{
	facility: Scalars['ID']['input']
}>

export type GetFacilityAreasQuery = {
	__typename?: 'Query'
	facility: {
		__typename?: 'Facility'
		_id: string
		areas?: {
			__typename?: 'AreaConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Area'
				_id: string
				name: string
				type: number
				isExterior: boolean
			}>
		} | null
		company: { __typename?: 'Client'; _id: string }
	}
}

export type CreateEmployeeMutationVariables = Exact<{
	input: EmployeeInput
}>

export type CreateEmployeeMutation = {
	__typename?: 'Mutation'
	employee: {
		__typename?: 'Employee'
		charge?: string | null
		phoneNumber?: string | null
		imss?: string | null
		email?: string | null
		curp?: string | null
		active: boolean
		_id: string
		name: string
		surname: string
		taxId?: string | null
		taxSystem?: string | null
		user?: {
			__typename?: 'User'
			_id: string
			active?: boolean | null
			device?: string | null
			email: string
			photo?: { __typename?: 'Attachment'; url?: string | null } | null
		} | null
		services?: {
			__typename?: 'ServiceConnection'
			_key: string
			count: number
		} | null
	}
}

export type CreateServiceMutationVariables = Exact<{
	input: ServiceInput
}>

export type CreateServiceMutation = {
	__typename?: 'Mutation'
	services: Array<{
		__typename?: 'Service'
		_id: string
		date: any
		type: number
		frequency?: number | null
		comments?: string | null
		signatureKey?: string | null
		notes?: string | null
		url?: string | null
		facility?: { __typename?: 'Facility'; _id: string; name: string } | null
		units?: {
			__typename?: 'UnitConnection'
			_key: string
			count: number
		} | null
		employees: {
			__typename?: 'EmployeeConnection'
			_key: string
			count: number
			nodes: Array<{
				__typename?: 'Employee'
				_id: string
				name: string
				surname: string
				user?: {
					__typename?: 'User'
					_id: string
					photo?: { __typename?: 'Attachment'; url?: string | null } | null
				} | null
			}>
		}
		areas: {
			__typename?: 'AreaConnection'
			_key: string
			count: number
			nodes: Array<{ __typename?: 'Area'; _id: string; name: string }>
		}
		products?: {
			__typename?: 'ProductConnection'
			_key: string
			count: number
			nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>
		} | null
		client: { __typename?: 'Client'; _id: string; name: string }
		createdBy: {
			__typename?: 'User'
			_id: string
			name: string
			surname?: string | null
		}
		transport?: {
			__typename?: 'TransportApplication'
			name?: string | null
			number?: string | null
		} | null
		application?: {
			__typename?: 'ApplicationType'
			method?: string | null
			duration?: string | null
			dosis?: string | null
		} | null
		payment?: {
			__typename?: 'Payment'
			status?: number | null
			cost?: number | null
			tax?: number | null
		} | null
		tags?: {
			__typename?: 'TagConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Tag'
				_id: string
				name: string
				description?: string | null
				color: string
				isArchived?: boolean | null
				collection: string
			}>
		} | null
	}>
}

export type GetTransportOptionsQueryVariables = Exact<{
	clientsFilter?: InputMaybe<CompaniesFilter>
}>

export type GetTransportOptionsQuery = {
	__typename?: 'Query'
	transports: {
		__typename?: 'TransportConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Transport'
			_id: string
			name: string
			cost?: number | null
		}>
	}
}

export type PlaguePresenceQueryVariables = Exact<{
	options?: InputMaybe<PlaguePresenceOptions>
	filter: PlaguePresenceFilter
}>

export type PlaguePresenceQuery = {
	__typename?: 'Query'
	plaguePresence: {
		__typename?: 'PlaguePresenceConnection'
		_key: string
		nodes: Array<{
			__typename?: 'PlaguePresence'
			date: any
			plagueCount?: Array<{
				__typename?: 'PlagueCount'
				plagueType: string
				count: number
			}> | null
		}>
	}
}

export type GenerateReportMutationVariables = Exact<{
	input: SendReportInput
}>

export type GenerateReportMutation = {
	__typename?: 'Mutation'
	generated?: Array<{
		__typename?: 'DownloadFile'
		url?: string | null
		key?: string | null
	}> | null
}

export type GetTransportInputOptionsQueryVariables = Exact<{
	employeesFilter?: InputMaybe<EmployeesFilter>
	clientsFilter?: InputMaybe<CompaniesFilter>
}>

export type GetTransportInputOptionsQuery = {
	__typename?: 'Query'
	products: {
		__typename?: 'ProductConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Product'
			_id: string
			name: string
			applications?: Array<{
				__typename?: 'ApplicationType'
				dosis?: string | null
				method?: string | null
				throwput?: string | null
			}> | null
		}>
	}
	clients: {
		__typename?: 'ClientConnection'
		_key: string
		nodes: Array<{ __typename?: 'Client'; _id: string; name: string }>
	}
	employees: {
		__typename?: 'EmployeeConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Employee'
			charge?: string | null
			phoneNumber?: string | null
			imss?: string | null
			email?: string | null
			curp?: string | null
			active: boolean
			_id: string
			name: string
			surname: string
			taxId?: string | null
			taxSystem?: string | null
			user?: {
				__typename?: 'User'
				_id: string
				active?: boolean | null
				device?: string | null
				email: string
				photo?: { __typename?: 'Attachment'; url?: string | null } | null
			} | null
			services?: {
				__typename?: 'ServiceConnection'
				_key: string
				count: number
			} | null
		}>
	}
	transports: {
		__typename?: 'TransportConnection'
		_key: string
		nodes: Array<{
			__typename?: 'Transport'
			_id: string
			name: string
			cost?: number | null
			surfaceArea?: number | null
		}>
	}
}

export type CreateRoleMutationVariables = Exact<{
	input: RoleInput
}>

export type CreateRoleMutation = {
	__typename?: 'Mutation'
	role: {
		__typename?: 'Role'
		_id: string
		title?: string | null
		description?: string | null
		permissions: Array<{
			__typename?: 'Permission'
			effect: string
			action: string
			resource: string
		}>
	}
}

export type UpdateRoleMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateRoleInput
}>

export type UpdateRoleMutation = {
	__typename?: 'Mutation'
	role: {
		__typename?: 'Role'
		_id: string
		title?: string | null
		description?: string | null
		permissions: Array<{
			__typename?: 'Permission'
			effect: string
			action: string
			resource: string
		}>
	}
}

export type UnitFragmentFragment = {
	__typename?: 'Unit'
	_id: string
	number: string
	active: boolean
	type: string
	facility?: {
		__typename?: 'Facility'
		_id: string
		areas?: {
			__typename?: 'AreaConnection'
			_key: string
			nodes: Array<{
				__typename?: 'Area'
				_id: string
				name: string
				type: number
				isExterior: boolean
			}>
		} | null
	} | null
	area?: {
		__typename?: 'Area'
		_id: string
		name: string
		isExterior: boolean
	} | null
	services: { __typename?: 'ServiceConnection'; _key: string; count: number }
}

export type GetUnitQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetUnitQuery = {
	__typename?: 'Query'
	unit: {
		__typename?: 'Unit'
		_id: string
		number: string
		active: boolean
		type: string
		facility?: {
			__typename?: 'Facility'
			_id: string
			areas?: {
				__typename?: 'AreaConnection'
				_key: string
				nodes: Array<{
					__typename?: 'Area'
					_id: string
					name: string
					type: number
					isExterior: boolean
				}>
			} | null
		} | null
		area?: {
			__typename?: 'Area'
			_id: string
			name: string
			isExterior: boolean
		} | null
		services: { __typename?: 'ServiceConnection'; _key: string; count: number }
	}
}

export const FacilityUnitFragmentFragmentDoc = gql`
	fragment FacilityUnitFragment on Unit {
		_id
		number
		type
		active
		area {
			_id
			name
			isExterior
		}
	}
`
export const ClientDetailFragmentFragmentDoc = gql`
	fragment ClientDetailFragment on Client {
		_id
		name
		legalName
		taxId
		taxSystem
		active
		type
		role
		website
		address {
			street
			number
			city
			suburb
			municipality
			zipCode
			state
		}
		phones {
			label
			number
		}
	}
`
export const PermissionFragmentFragmentDoc = gql`
	fragment PermissionFragment on Permission {
		effect
		action
		resource
	}
`
export const RoleFragmentFragmentDoc = gql`
	fragment RoleFragment on Role {
		_id
		title
		description
		permissions {
			...PermissionFragment
		}
	}
	${PermissionFragmentFragmentDoc}
`
export const UserPermissionsFragmentFragmentDoc = gql`
	fragment UserPermissionsFragment on User {
		_id
		roles {
			...RoleFragment
		}
		permissions {
			...PermissionFragment
		}
	}
	${RoleFragmentFragmentDoc}
	${PermissionFragmentFragmentDoc}
`
export const UnitServiceFragmentFragmentDoc = gql`
	fragment UnitServiceFragment on UnitService {
		_id
		infestation
		date
		status {
			general
			specific
		}
		plagues {
			type
			count
		}
		employee {
			_id
			user {
				_id
			}
		}
		unit {
			_id
		}
	}
`
export const TagFragmentFragmentDoc = gql`
	fragment TagFragment on Tag {
		_id
		name
		description
		color
		isArchived
		collection
	}
`
export const ServiceDetailFragmentFragmentDoc = gql`
	fragment ServiceDetailFragment on Service {
		_id
		type
		date
		arrivedAt
		enRouteAt
		completedAt
		frequency
		url
		lot
		notes
		comments
		corrective
		signatureKey
		completionTime
		employees {
			_key
			nodes {
				_id
				name
				surname
				user {
					_id
				}
			}
		}
		application {
			dosis
			quantity
			method
			duration
			sites
		}
		areas {
			_key
			nodes {
				_id
				name
				active
			}
		}
		tags {
			_key
			nodes {
				...TagFragment
			}
		}
		products {
			_key
			nodes {
				_id
				name
			}
		}
	}
	${TagFragmentFragmentDoc}
`
export const FacilityServiceFragmentFragmentDoc = gql`
	fragment FacilityServiceFragment on Service {
		_id
		date
		type
		url
		signatureKey
		areas {
			_key
			nodes {
				_id
				name
			}
		}
		employees {
			_key
			nodes {
				_id
				name
				surname
				user {
					_id
					name
					surname
				}
			}
		}
	}
`
export const ClientContactFragmentFragmentDoc = gql`
	fragment ClientContactFragment on Contact {
		_id
		charge
		department
		phoneNumber
		mobileNumber
	}
`
export const ActivityFragmentFragmentDoc = gql`
	fragment ActivityFragment on Activity {
		_id
		description
		type
		createdAt
		metadata
		attachments {
			_id
			url
			thumbnail
			mimetype
		}
		createdBy {
			_id
			name
			surname
			photo {
				url
			}
		}
	}
`
export const ClientFragmentFragmentDoc = gql`
	fragment ClientFragment on Client {
		_id
		name
		taxId
		taxSystem
		active
		legalName
		type
		phones {
			label
			number
		}
		address {
			street
			number
			zipCode
			suburb
			municipality
			city
			state
			country
		}
		contacts {
			_key
			count
			nodes {
				_id
				name
				surname
				primaryContact
			}
		}
		tags {
			_key
			nodes {
				...TagFragment
			}
		}
	}
	${TagFragmentFragmentDoc}
`
export const ContactBaseFragmentFragmentDoc = gql`
	fragment ContactBaseFragment on Contact {
		_id
		name
		surname
		user {
			_id
			lastLogin
			hasActiveToken
			permissions {
				effect
				action
				resource
			}
		}
	}
`
export const ContactFragmentFragmentDoc = gql`
	fragment ContactFragment on Contact {
		...ContactBaseFragment
		prefix
		charge
		department
		email
		phoneNumber
		company {
			_id
			name
		}
		facilities {
			_key
			count
		}
		tags {
			_key
			nodes {
				...TagFragment
			}
		}
		user {
			_id
			lastLogin
			active
		}
		active
	}
	${ContactBaseFragmentFragmentDoc}
	${TagFragmentFragmentDoc}
`
export const EmployeeBaseFragmentFragmentDoc = gql`
	fragment EmployeeBaseFragment on Employee {
		_id
		name
		surname
		taxId
		taxSystem
		user {
			_id
		}
	}
`
export const EmployeeUserFragmentFragmentDoc = gql`
	fragment EmployeeUserFragment on User {
		_id
		active
		device
		email
		photo {
			url
		}
	}
`
export const EmployeeFragmentFragmentDoc = gql`
	fragment EmployeeFragment on Employee {
		...EmployeeBaseFragment
		charge
		phoneNumber
		imss
		email
		curp
		active
		user {
			...EmployeeUserFragment
		}
		services {
			_key
			count
		}
	}
	${EmployeeBaseFragmentFragmentDoc}
	${EmployeeUserFragmentFragmentDoc}
`
export const FacilityFragmentFragmentDoc = gql`
	fragment FacilityFragment on Facility {
		_id
		name
		emails
		phones {
			number
			label
		}
		active
		isTransport
		address {
			street
			number
			city
			suburb
			municipality
			zipCode
			state
		}
		company {
			_id
		}
		toleranceLimits {
			insects
			rodents
			birds
		}
		type
	}
`
export const FileFragmentFragmentDoc = gql`
	fragment FileFragment on File {
		_id
		name
		type
		url
		mimetype
		facility {
			_id
		}
		thumbnail
		lastUpdated
		acceptance
		deleted
	}
`
export const InvoiceFragmentFragmentDoc = gql`
	fragment InvoiceFragment on Invoice {
		_id
		date
		currency
		isDraft
		isArchived
		isSaleNote
		isCanceled
		subTotal
		taxAmount
		type
		use
		total
		comments
		series
		folio
		paymentForm
		paymentMethod
		paymentConditions
		providerResourceId
		employee {
			_id
		}
		number {
			company
			organization
		}
		company {
			_id
			name
			legalName
		}
		stamp {
			date
			seal
		}
		items {
			product {
				_id
			}
			price
			quantity
			currency
			discounts
			taxes
			description
		}
	}
`
export const MessageTemplateFragmentFragmentDoc = gql`
	fragment MessageTemplateFragment on MessageTemplate {
		_id
		type
		name
		subject
		body
		design
		relatedModel
		createdAt
		updatedAt
		createdBy {
			_id
			name
		}
	}
`
export const ProductFragmentFragmentDoc = gql`
	fragment ProductFragment on Product {
		_id
		active
		isArchived
		name
		producer
		type
		price
		notes
		hasTax
		subType
		currency
		description
		sanitaryRegistry
		activeIngredient
		shortDescription
		metadata {
			productId
			priceId
		}
		tax {
			key
			unitKey
		}
		applications {
			dosis
			method
			throwput
			plagues
		}
		tags {
			...TagFragment
		}
	}
	${TagFragmentFragmentDoc}
`
export const ServiceFragmentFragmentDoc = gql`
	fragment ServiceFragment on Service {
		_id
		date
		type
		frequency
		comments
		signatureKey
		facility {
			_id
			name
		}
		units {
			_key
			count
		}
		employees {
			_key
			count
			nodes {
				_id
				name
				surname
				user {
					_id
					photo {
						url
					}
				}
			}
		}
		areas {
			_key
			count
			nodes {
				_id
				name
			}
		}
		products {
			_key
			count
			nodes {
				_id
				name
			}
		}
		client {
			_id
			name
		}
		notes
		url
		createdBy {
			_id
			name
			surname
		}
		transport {
			name
			number
		}
		application {
			method
			duration
			dosis
		}
		payment {
			status
			cost
			tax
		}
		tags {
			_key
			nodes {
				...TagFragment
			}
		}
	}
	${TagFragmentFragmentDoc}
`
export const OrganizationFragmentFragmentDoc = gql`
	fragment OrganizationFragment on Organization {
		_id
		apps
		name
		legalName
		taxId
		taxSystem
		information {
			website
		}
		logo {
			url
		}
		logoLight {
			url
		}
		preferences {
			language
			timeZone
			billingEmail
		}
		subscription {
			status
		}
		features
		address {
			street
			number
			city
			suburb
			municipality
			zipCode
			state
			country
		}
	}
`
export const UserFragmentFragmentDoc = gql`
	fragment UserFragment on User {
		_id
		name
		surname
		email
		phoneNumber
		company {
			_id
			name
		}
		photo {
			url
		}
		preferences {
			language
			timeZone
			privacy {
				tracking {
					performance
					product
					crash
				}
			}
		}
		organization {
			...OrganizationFragment
		}
		facilities {
			_key
			nodes {
				_id
				name
			}
		}
		isEmailBouncing
	}
	${OrganizationFragmentFragmentDoc}
`
export const UnitFragmentFragmentDoc = gql`
	fragment UnitFragment on Unit {
		_id
		number
		active
		type
		facility {
			_id
			areas {
				_key
				nodes {
					_id
					name
					type
					isExterior
				}
			}
		}
		area {
			_id
			name
			isExterior
		}
		services {
			_key
			count
		}
	}
`
export const UpdateAreasDocument = gql`
	mutation UpdateAreas($input: UpdateAreasInput!) {
		updateAreas(input: $input) {
			_id
			name
			type
			isExterior
			active
			units {
				_key
				count
			}
		}
	}
`
export type UpdateAreasMutationFn = Apollo.MutationFunction<
	UpdateAreasMutation,
	UpdateAreasMutationVariables
>

/**
 * __useUpdateAreasMutation__
 *
 * To run a mutation, you first call `useUpdateAreasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreasMutation, { data, loading, error }] = useUpdateAreasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAreasMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateAreasMutation,
		UpdateAreasMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateAreasMutation, UpdateAreasMutationVariables>(
		UpdateAreasDocument,
		options,
	)
}
export type UpdateAreasMutationHookResult = ReturnType<
	typeof useUpdateAreasMutation
>
export type UpdateAreasMutationResult =
	Apollo.MutationResult<UpdateAreasMutation>
export type UpdateAreasMutationOptions = Apollo.BaseMutationOptions<
	UpdateAreasMutation,
	UpdateAreasMutationVariables
>
export const UnitsAndSelfDocument = gql`
	query unitsAndSelf($filter: UnitsFilter!, $id: ID!) {
		self @client {
			_id
			organization {
				_id
				logo {
					url
				}
			}
		}
		facility(id: $id) {
			_id
			isTransport
		}
		units(filter: $filter) {
			_key
			nodes {
				...FacilityUnitFragment
			}
		}
	}
	${FacilityUnitFragmentFragmentDoc}
`

/**
 * __useUnitsAndSelfQuery__
 *
 * To run a query within a React component, call `useUnitsAndSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsAndSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsAndSelfQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnitsAndSelfQuery(
	baseOptions: Apollo.QueryHookOptions<
		UnitsAndSelfQuery,
		UnitsAndSelfQueryVariables
	> &
		(
			| { variables: UnitsAndSelfQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<UnitsAndSelfQuery, UnitsAndSelfQueryVariables>(
		UnitsAndSelfDocument,
		options,
	)
}
export function useUnitsAndSelfLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UnitsAndSelfQuery,
		UnitsAndSelfQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<UnitsAndSelfQuery, UnitsAndSelfQueryVariables>(
		UnitsAndSelfDocument,
		options,
	)
}
export function useUnitsAndSelfSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				UnitsAndSelfQuery,
				UnitsAndSelfQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<UnitsAndSelfQuery, UnitsAndSelfQueryVariables>(
		UnitsAndSelfDocument,
		options,
	)
}
export type UnitsAndSelfQueryHookResult = ReturnType<
	typeof useUnitsAndSelfQuery
>
export type UnitsAndSelfLazyQueryHookResult = ReturnType<
	typeof useUnitsAndSelfLazyQuery
>
export type UnitsAndSelfSuspenseQueryHookResult = ReturnType<
	typeof useUnitsAndSelfSuspenseQuery
>
export type UnitsAndSelfQueryResult = Apollo.QueryResult<
	UnitsAndSelfQuery,
	UnitsAndSelfQueryVariables
>
export const GetClientAndTagsDocument = gql`
	query GetClientAndTags(
		$id: ID!
		$tagsFilter: TagsFilter
		$servicesFilter: ServicesFilter
	) {
		tags(filter: $tagsFilter) {
			_key
			nodes {
				...TagFragment
			}
		}
		client(id: $id) {
			...ClientDetailFragment
			metadata
			services(filter: $servicesFilter, limit: 20) {
				_key
				count
			}
			contacts {
				_key
				count
			}
			tags {
				_key
				nodes {
					...TagFragment
				}
			}
		}
	}
	${TagFragmentFragmentDoc}
	${ClientDetailFragmentFragmentDoc}
`

/**
 * __useGetClientAndTagsQuery__
 *
 * To run a query within a React component, call `useGetClientAndTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAndTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAndTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tagsFilter: // value for 'tagsFilter'
 *      servicesFilter: // value for 'servicesFilter'
 *   },
 * });
 */
export function useGetClientAndTagsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetClientAndTagsQuery,
		GetClientAndTagsQueryVariables
	> &
		(
			| { variables: GetClientAndTagsQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetClientAndTagsQuery, GetClientAndTagsQueryVariables>(
		GetClientAndTagsDocument,
		options,
	)
}
export function useGetClientAndTagsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetClientAndTagsQuery,
		GetClientAndTagsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetClientAndTagsQuery,
		GetClientAndTagsQueryVariables
	>(GetClientAndTagsDocument, options)
}
export function useGetClientAndTagsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetClientAndTagsQuery,
				GetClientAndTagsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetClientAndTagsQuery,
		GetClientAndTagsQueryVariables
	>(GetClientAndTagsDocument, options)
}
export type GetClientAndTagsQueryHookResult = ReturnType<
	typeof useGetClientAndTagsQuery
>
export type GetClientAndTagsLazyQueryHookResult = ReturnType<
	typeof useGetClientAndTagsLazyQuery
>
export type GetClientAndTagsSuspenseQueryHookResult = ReturnType<
	typeof useGetClientAndTagsSuspenseQuery
>
export type GetClientAndTagsQueryResult = Apollo.QueryResult<
	GetClientAndTagsQuery,
	GetClientAndTagsQueryVariables
>
export const UpdateClientDocument = gql`
	mutation UpdateClient($id: ID!, $input: UpdateClientInput!) {
		updateClient(id: $id, input: $input) {
			...ClientDetailFragment
		}
	}
	${ClientDetailFragmentFragmentDoc}
`
export type UpdateClientMutationFn = Apollo.MutationFunction<
	UpdateClientMutation,
	UpdateClientMutationVariables
>

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateClientMutation,
		UpdateClientMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateClientMutation,
		UpdateClientMutationVariables
	>(UpdateClientDocument, options)
}
export type UpdateClientMutationHookResult = ReturnType<
	typeof useUpdateClientMutation
>
export type UpdateClientMutationResult =
	Apollo.MutationResult<UpdateClientMutation>
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
	UpdateClientMutation,
	UpdateClientMutationVariables
>
export const GetEmployeePermissionsDocument = gql`
	query getEmployeePermissions($id: ID!) {
		employee(id: $id) {
			_id
			user {
				...UserPermissionsFragment
				lastLogin
			}
		}
	}
	${UserPermissionsFragmentFragmentDoc}
`

/**
 * __useGetEmployeePermissionsQuery__
 *
 * To run a query within a React component, call `useGetEmployeePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeePermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeePermissionsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetEmployeePermissionsQuery,
		GetEmployeePermissionsQueryVariables
	> &
		(
			| { variables: GetEmployeePermissionsQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetEmployeePermissionsQuery,
		GetEmployeePermissionsQueryVariables
	>(GetEmployeePermissionsDocument, options)
}
export function useGetEmployeePermissionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetEmployeePermissionsQuery,
		GetEmployeePermissionsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetEmployeePermissionsQuery,
		GetEmployeePermissionsQueryVariables
	>(GetEmployeePermissionsDocument, options)
}
export function useGetEmployeePermissionsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetEmployeePermissionsQuery,
				GetEmployeePermissionsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetEmployeePermissionsQuery,
		GetEmployeePermissionsQueryVariables
	>(GetEmployeePermissionsDocument, options)
}
export type GetEmployeePermissionsQueryHookResult = ReturnType<
	typeof useGetEmployeePermissionsQuery
>
export type GetEmployeePermissionsLazyQueryHookResult = ReturnType<
	typeof useGetEmployeePermissionsLazyQuery
>
export type GetEmployeePermissionsSuspenseQueryHookResult = ReturnType<
	typeof useGetEmployeePermissionsSuspenseQuery
>
export type GetEmployeePermissionsQueryResult = Apollo.QueryResult<
	GetEmployeePermissionsQuery,
	GetEmployeePermissionsQueryVariables
>
export const GetOrganizationQuoteConfigDocument = gql`
	query GetOrganizationQuoteConfig {
		self {
			_id
			organization {
				_id
				discounts {
					_id
				}
				taxes {
					_id
					name
					rate
					active
					region
					factor
					isArchived
					description
					isWithholding
				}
			}
		}
	}
`

/**
 * __useGetOrganizationQuoteConfigQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuoteConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuoteConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuoteConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuoteConfigQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationQuoteConfigQuery,
		GetOrganizationQuoteConfigQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetOrganizationQuoteConfigQuery,
		GetOrganizationQuoteConfigQueryVariables
	>(GetOrganizationQuoteConfigDocument, options)
}
export function useGetOrganizationQuoteConfigLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationQuoteConfigQuery,
		GetOrganizationQuoteConfigQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetOrganizationQuoteConfigQuery,
		GetOrganizationQuoteConfigQueryVariables
	>(GetOrganizationQuoteConfigDocument, options)
}
export function useGetOrganizationQuoteConfigSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetOrganizationQuoteConfigQuery,
				GetOrganizationQuoteConfigQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetOrganizationQuoteConfigQuery,
		GetOrganizationQuoteConfigQueryVariables
	>(GetOrganizationQuoteConfigDocument, options)
}
export type GetOrganizationQuoteConfigQueryHookResult = ReturnType<
	typeof useGetOrganizationQuoteConfigQuery
>
export type GetOrganizationQuoteConfigLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationQuoteConfigLazyQuery
>
export type GetOrganizationQuoteConfigSuspenseQueryHookResult = ReturnType<
	typeof useGetOrganizationQuoteConfigSuspenseQuery
>
export type GetOrganizationQuoteConfigQueryResult = Apollo.QueryResult<
	GetOrganizationQuoteConfigQuery,
	GetOrganizationQuoteConfigQueryVariables
>
export const GetServiceAndEmployeesDocument = gql`
	query getServiceAndEmployees(
		$id: ID!
		$tagsFilter: TagsFilter
		$employeesFilter: EmployeesFilter
		$hasAnalytics: Boolean!
	) {
		employees(filter: $employeesFilter) {
			_key
			nodes {
				_id
				name
				surname
				user {
					_id
					photo {
						url
					}
				}
			}
		}
		tags(filter: $tagsFilter) {
			_key
			nodes {
				...TagFragment
			}
		}
		products {
			_key
			nodes {
				_id
				name
				active
				applications {
					quantity
					method
					dosis
					duration
				}
			}
		}
		service(id: $id) {
			...ServiceDetailFragment
			estimatedCompletionTime @include(if: $hasAnalytics)
			attachments {
				...FileFragment
			}
			client {
				_id
				name
			}
			units {
				_key
				nodes {
					_id
					number
					type
				}
			}
			facility {
				_id
				name
				areas {
					_key
					nodes {
						_id
						name
						type
					}
				}
			}
			createdBy {
				_id
				name
				surname
			}
			createdAt
		}
	}
	${TagFragmentFragmentDoc}
	${ServiceDetailFragmentFragmentDoc}
	${FileFragmentFragmentDoc}
`

/**
 * __useGetServiceAndEmployeesQuery__
 *
 * To run a query within a React component, call `useGetServiceAndEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceAndEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceAndEmployeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tagsFilter: // value for 'tagsFilter'
 *      employeesFilter: // value for 'employeesFilter'
 *      hasAnalytics: // value for 'hasAnalytics'
 *   },
 * });
 */
export function useGetServiceAndEmployeesQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetServiceAndEmployeesQuery,
		GetServiceAndEmployeesQueryVariables
	> &
		(
			| { variables: GetServiceAndEmployeesQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetServiceAndEmployeesQuery,
		GetServiceAndEmployeesQueryVariables
	>(GetServiceAndEmployeesDocument, options)
}
export function useGetServiceAndEmployeesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetServiceAndEmployeesQuery,
		GetServiceAndEmployeesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetServiceAndEmployeesQuery,
		GetServiceAndEmployeesQueryVariables
	>(GetServiceAndEmployeesDocument, options)
}
export function useGetServiceAndEmployeesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetServiceAndEmployeesQuery,
				GetServiceAndEmployeesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetServiceAndEmployeesQuery,
		GetServiceAndEmployeesQueryVariables
	>(GetServiceAndEmployeesDocument, options)
}
export type GetServiceAndEmployeesQueryHookResult = ReturnType<
	typeof useGetServiceAndEmployeesQuery
>
export type GetServiceAndEmployeesLazyQueryHookResult = ReturnType<
	typeof useGetServiceAndEmployeesLazyQuery
>
export type GetServiceAndEmployeesSuspenseQueryHookResult = ReturnType<
	typeof useGetServiceAndEmployeesSuspenseQuery
>
export type GetServiceAndEmployeesQueryResult = Apollo.QueryResult<
	GetServiceAndEmployeesQuery,
	GetServiceAndEmployeesQueryVariables
>
export const GetUnitChecksDocument = gql`
	query GetUnitChecks($filter: UnitServicesFilter) {
		unitServices(filter: $filter) {
			_key
			nodes {
				...UnitServiceFragment
			}
		}
	}
	${UnitServiceFragmentFragmentDoc}
`

/**
 * __useGetUnitChecksQuery__
 *
 * To run a query within a React component, call `useGetUnitChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitChecksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUnitChecksQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetUnitChecksQuery,
		GetUnitChecksQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetUnitChecksQuery, GetUnitChecksQueryVariables>(
		GetUnitChecksDocument,
		options,
	)
}
export function useGetUnitChecksLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetUnitChecksQuery,
		GetUnitChecksQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetUnitChecksQuery, GetUnitChecksQueryVariables>(
		GetUnitChecksDocument,
		options,
	)
}
export function useGetUnitChecksSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetUnitChecksQuery,
				GetUnitChecksQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetUnitChecksQuery,
		GetUnitChecksQueryVariables
	>(GetUnitChecksDocument, options)
}
export type GetUnitChecksQueryHookResult = ReturnType<
	typeof useGetUnitChecksQuery
>
export type GetUnitChecksLazyQueryHookResult = ReturnType<
	typeof useGetUnitChecksLazyQuery
>
export type GetUnitChecksSuspenseQueryHookResult = ReturnType<
	typeof useGetUnitChecksSuspenseQuery
>
export type GetUnitChecksQueryResult = Apollo.QueryResult<
	GetUnitChecksQuery,
	GetUnitChecksQueryVariables
>
export const UpdateServicesDocument = gql`
	mutation UpdateServices($ids: [ID!]!, $input: UpdateServicesInput!) {
		updateServices(ids: $ids, input: $input) {
			...ServiceFragment
		}
	}
	${ServiceFragmentFragmentDoc}
`
export type UpdateServicesMutationFn = Apollo.MutationFunction<
	UpdateServicesMutation,
	UpdateServicesMutationVariables
>

/**
 * __useUpdateServicesMutation__
 *
 * To run a mutation, you first call `useUpdateServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServicesMutation, { data, loading, error }] = useUpdateServicesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServicesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateServicesMutation,
		UpdateServicesMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateServicesMutation,
		UpdateServicesMutationVariables
	>(UpdateServicesDocument, options)
}
export type UpdateServicesMutationHookResult = ReturnType<
	typeof useUpdateServicesMutation
>
export type UpdateServicesMutationResult =
	Apollo.MutationResult<UpdateServicesMutation>
export type UpdateServicesMutationOptions = Apollo.BaseMutationOptions<
	UpdateServicesMutation,
	UpdateServicesMutationVariables
>
export const GetEmployeesForFilterDocument = gql`
	query getEmployeesForFilter {
		employees {
			_key
			nodes {
				_id
				name
				surname
			}
		}
	}
`

/**
 * __useGetEmployeesForFilterQuery__
 *
 * To run a query within a React component, call `useGetEmployeesForFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesForFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesForFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeesForFilterQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetEmployeesForFilterQuery,
		GetEmployeesForFilterQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetEmployeesForFilterQuery,
		GetEmployeesForFilterQueryVariables
	>(GetEmployeesForFilterDocument, options)
}
export function useGetEmployeesForFilterLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetEmployeesForFilterQuery,
		GetEmployeesForFilterQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetEmployeesForFilterQuery,
		GetEmployeesForFilterQueryVariables
	>(GetEmployeesForFilterDocument, options)
}
export function useGetEmployeesForFilterSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetEmployeesForFilterQuery,
				GetEmployeesForFilterQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetEmployeesForFilterQuery,
		GetEmployeesForFilterQueryVariables
	>(GetEmployeesForFilterDocument, options)
}
export type GetEmployeesForFilterQueryHookResult = ReturnType<
	typeof useGetEmployeesForFilterQuery
>
export type GetEmployeesForFilterLazyQueryHookResult = ReturnType<
	typeof useGetEmployeesForFilterLazyQuery
>
export type GetEmployeesForFilterSuspenseQueryHookResult = ReturnType<
	typeof useGetEmployeesForFilterSuspenseQuery
>
export type GetEmployeesForFilterQueryResult = Apollo.QueryResult<
	GetEmployeesForFilterQuery,
	GetEmployeesForFilterQueryVariables
>
export const GetOrganizationDocument = gql`
	query getOrganization {
		self {
			_id
			email
			organization {
				_id
				taxId
				information {
					website
				}
				taxes {
					_id
					name
					region
					rate
					type
					description
					active
					factor
					isWithholding
				}
				preferences {
					billingEmail
					documentMemos {
						QT
					}
				}
				integrations {
					_id
					provider
					status
					type
					name
					createdAt
				}
			}
		}
	}
`

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationQuery,
		GetOrganizationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
		GetOrganizationDocument,
		options,
	)
}
export function useGetOrganizationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationQuery,
		GetOrganizationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetOrganizationQuery,
		GetOrganizationQueryVariables
	>(GetOrganizationDocument, options)
}
export function useGetOrganizationSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetOrganizationQuery,
				GetOrganizationQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetOrganizationQuery,
		GetOrganizationQueryVariables
	>(GetOrganizationDocument, options)
}
export type GetOrganizationQueryHookResult = ReturnType<
	typeof useGetOrganizationQuery
>
export type GetOrganizationLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationLazyQuery
>
export type GetOrganizationSuspenseQueryHookResult = ReturnType<
	typeof useGetOrganizationSuspenseQuery
>
export type GetOrganizationQueryResult = Apollo.QueryResult<
	GetOrganizationQuery,
	GetOrganizationQueryVariables
>
export const RequestIntegrationTokenDocument = gql`
	mutation RequestIntegrationToken($input: IntegrationTokenRequestInput!) {
		requestIntegrationToken(input: $input)
	}
`
export type RequestIntegrationTokenMutationFn = Apollo.MutationFunction<
	RequestIntegrationTokenMutation,
	RequestIntegrationTokenMutationVariables
>

/**
 * __useRequestIntegrationTokenMutation__
 *
 * To run a mutation, you first call `useRequestIntegrationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestIntegrationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestIntegrationTokenMutation, { data, loading, error }] = useRequestIntegrationTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestIntegrationTokenMutation(
	baseOptions?: Apollo.MutationHookOptions<
		RequestIntegrationTokenMutation,
		RequestIntegrationTokenMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		RequestIntegrationTokenMutation,
		RequestIntegrationTokenMutationVariables
	>(RequestIntegrationTokenDocument, options)
}
export type RequestIntegrationTokenMutationHookResult = ReturnType<
	typeof useRequestIntegrationTokenMutation
>
export type RequestIntegrationTokenMutationResult =
	Apollo.MutationResult<RequestIntegrationTokenMutation>
export type RequestIntegrationTokenMutationOptions = Apollo.BaseMutationOptions<
	RequestIntegrationTokenMutation,
	RequestIntegrationTokenMutationVariables
>
export const GetRolesDocument = gql`
	query getRoles {
		roles {
			_key
			nodes {
				...RoleFragment
			}
		}
	}
	${RoleFragmentFragmentDoc}
`

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
	baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(
		GetRolesDocument,
		options,
	)
}
export function useGetRolesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetRolesQuery,
		GetRolesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(
		GetRolesDocument,
		options,
	)
}
export function useGetRolesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(
		GetRolesDocument,
		options,
	)
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>
export type GetRolesLazyQueryHookResult = ReturnType<
	typeof useGetRolesLazyQuery
>
export type GetRolesSuspenseQueryHookResult = ReturnType<
	typeof useGetRolesSuspenseQuery
>
export type GetRolesQueryResult = Apollo.QueryResult<
	GetRolesQuery,
	GetRolesQueryVariables
>
export const GetOrganizationTagsDocument = gql`
	query getOrganizationTags {
		tags {
			_key
			nodes {
				...TagFragment
			}
		}
	}
	${TagFragmentFragmentDoc}
`

/**
 * __useGetOrganizationTagsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationTagsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationTagsQuery,
		GetOrganizationTagsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetOrganizationTagsQuery,
		GetOrganizationTagsQueryVariables
	>(GetOrganizationTagsDocument, options)
}
export function useGetOrganizationTagsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationTagsQuery,
		GetOrganizationTagsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetOrganizationTagsQuery,
		GetOrganizationTagsQueryVariables
	>(GetOrganizationTagsDocument, options)
}
export function useGetOrganizationTagsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetOrganizationTagsQuery,
				GetOrganizationTagsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetOrganizationTagsQuery,
		GetOrganizationTagsQueryVariables
	>(GetOrganizationTagsDocument, options)
}
export type GetOrganizationTagsQueryHookResult = ReturnType<
	typeof useGetOrganizationTagsQuery
>
export type GetOrganizationTagsLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationTagsLazyQuery
>
export type GetOrganizationTagsSuspenseQueryHookResult = ReturnType<
	typeof useGetOrganizationTagsSuspenseQuery
>
export type GetOrganizationTagsQueryResult = Apollo.QueryResult<
	GetOrganizationTagsQuery,
	GetOrganizationTagsQueryVariables
>
export const GetClientTransportsDocument = gql`
	query getClientTransports(
		$filter: CompaniesFilter
		$sFilter: ServicesFilter
	) {
		clients(filter: $filter) {
			_key
			nodes {
				_id
				name
				transports {
					_key
					nodes {
						_id
						unit {
							_id
						}
						services(filter: $sFilter) {
							_key
							nodes {
								date
								_id
								payment {
									status
								}
							}
						}
					}
				}
			}
		}
	}
`

/**
 * __useGetClientTransportsQuery__
 *
 * To run a query within a React component, call `useGetClientTransportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientTransportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientTransportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sFilter: // value for 'sFilter'
 *   },
 * });
 */
export function useGetClientTransportsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetClientTransportsQuery,
		GetClientTransportsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetClientTransportsQuery,
		GetClientTransportsQueryVariables
	>(GetClientTransportsDocument, options)
}
export function useGetClientTransportsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetClientTransportsQuery,
		GetClientTransportsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetClientTransportsQuery,
		GetClientTransportsQueryVariables
	>(GetClientTransportsDocument, options)
}
export function useGetClientTransportsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetClientTransportsQuery,
				GetClientTransportsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetClientTransportsQuery,
		GetClientTransportsQueryVariables
	>(GetClientTransportsDocument, options)
}
export type GetClientTransportsQueryHookResult = ReturnType<
	typeof useGetClientTransportsQuery
>
export type GetClientTransportsLazyQueryHookResult = ReturnType<
	typeof useGetClientTransportsLazyQuery
>
export type GetClientTransportsSuspenseQueryHookResult = ReturnType<
	typeof useGetClientTransportsSuspenseQuery
>
export type GetClientTransportsQueryResult = Apollo.QueryResult<
	GetClientTransportsQuery,
	GetClientTransportsQueryVariables
>
export const GetTransportServicesDocument = gql`
	query getTransportServices($id: ID!) {
		unit(id: $id) {
			_id
			services {
				_key
				nodes {
					...ServiceFragment
				}
			}
		}
	}
	${ServiceFragmentFragmentDoc}
`

/**
 * __useGetTransportServicesQuery__
 *
 * To run a query within a React component, call `useGetTransportServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportServicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransportServicesQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetTransportServicesQuery,
		GetTransportServicesQueryVariables
	> &
		(
			| { variables: GetTransportServicesQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetTransportServicesQuery,
		GetTransportServicesQueryVariables
	>(GetTransportServicesDocument, options)
}
export function useGetTransportServicesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetTransportServicesQuery,
		GetTransportServicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetTransportServicesQuery,
		GetTransportServicesQueryVariables
	>(GetTransportServicesDocument, options)
}
export function useGetTransportServicesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetTransportServicesQuery,
				GetTransportServicesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetTransportServicesQuery,
		GetTransportServicesQueryVariables
	>(GetTransportServicesDocument, options)
}
export type GetTransportServicesQueryHookResult = ReturnType<
	typeof useGetTransportServicesQuery
>
export type GetTransportServicesLazyQueryHookResult = ReturnType<
	typeof useGetTransportServicesLazyQuery
>
export type GetTransportServicesSuspenseQueryHookResult = ReturnType<
	typeof useGetTransportServicesSuspenseQuery
>
export type GetTransportServicesQueryResult = Apollo.QueryResult<
	GetTransportServicesQuery,
	GetTransportServicesQueryVariables
>
export const UpdateServiceSignatureDocument = gql`
	mutation UpdateServiceSignature($id: ID!, $input: UpdateServiceInput!) {
		updateService(id: $id, input: $input) {
			...FacilityServiceFragment
		}
	}
	${FacilityServiceFragmentFragmentDoc}
`
export type UpdateServiceSignatureMutationFn = Apollo.MutationFunction<
	UpdateServiceSignatureMutation,
	UpdateServiceSignatureMutationVariables
>

/**
 * __useUpdateServiceSignatureMutation__
 *
 * To run a mutation, you first call `useUpdateServiceSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceSignatureMutation, { data, loading, error }] = useUpdateServiceSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceSignatureMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateServiceSignatureMutation,
		UpdateServiceSignatureMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateServiceSignatureMutation,
		UpdateServiceSignatureMutationVariables
	>(UpdateServiceSignatureDocument, options)
}
export type UpdateServiceSignatureMutationHookResult = ReturnType<
	typeof useUpdateServiceSignatureMutation
>
export type UpdateServiceSignatureMutationResult =
	Apollo.MutationResult<UpdateServiceSignatureMutation>
export type UpdateServiceSignatureMutationOptions = Apollo.BaseMutationOptions<
	UpdateServiceSignatureMutation,
	UpdateServiceSignatureMutationVariables
>
export const GetFacilityAreaAndUnitsDocument = gql`
	query getFacilityAreaAndUnits($facility: ID!) {
		facility(id: $facility) {
			_id
			toleranceLimits {
				insects
				rodents
			}
			areas {
				_key
				nodes {
					_id
					name
					active
				}
			}
			units {
				_key
				nodes {
					_id
					number
					area {
						_id
						name
						isExterior
					}
					status {
						general
						specific
					}
				}
			}
		}
	}
`

/**
 * __useGetFacilityAreaAndUnitsQuery__
 *
 * To run a query within a React component, call `useGetFacilityAreaAndUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityAreaAndUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityAreaAndUnitsQuery({
 *   variables: {
 *      facility: // value for 'facility'
 *   },
 * });
 */
export function useGetFacilityAreaAndUnitsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetFacilityAreaAndUnitsQuery,
		GetFacilityAreaAndUnitsQueryVariables
	> &
		(
			| { variables: GetFacilityAreaAndUnitsQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetFacilityAreaAndUnitsQuery,
		GetFacilityAreaAndUnitsQueryVariables
	>(GetFacilityAreaAndUnitsDocument, options)
}
export function useGetFacilityAreaAndUnitsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFacilityAreaAndUnitsQuery,
		GetFacilityAreaAndUnitsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetFacilityAreaAndUnitsQuery,
		GetFacilityAreaAndUnitsQueryVariables
	>(GetFacilityAreaAndUnitsDocument, options)
}
export function useGetFacilityAreaAndUnitsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetFacilityAreaAndUnitsQuery,
				GetFacilityAreaAndUnitsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetFacilityAreaAndUnitsQuery,
		GetFacilityAreaAndUnitsQueryVariables
	>(GetFacilityAreaAndUnitsDocument, options)
}
export type GetFacilityAreaAndUnitsQueryHookResult = ReturnType<
	typeof useGetFacilityAreaAndUnitsQuery
>
export type GetFacilityAreaAndUnitsLazyQueryHookResult = ReturnType<
	typeof useGetFacilityAreaAndUnitsLazyQuery
>
export type GetFacilityAreaAndUnitsSuspenseQueryHookResult = ReturnType<
	typeof useGetFacilityAreaAndUnitsSuspenseQuery
>
export type GetFacilityAreaAndUnitsQueryResult = Apollo.QueryResult<
	GetFacilityAreaAndUnitsQuery,
	GetFacilityAreaAndUnitsQueryVariables
>
export const GetFacilityServicesDocument = gql`
	query GetFacilityServices(
		$filter: ServicesFilter
		$sort: ServicesSort
		$limit: Int
	) {
		services(filter: $filter, sort: $sort, limit: $limit) {
			_key
			nodes {
				...FacilityServiceFragment
			}
		}
	}
	${FacilityServiceFragmentFragmentDoc}
`

/**
 * __useGetFacilityServicesQuery__
 *
 * To run a query within a React component, call `useGetFacilityServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityServicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetFacilityServicesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetFacilityServicesQuery,
		GetFacilityServicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetFacilityServicesQuery,
		GetFacilityServicesQueryVariables
	>(GetFacilityServicesDocument, options)
}
export function useGetFacilityServicesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFacilityServicesQuery,
		GetFacilityServicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetFacilityServicesQuery,
		GetFacilityServicesQueryVariables
	>(GetFacilityServicesDocument, options)
}
export function useGetFacilityServicesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetFacilityServicesQuery,
				GetFacilityServicesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetFacilityServicesQuery,
		GetFacilityServicesQueryVariables
	>(GetFacilityServicesDocument, options)
}
export type GetFacilityServicesQueryHookResult = ReturnType<
	typeof useGetFacilityServicesQuery
>
export type GetFacilityServicesLazyQueryHookResult = ReturnType<
	typeof useGetFacilityServicesLazyQuery
>
export type GetFacilityServicesSuspenseQueryHookResult = ReturnType<
	typeof useGetFacilityServicesSuspenseQuery
>
export type GetFacilityServicesQueryResult = Apollo.QueryResult<
	GetFacilityServicesQuery,
	GetFacilityServicesQueryVariables
>
export const GetOrganizationSignupDocument = gql`
	query getOrganizationSignup($id: ID!) {
		organization(id: $id) {
			_id
			name
			logo {
				url
			}
			logoLight {
				url
			}
		}
	}
`

/**
 * __useGetOrganizationSignupQuery__
 *
 * To run a query within a React component, call `useGetOrganizationSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationSignupQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetOrganizationSignupQuery,
		GetOrganizationSignupQueryVariables
	> &
		(
			| { variables: GetOrganizationSignupQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetOrganizationSignupQuery,
		GetOrganizationSignupQueryVariables
	>(GetOrganizationSignupDocument, options)
}
export function useGetOrganizationSignupLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationSignupQuery,
		GetOrganizationSignupQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetOrganizationSignupQuery,
		GetOrganizationSignupQueryVariables
	>(GetOrganizationSignupDocument, options)
}
export function useGetOrganizationSignupSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetOrganizationSignupQuery,
				GetOrganizationSignupQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetOrganizationSignupQuery,
		GetOrganizationSignupQueryVariables
	>(GetOrganizationSignupDocument, options)
}
export type GetOrganizationSignupQueryHookResult = ReturnType<
	typeof useGetOrganizationSignupQuery
>
export type GetOrganizationSignupLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationSignupLazyQuery
>
export type GetOrganizationSignupSuspenseQueryHookResult = ReturnType<
	typeof useGetOrganizationSignupSuspenseQuery
>
export type GetOrganizationSignupQueryResult = Apollo.QueryResult<
	GetOrganizationSignupQuery,
	GetOrganizationSignupQueryVariables
>
export const SignUpDocument = gql`
	mutation signUp($input: SignUpInput!) {
		credentials: signUp(input: $input) {
			token
			user {
				...UserFragment
			}
		}
	}
	${UserFragmentFragmentDoc}
`
export type SignUpMutationFn = Apollo.MutationFunction<
	SignUpMutation,
	SignUpMutationVariables
>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SignUpMutation,
		SignUpMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
		SignUpDocument,
		options,
	)
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
	SignUpMutation,
	SignUpMutationVariables
>
export const CreatePresignedUploadUrlDocument = gql`
	mutation CreatePresignedUploadUrl($input: PresignedUploadUrlInput!) {
		presignedUploadUrl(input: $input) {
			key
			url
		}
	}
`
export type CreatePresignedUploadUrlMutationFn = Apollo.MutationFunction<
	CreatePresignedUploadUrlMutation,
	CreatePresignedUploadUrlMutationVariables
>

/**
 * __useCreatePresignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreatePresignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedUploadUrlMutation, { data, loading, error }] = useCreatePresignedUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresignedUploadUrlMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreatePresignedUploadUrlMutation,
		CreatePresignedUploadUrlMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreatePresignedUploadUrlMutation,
		CreatePresignedUploadUrlMutationVariables
	>(CreatePresignedUploadUrlDocument, options)
}
export type CreatePresignedUploadUrlMutationHookResult = ReturnType<
	typeof useCreatePresignedUploadUrlMutation
>
export type CreatePresignedUploadUrlMutationResult =
	Apollo.MutationResult<CreatePresignedUploadUrlMutation>
export type CreatePresignedUploadUrlMutationOptions =
	Apollo.BaseMutationOptions<
		CreatePresignedUploadUrlMutation,
		CreatePresignedUploadUrlMutationVariables
	>
export const UploadFileDocument = gql`
	mutation uploadFile($input: UploadFileInput!) @deprecated {
		file: uploadFile(input: $input) {
			...FileFragment
		}
	}
	${FileFragmentFragmentDoc}
`
export type UploadFileMutationFn = Apollo.MutationFunction<
	UploadFileMutation,
	UploadFileMutationVariables
>

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UploadFileMutation,
		UploadFileMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
		UploadFileDocument,
		options,
	)
}
export type UploadFileMutationHookResult = ReturnType<
	typeof useUploadFileMutation
>
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
	UploadFileMutation,
	UploadFileMutationVariables
>
export const CancelInvoiceDocument = gql`
	mutation CancelInvoice($id: ID!, $input: CancelInvoiceInput!) {
		cancelInvoice(id: $id, input: $input) {
			_id
			date
			isCanceled
			isDraft
			isSaleNote
			type
			total
			currency
			subTotal
			paymentForm
			paymentMethod
			transactions {
				paymentLink
			}
			company {
				_id
				name
				legalName
			}
			stamp {
				date
				seal
			}
			file {
				url
			}
		}
	}
`
export type CancelInvoiceMutationFn = Apollo.MutationFunction<
	CancelInvoiceMutation,
	CancelInvoiceMutationVariables
>

/**
 * __useCancelInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvoiceMutation, { data, loading, error }] = useCancelInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelInvoiceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CancelInvoiceMutation,
		CancelInvoiceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CancelInvoiceMutation,
		CancelInvoiceMutationVariables
	>(CancelInvoiceDocument, options)
}
export type CancelInvoiceMutationHookResult = ReturnType<
	typeof useCancelInvoiceMutation
>
export type CancelInvoiceMutationResult =
	Apollo.MutationResult<CancelInvoiceMutation>
export type CancelInvoiceMutationOptions = Apollo.BaseMutationOptions<
	CancelInvoiceMutation,
	CancelInvoiceMutationVariables
>
export const CreateActivityDocument = gql`
	mutation CreateActivity($input: ActivityInput!) {
		activity(input: $input) {
			...ActivityFragment
		}
	}
	${ActivityFragmentFragmentDoc}
`
export type CreateActivityMutationFn = Apollo.MutationFunction<
	CreateActivityMutation,
	CreateActivityMutationVariables
>

/**
 * __useCreateActivityMutation__
 *
 * To run a mutation, you first call `useCreateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityMutation, { data, loading, error }] = useCreateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivityMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateActivityMutation,
		CreateActivityMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateActivityMutation,
		CreateActivityMutationVariables
	>(CreateActivityDocument, options)
}
export type CreateActivityMutationHookResult = ReturnType<
	typeof useCreateActivityMutation
>
export type CreateActivityMutationResult =
	Apollo.MutationResult<CreateActivityMutation>
export type CreateActivityMutationOptions = Apollo.BaseMutationOptions<
	CreateActivityMutation,
	CreateActivityMutationVariables
>
export const CreateAreaDocument = gql`
	mutation CreateArea($input: AreaInput!) {
		area(input: $input) {
			_id
			name
			type
			isExterior
			category
		}
	}
`
export type CreateAreaMutationFn = Apollo.MutationFunction<
	CreateAreaMutation,
	CreateAreaMutationVariables
>

/**
 * __useCreateAreaMutation__
 *
 * To run a mutation, you first call `useCreateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaMutation, { data, loading, error }] = useCreateAreaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAreaMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateAreaMutation,
		CreateAreaMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreateAreaMutation, CreateAreaMutationVariables>(
		CreateAreaDocument,
		options,
	)
}
export type CreateAreaMutationHookResult = ReturnType<
	typeof useCreateAreaMutation
>
export type CreateAreaMutationResult = Apollo.MutationResult<CreateAreaMutation>
export type CreateAreaMutationOptions = Apollo.BaseMutationOptions<
	CreateAreaMutation,
	CreateAreaMutationVariables
>
export const CreateCompanyDocument = gql`
	mutation CreateCompany($input: ClientInput!) {
		client(input: $input) {
			...ClientFragment
			services {
				_key
				count
			}
		}
	}
	${ClientFragmentFragmentDoc}
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
	CreateCompanyMutation,
	CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateCompanyMutation,
		CreateCompanyMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateCompanyMutation,
		CreateCompanyMutationVariables
	>(CreateCompanyDocument, options)
}
export type CreateCompanyMutationHookResult = ReturnType<
	typeof useCreateCompanyMutation
>
export type CreateCompanyMutationResult =
	Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
	CreateCompanyMutation,
	CreateCompanyMutationVariables
>
export const CreateContactDocument = gql`
	mutation CreateContact($input: ContactInput!) {
		contact(input: $input) {
			...ContactFragment
		}
	}
	${ContactFragmentFragmentDoc}
`
export type CreateContactMutationFn = Apollo.MutationFunction<
	CreateContactMutation,
	CreateContactMutationVariables
>

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateContactMutation,
		CreateContactMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateContactMutation,
		CreateContactMutationVariables
	>(CreateContactDocument, options)
}
export type CreateContactMutationHookResult = ReturnType<
	typeof useCreateContactMutation
>
export type CreateContactMutationResult =
	Apollo.MutationResult<CreateContactMutation>
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
	CreateContactMutation,
	CreateContactMutationVariables
>
export const CreateCustomerPortalSessionDocument = gql`
	mutation CreateCustomerPortalSession {
		createCustomerPortalSession {
			id
			url
			return_url
			livemode
		}
	}
`
export type CreateCustomerPortalSessionMutationFn = Apollo.MutationFunction<
	CreateCustomerPortalSessionMutation,
	CreateCustomerPortalSessionMutationVariables
>

/**
 * __useCreateCustomerPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerPortalSessionMutation, { data, loading, error }] = useCreateCustomerPortalSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCustomerPortalSessionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateCustomerPortalSessionMutation,
		CreateCustomerPortalSessionMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateCustomerPortalSessionMutation,
		CreateCustomerPortalSessionMutationVariables
	>(CreateCustomerPortalSessionDocument, options)
}
export type CreateCustomerPortalSessionMutationHookResult = ReturnType<
	typeof useCreateCustomerPortalSessionMutation
>
export type CreateCustomerPortalSessionMutationResult =
	Apollo.MutationResult<CreateCustomerPortalSessionMutation>
export type CreateCustomerPortalSessionMutationOptions =
	Apollo.BaseMutationOptions<
		CreateCustomerPortalSessionMutation,
		CreateCustomerPortalSessionMutationVariables
	>
export const CreateExportDocument = gql`
	mutation CreateExport($input: ExportInput!) {
		export(input: $input)
	}
`
export type CreateExportMutationFn = Apollo.MutationFunction<
	CreateExportMutation,
	CreateExportMutationVariables
>

/**
 * __useCreateExportMutation__
 *
 * To run a mutation, you first call `useCreateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportMutation, { data, loading, error }] = useCreateExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExportMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateExportMutation,
		CreateExportMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateExportMutation,
		CreateExportMutationVariables
	>(CreateExportDocument, options)
}
export type CreateExportMutationHookResult = ReturnType<
	typeof useCreateExportMutation
>
export type CreateExportMutationResult =
	Apollo.MutationResult<CreateExportMutation>
export type CreateExportMutationOptions = Apollo.BaseMutationOptions<
	CreateExportMutation,
	CreateExportMutationVariables
>
export const CreateFacilityDocument = gql`
	mutation CreateFacility($input: FacilityInput!) {
		facility(input: $input) {
			...FacilityFragment
		}
	}
	${FacilityFragmentFragmentDoc}
`
export type CreateFacilityMutationFn = Apollo.MutationFunction<
	CreateFacilityMutation,
	CreateFacilityMutationVariables
>

/**
 * __useCreateFacilityMutation__
 *
 * To run a mutation, you first call `useCreateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFacilityMutation, { data, loading, error }] = useCreateFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFacilityMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateFacilityMutation,
		CreateFacilityMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateFacilityMutation,
		CreateFacilityMutationVariables
	>(CreateFacilityDocument, options)
}
export type CreateFacilityMutationHookResult = ReturnType<
	typeof useCreateFacilityMutation
>
export type CreateFacilityMutationResult =
	Apollo.MutationResult<CreateFacilityMutation>
export type CreateFacilityMutationOptions = Apollo.BaseMutationOptions<
	CreateFacilityMutation,
	CreateFacilityMutationVariables
>
export const CreateInvoiceDocument = gql`
	mutation CreateInvoice($input: InvoiceInput!) {
		invoice(input: $input) {
			...InvoiceFragment
		}
	}
	${InvoiceFragmentFragmentDoc}
`
export type CreateInvoiceMutationFn = Apollo.MutationFunction<
	CreateInvoiceMutation,
	CreateInvoiceMutationVariables
>

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateInvoiceMutation,
		CreateInvoiceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateInvoiceMutation,
		CreateInvoiceMutationVariables
	>(CreateInvoiceDocument, options)
}
export type CreateInvoiceMutationHookResult = ReturnType<
	typeof useCreateInvoiceMutation
>
export type CreateInvoiceMutationResult =
	Apollo.MutationResult<CreateInvoiceMutation>
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
	CreateInvoiceMutation,
	CreateInvoiceMutationVariables
>
export const CreateMagicLinkDocument = gql`
	mutation CreateMagicLink($input: MagicLinkInput!) {
		magicLink(input: $input)
	}
`
export type CreateMagicLinkMutationFn = Apollo.MutationFunction<
	CreateMagicLinkMutation,
	CreateMagicLinkMutationVariables
>

/**
 * __useCreateMagicLinkMutation__
 *
 * To run a mutation, you first call `useCreateMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMagicLinkMutation, { data, loading, error }] = useCreateMagicLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMagicLinkMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateMagicLinkMutation,
		CreateMagicLinkMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateMagicLinkMutation,
		CreateMagicLinkMutationVariables
	>(CreateMagicLinkDocument, options)
}
export type CreateMagicLinkMutationHookResult = ReturnType<
	typeof useCreateMagicLinkMutation
>
export type CreateMagicLinkMutationResult =
	Apollo.MutationResult<CreateMagicLinkMutation>
export type CreateMagicLinkMutationOptions = Apollo.BaseMutationOptions<
	CreateMagicLinkMutation,
	CreateMagicLinkMutationVariables
>
export const CreateMessageTemplateDocument = gql`
	mutation createMessageTemplate($input: MessageTemplateInput!) {
		messageTemplate(input: $input) {
			...MessageTemplateFragment
		}
	}
	${MessageTemplateFragmentFragmentDoc}
`
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<
	CreateMessageTemplateMutation,
	CreateMessageTemplateMutationVariables
>

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateMessageTemplateMutation,
		CreateMessageTemplateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateMessageTemplateMutation,
		CreateMessageTemplateMutationVariables
	>(CreateMessageTemplateDocument, options)
}
export type CreateMessageTemplateMutationHookResult = ReturnType<
	typeof useCreateMessageTemplateMutation
>
export type CreateMessageTemplateMutationResult =
	Apollo.MutationResult<CreateMessageTemplateMutation>
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
	CreateMessageTemplateMutation,
	CreateMessageTemplateMutationVariables
>
export const CreateOrganizationDocument = gql`
	mutation CreateOrganization($input: OrganizationInput!) {
		credentials: organization(input: $input) {
			token
			organization {
				_id
				slug
			}
			user {
				_id
			}
		}
	}
`
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
	CreateOrganizationMutation,
	CreateOrganizationMutationVariables
>

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateOrganizationMutation,
		CreateOrganizationMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateOrganizationMutation,
		CreateOrganizationMutationVariables
	>(CreateOrganizationDocument, options)
}
export type CreateOrganizationMutationHookResult = ReturnType<
	typeof useCreateOrganizationMutation
>
export type CreateOrganizationMutationResult =
	Apollo.MutationResult<CreateOrganizationMutation>
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
	CreateOrganizationMutation,
	CreateOrganizationMutationVariables
>
export const CreateProductDocument = gql`
	mutation createProduct($input: ProductInput!) {
		product(input: $input) {
			...ProductFragment
		}
	}
	${ProductFragmentFragmentDoc}
`
export type CreateProductMutationFn = Apollo.MutationFunction<
	CreateProductMutation,
	CreateProductMutationVariables
>

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateProductMutation,
		CreateProductMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateProductMutation,
		CreateProductMutationVariables
	>(CreateProductDocument, options)
}
export type CreateProductMutationHookResult = ReturnType<
	typeof useCreateProductMutation
>
export type CreateProductMutationResult =
	Apollo.MutationResult<CreateProductMutation>
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
	CreateProductMutation,
	CreateProductMutationVariables
>
export const CreateStripeAccountLoginLinkDocument = gql`
	mutation CreateStripeAccountLoginLink {
		createStripeAccountLoginLink
	}
`
export type CreateStripeAccountLoginLinkMutationFn = Apollo.MutationFunction<
	CreateStripeAccountLoginLinkMutation,
	CreateStripeAccountLoginLinkMutationVariables
>

/**
 * __useCreateStripeAccountLoginLinkMutation__
 *
 * To run a mutation, you first call `useCreateStripeAccountLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeAccountLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeAccountLoginLinkMutation, { data, loading, error }] = useCreateStripeAccountLoginLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeAccountLoginLinkMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateStripeAccountLoginLinkMutation,
		CreateStripeAccountLoginLinkMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateStripeAccountLoginLinkMutation,
		CreateStripeAccountLoginLinkMutationVariables
	>(CreateStripeAccountLoginLinkDocument, options)
}
export type CreateStripeAccountLoginLinkMutationHookResult = ReturnType<
	typeof useCreateStripeAccountLoginLinkMutation
>
export type CreateStripeAccountLoginLinkMutationResult =
	Apollo.MutationResult<CreateStripeAccountLoginLinkMutation>
export type CreateStripeAccountLoginLinkMutationOptions =
	Apollo.BaseMutationOptions<
		CreateStripeAccountLoginLinkMutation,
		CreateStripeAccountLoginLinkMutationVariables
	>
export const CreateTagDocument = gql`
	mutation CreateTag($input: TagInput!) {
		tag(input: $input) {
			...TagFragment
		}
	}
	${TagFragmentFragmentDoc}
`
export type CreateTagMutationFn = Apollo.MutationFunction<
	CreateTagMutation,
	CreateTagMutationVariables
>

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateTagMutation,
		CreateTagMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
		CreateTagDocument,
		options,
	)
}
export type CreateTagMutationHookResult = ReturnType<
	typeof useCreateTagMutation
>
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
	CreateTagMutation,
	CreateTagMutationVariables
>
export const CreateUnitServiceDocument = gql`
	mutation CreateUnitService($input: UnitServiceInput!) {
		unitService(input: $input) {
			...UnitServiceFragment
		}
	}
	${UnitServiceFragmentFragmentDoc}
`
export type CreateUnitServiceMutationFn = Apollo.MutationFunction<
	CreateUnitServiceMutation,
	CreateUnitServiceMutationVariables
>

/**
 * __useCreateUnitServiceMutation__
 *
 * To run a mutation, you first call `useCreateUnitServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitServiceMutation, { data, loading, error }] = useCreateUnitServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnitServiceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateUnitServiceMutation,
		CreateUnitServiceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateUnitServiceMutation,
		CreateUnitServiceMutationVariables
	>(CreateUnitServiceDocument, options)
}
export type CreateUnitServiceMutationHookResult = ReturnType<
	typeof useCreateUnitServiceMutation
>
export type CreateUnitServiceMutationResult =
	Apollo.MutationResult<CreateUnitServiceMutation>
export type CreateUnitServiceMutationOptions = Apollo.BaseMutationOptions<
	CreateUnitServiceMutation,
	CreateUnitServiceMutationVariables
>
export const ExportDataDocument = gql`
	mutation ExportData($input: ExportInput!) {
		export(input: $input)
	}
`
export type ExportDataMutationFn = Apollo.MutationFunction<
	ExportDataMutation,
	ExportDataMutationVariables
>

/**
 * __useExportDataMutation__
 *
 * To run a mutation, you first call `useExportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDataMutation, { data, loading, error }] = useExportDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDataMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ExportDataMutation,
		ExportDataMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<ExportDataMutation, ExportDataMutationVariables>(
		ExportDataDocument,
		options,
	)
}
export type ExportDataMutationHookResult = ReturnType<
	typeof useExportDataMutation
>
export type ExportDataMutationResult = Apollo.MutationResult<ExportDataMutation>
export type ExportDataMutationOptions = Apollo.BaseMutationOptions<
	ExportDataMutation,
	ExportDataMutationVariables
>
export const GenerateCalendarDocument = gql`
	mutation GenerateCalendar($input: GenerateCalendarInput!) {
		calendar(input: $input)
	}
`
export type GenerateCalendarMutationFn = Apollo.MutationFunction<
	GenerateCalendarMutation,
	GenerateCalendarMutationVariables
>

/**
 * __useGenerateCalendarMutation__
 *
 * To run a mutation, you first call `useGenerateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCalendarMutation, { data, loading, error }] = useGenerateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCalendarMutation(
	baseOptions?: Apollo.MutationHookOptions<
		GenerateCalendarMutation,
		GenerateCalendarMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		GenerateCalendarMutation,
		GenerateCalendarMutationVariables
	>(GenerateCalendarDocument, options)
}
export type GenerateCalendarMutationHookResult = ReturnType<
	typeof useGenerateCalendarMutation
>
export type GenerateCalendarMutationResult =
	Apollo.MutationResult<GenerateCalendarMutation>
export type GenerateCalendarMutationOptions = Apollo.BaseMutationOptions<
	GenerateCalendarMutation,
	GenerateCalendarMutationVariables
>
export const LogInDocument = gql`
	mutation LogIn($input: LoginInput!) {
		credentials: login(input: $input) {
			token
			user {
				...UserFragment
			}
		}
	}
	${UserFragmentFragmentDoc}
`
export type LogInMutationFn = Apollo.MutationFunction<
	LogInMutation,
	LogInMutationVariables
>

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogInMutation(
	baseOptions?: Apollo.MutationHookOptions<
		LogInMutation,
		LogInMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<LogInMutation, LogInMutationVariables>(
		LogInDocument,
		options,
	)
}
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>
export type LogInMutationOptions = Apollo.BaseMutationOptions<
	LogInMutation,
	LogInMutationVariables
>
export const RequestResetPasswordDocument = gql`
	mutation RequestResetPassword($input: RequestResetPasswordInput!) {
		success: requestResetPassword(input: $input)
	}
`
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<
	RequestResetPasswordMutation,
	RequestResetPasswordMutationVariables
>

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		RequestResetPasswordMutation,
		RequestResetPasswordMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		RequestResetPasswordMutation,
		RequestResetPasswordMutationVariables
	>(RequestResetPasswordDocument, options)
}
export type RequestResetPasswordMutationHookResult = ReturnType<
	typeof useRequestResetPasswordMutation
>
export type RequestResetPasswordMutationResult =
	Apollo.MutationResult<RequestResetPasswordMutation>
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<
	RequestResetPasswordMutation,
	RequestResetPasswordMutationVariables
>
export const RequestUserVerificationDocument = gql`
	mutation requestUserVerification(
		$user: ID!
		$input: VerifyUserAttributeInput!
	) {
		requestVerifyUserAttribute(user: $user, input: $input)
	}
`
export type RequestUserVerificationMutationFn = Apollo.MutationFunction<
	RequestUserVerificationMutation,
	RequestUserVerificationMutationVariables
>

/**
 * __useRequestUserVerificationMutation__
 *
 * To run a mutation, you first call `useRequestUserVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUserVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUserVerificationMutation, { data, loading, error }] = useRequestUserVerificationMutation({
 *   variables: {
 *      user: // value for 'user'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestUserVerificationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		RequestUserVerificationMutation,
		RequestUserVerificationMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		RequestUserVerificationMutation,
		RequestUserVerificationMutationVariables
	>(RequestUserVerificationDocument, options)
}
export type RequestUserVerificationMutationHookResult = ReturnType<
	typeof useRequestUserVerificationMutation
>
export type RequestUserVerificationMutationResult =
	Apollo.MutationResult<RequestUserVerificationMutation>
export type RequestUserVerificationMutationOptions = Apollo.BaseMutationOptions<
	RequestUserVerificationMutation,
	RequestUserVerificationMutationVariables
>
export const ResetPasswordDocument = gql`
	mutation ResetPassword($input: ResetPasswordInput!) {
		credentials: resetPassword(input: $input) {
			token
			user {
				...UserFragment
			}
		}
	}
	${UserFragmentFragmentDoc}
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<
	ResetPasswordMutation,
	ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<
	typeof useResetPasswordMutation
>
export type ResetPasswordMutationResult =
	Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
	ResetPasswordMutation,
	ResetPasswordMutationVariables
>
export const SendMessageDocument = gql`
	mutation SendMessage($input: SendMessageInput!) {
		sendMessage(input: $input)
	}
`
export type SendMessageMutationFn = Apollo.MutationFunction<
	SendMessageMutation,
	SendMessageMutationVariables
>

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendMessageMutation,
		SendMessageMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
		SendMessageDocument,
		options,
	)
}
export type SendMessageMutationHookResult = ReturnType<
	typeof useSendMessageMutation
>
export type SendMessageMutationResult =
	Apollo.MutationResult<SendMessageMutation>
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
	SendMessageMutation,
	SendMessageMutationVariables
>
export const ShareDocument = gql`
	mutation Share($input: ShareInput!) {
		share(input: $input) {
			recipients {
				contactsCount
			}
		}
	}
`
export type ShareMutationFn = Apollo.MutationFunction<
	ShareMutation,
	ShareMutationVariables
>

/**
 * __useShareMutation__
 *
 * To run a mutation, you first call `useShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMutation, { data, loading, error }] = useShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ShareMutation,
		ShareMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<ShareMutation, ShareMutationVariables>(
		ShareDocument,
		options,
	)
}
export type ShareMutationHookResult = ReturnType<typeof useShareMutation>
export type ShareMutationResult = Apollo.MutationResult<ShareMutation>
export type ShareMutationOptions = Apollo.BaseMutationOptions<
	ShareMutation,
	ShareMutationVariables
>
export const UpdateContactDocument = gql`
	mutation updateContact($id: ID!, $input: UpdateContactInput!) {
		updateContact(id: $id, input: $input) {
			...ContactFragment
		}
	}
	${ContactFragmentFragmentDoc}
`
export type UpdateContactMutationFn = Apollo.MutationFunction<
	UpdateContactMutation,
	UpdateContactMutationVariables
>

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateContactMutation,
		UpdateContactMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateContactMutation,
		UpdateContactMutationVariables
	>(UpdateContactDocument, options)
}
export type UpdateContactMutationHookResult = ReturnType<
	typeof useUpdateContactMutation
>
export type UpdateContactMutationResult =
	Apollo.MutationResult<UpdateContactMutation>
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
	UpdateContactMutation,
	UpdateContactMutationVariables
>
export const UpdateEmployeeDocument = gql`
	mutation UpdateEmployee($id: ID!, $input: UpdateEmployeeInput!) {
		updateEmployee(id: $id, input: $input) {
			...EmployeeFragment
		}
	}
	${EmployeeFragmentFragmentDoc}
`
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<
	UpdateEmployeeMutation,
	UpdateEmployeeMutationVariables
>

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateEmployeeMutation,
		UpdateEmployeeMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateEmployeeMutation,
		UpdateEmployeeMutationVariables
	>(UpdateEmployeeDocument, options)
}
export type UpdateEmployeeMutationHookResult = ReturnType<
	typeof useUpdateEmployeeMutation
>
export type UpdateEmployeeMutationResult =
	Apollo.MutationResult<UpdateEmployeeMutation>
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<
	UpdateEmployeeMutation,
	UpdateEmployeeMutationVariables
>
export const UpdateFacilityDocument = gql`
	mutation UpdateFacility($id: ID!, $input: UpdateFacilityInput!) {
		updateFacility(id: $id, input: $input) {
			...FacilityFragment
		}
	}
	${FacilityFragmentFragmentDoc}
`
export type UpdateFacilityMutationFn = Apollo.MutationFunction<
	UpdateFacilityMutation,
	UpdateFacilityMutationVariables
>

/**
 * __useUpdateFacilityMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityMutation, { data, loading, error }] = useUpdateFacilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilityMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateFacilityMutation,
		UpdateFacilityMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateFacilityMutation,
		UpdateFacilityMutationVariables
	>(UpdateFacilityDocument, options)
}
export type UpdateFacilityMutationHookResult = ReturnType<
	typeof useUpdateFacilityMutation
>
export type UpdateFacilityMutationResult =
	Apollo.MutationResult<UpdateFacilityMutation>
export type UpdateFacilityMutationOptions = Apollo.BaseMutationOptions<
	UpdateFacilityMutation,
	UpdateFacilityMutationVariables
>
export const UpdateFileDocument = gql`
	mutation UpdateFile($id: ID!, $input: UpdateFileInput!) {
		updateFile(id: $id, input: $input) {
			...FileFragment
		}
	}
	${FileFragmentFragmentDoc}
`
export type UpdateFileMutationFn = Apollo.MutationFunction<
	UpdateFileMutation,
	UpdateFileMutationVariables
>

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateFileMutation,
		UpdateFileMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(
		UpdateFileDocument,
		options,
	)
}
export type UpdateFileMutationHookResult = ReturnType<
	typeof useUpdateFileMutation
>
export type UpdateFileMutationResult = Apollo.MutationResult<UpdateFileMutation>
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<
	UpdateFileMutation,
	UpdateFileMutationVariables
>
export const UpdateInvoiceDocument = gql`
	mutation UpdateInvoice($id: ID!, $input: UpdateInvoiceInput!) {
		updateInvoice(id: $id, input: $input) {
			...InvoiceFragment
		}
	}
	${InvoiceFragmentFragmentDoc}
`
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<
	UpdateInvoiceMutation,
	UpdateInvoiceMutationVariables
>

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateInvoiceMutation,
		UpdateInvoiceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateInvoiceMutation,
		UpdateInvoiceMutationVariables
	>(UpdateInvoiceDocument, options)
}
export type UpdateInvoiceMutationHookResult = ReturnType<
	typeof useUpdateInvoiceMutation
>
export type UpdateInvoiceMutationResult =
	Apollo.MutationResult<UpdateInvoiceMutation>
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
	UpdateInvoiceMutation,
	UpdateInvoiceMutationVariables
>
export const UpdateMessageTemplateDocument = gql`
	mutation UpdateMessageTemplate(
		$id: ID!
		$input: UpdateMessageTemplateInput!
	) {
		updateMessageTemplate(id: $id, input: $input) {
			...MessageTemplateFragment
		}
	}
	${MessageTemplateFragmentFragmentDoc}
`
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<
	UpdateMessageTemplateMutation,
	UpdateMessageTemplateMutationVariables
>

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateMessageTemplateMutation,
		UpdateMessageTemplateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateMessageTemplateMutation,
		UpdateMessageTemplateMutationVariables
	>(UpdateMessageTemplateDocument, options)
}
export type UpdateMessageTemplateMutationHookResult = ReturnType<
	typeof useUpdateMessageTemplateMutation
>
export type UpdateMessageTemplateMutationResult =
	Apollo.MutationResult<UpdateMessageTemplateMutation>
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
	UpdateMessageTemplateMutation,
	UpdateMessageTemplateMutationVariables
>
export const UpdateOrganizationDocument = gql`
	mutation UpdateOrganization($id: ID!, $input: UpdateOrganizationInput!) {
		updateOrganization(id: $id, input: $input) {
			...OrganizationFragment
		}
	}
	${OrganizationFragmentFragmentDoc}
`
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
	UpdateOrganizationMutation,
	UpdateOrganizationMutationVariables
>

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateOrganizationMutation,
		UpdateOrganizationMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateOrganizationMutation,
		UpdateOrganizationMutationVariables
	>(UpdateOrganizationDocument, options)
}
export type UpdateOrganizationMutationHookResult = ReturnType<
	typeof useUpdateOrganizationMutation
>
export type UpdateOrganizationMutationResult =
	Apollo.MutationResult<UpdateOrganizationMutation>
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
	UpdateOrganizationMutation,
	UpdateOrganizationMutationVariables
>
export const UpdateProductDocument = gql`
	mutation UpdateProduct($id: ID!, $input: UpdateProductInput!) {
		updateProduct(id: $id, input: $input) {
			...ProductFragment
		}
	}
	${ProductFragmentFragmentDoc}
`
export type UpdateProductMutationFn = Apollo.MutationFunction<
	UpdateProductMutation,
	UpdateProductMutationVariables
>

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateProductMutation,
		UpdateProductMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateProductMutation,
		UpdateProductMutationVariables
	>(UpdateProductDocument, options)
}
export type UpdateProductMutationHookResult = ReturnType<
	typeof useUpdateProductMutation
>
export type UpdateProductMutationResult =
	Apollo.MutationResult<UpdateProductMutation>
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
	UpdateProductMutation,
	UpdateProductMutationVariables
>
export const UpdateServiceDocument = gql`
	mutation UpdateService($id: ID!, $input: UpdateServiceInput!) {
		service: updateService(id: $id, input: $input) {
			...ServiceDetailFragment
		}
	}
	${ServiceDetailFragmentFragmentDoc}
`
export type UpdateServiceMutationFn = Apollo.MutationFunction<
	UpdateServiceMutation,
	UpdateServiceMutationVariables
>

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateServiceMutation,
		UpdateServiceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateServiceMutation,
		UpdateServiceMutationVariables
	>(UpdateServiceDocument, options)
}
export type UpdateServiceMutationHookResult = ReturnType<
	typeof useUpdateServiceMutation
>
export type UpdateServiceMutationResult =
	Apollo.MutationResult<UpdateServiceMutation>
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<
	UpdateServiceMutation,
	UpdateServiceMutationVariables
>
export const UpdateTagDocument = gql`
	mutation UpdateTag($id: ID!, $input: UpdateTagInput!) {
		updateTag(id: $id, input: $input) {
			...TagFragment
		}
	}
	${TagFragmentFragmentDoc}
`
export type UpdateTagMutationFn = Apollo.MutationFunction<
	UpdateTagMutation,
	UpdateTagMutationVariables
>

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateTagMutation,
		UpdateTagMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(
		UpdateTagDocument,
		options,
	)
}
export type UpdateTagMutationHookResult = ReturnType<
	typeof useUpdateTagMutation
>
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
	UpdateTagMutation,
	UpdateTagMutationVariables
>
export const UpdateUnitServiceDocument = gql`
	mutation UpdateUnitService($id: ID!, $input: UpdateUnitServiceInput!) {
		updateUnitService(id: $id, input: $input) {
			...UnitServiceFragment
		}
	}
	${UnitServiceFragmentFragmentDoc}
`
export type UpdateUnitServiceMutationFn = Apollo.MutationFunction<
	UpdateUnitServiceMutation,
	UpdateUnitServiceMutationVariables
>

/**
 * __useUpdateUnitServiceMutation__
 *
 * To run a mutation, you first call `useUpdateUnitServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitServiceMutation, { data, loading, error }] = useUpdateUnitServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnitServiceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateUnitServiceMutation,
		UpdateUnitServiceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateUnitServiceMutation,
		UpdateUnitServiceMutationVariables
	>(UpdateUnitServiceDocument, options)
}
export type UpdateUnitServiceMutationHookResult = ReturnType<
	typeof useUpdateUnitServiceMutation
>
export type UpdateUnitServiceMutationResult =
	Apollo.MutationResult<UpdateUnitServiceMutation>
export type UpdateUnitServiceMutationOptions = Apollo.BaseMutationOptions<
	UpdateUnitServiceMutation,
	UpdateUnitServiceMutationVariables
>
export const UpdateUserSignupDocument = gql`
	mutation UpdateUserSignup($id: ID!, $input: UpdateUserInput!) {
		updateUser(id: $id, input: $input) {
			_id
			name
			phoneNumber
		}
	}
`
export type UpdateUserSignupMutationFn = Apollo.MutationFunction<
	UpdateUserSignupMutation,
	UpdateUserSignupMutationVariables
>

/**
 * __useUpdateUserSignupMutation__
 *
 * To run a mutation, you first call `useUpdateUserSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSignupMutation, { data, loading, error }] = useUpdateUserSignupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSignupMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateUserSignupMutation,
		UpdateUserSignupMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateUserSignupMutation,
		UpdateUserSignupMutationVariables
	>(UpdateUserSignupDocument, options)
}
export type UpdateUserSignupMutationHookResult = ReturnType<
	typeof useUpdateUserSignupMutation
>
export type UpdateUserSignupMutationResult =
	Apollo.MutationResult<UpdateUserSignupMutation>
export type UpdateUserSignupMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserSignupMutation,
	UpdateUserSignupMutationVariables
>
export const UpdateUserDocument = gql`
	mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
		updateUser(id: $id, input: $input) {
			...UserFragment
			...UserPermissionsFragment
			contact {
				...ClientContactFragment
			}
		}
	}
	${UserFragmentFragmentDoc}
	${UserPermissionsFragmentFragmentDoc}
	${ClientContactFragmentFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
	UpdateUserMutation,
	UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateUserMutation,
		UpdateUserMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
		UpdateUserDocument,
		options,
	)
}
export type UpdateUserMutationHookResult = ReturnType<
	typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserMutation,
	UpdateUserMutationVariables
>
export const VerifyUserVerificationDocument = gql`
	mutation verifyUserVerification(
		$user: ID!
		$input: VerifyUserAttributeInput!
	) {
		verifyUserAttribute(user: $user, input: $input)
	}
`
export type VerifyUserVerificationMutationFn = Apollo.MutationFunction<
	VerifyUserVerificationMutation,
	VerifyUserVerificationMutationVariables
>

/**
 * __useVerifyUserVerificationMutation__
 *
 * To run a mutation, you first call `useVerifyUserVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserVerificationMutation, { data, loading, error }] = useVerifyUserVerificationMutation({
 *   variables: {
 *      user: // value for 'user'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyUserVerificationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		VerifyUserVerificationMutation,
		VerifyUserVerificationMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		VerifyUserVerificationMutation,
		VerifyUserVerificationMutationVariables
	>(VerifyUserVerificationDocument, options)
}
export type VerifyUserVerificationMutationHookResult = ReturnType<
	typeof useVerifyUserVerificationMutation
>
export type VerifyUserVerificationMutationResult =
	Apollo.MutationResult<VerifyUserVerificationMutation>
export type VerifyUserVerificationMutationOptions = Apollo.BaseMutationOptions<
	VerifyUserVerificationMutation,
	VerifyUserVerificationMutationVariables
>
export const GetAgendaDocument = gql`
	query GetAgenda($sFilter: ServicesFilter, $eFilter: EmployeesFilter) {
		services(filter: $sFilter) {
			_key
			nodes {
				_id
				date
				arrivedAt
				completedAt
				enRouteAt
				type
				url
				client {
					_id
					name
				}
				facility {
					_id
					name
				}
				employees {
					_key
					nodes {
						_id
					}
				}
				units {
					_key
					nodes {
						_id
						number
						status {
							general
						}
					}
				}
			}
		}
		employees(filter: $eFilter) {
			_key
			nodes {
				_id
				name
				surname
				user {
					_id
					photo {
						url
					}
				}
			}
		}
	}
`

/**
 * __useGetAgendaQuery__
 *
 * To run a query within a React component, call `useGetAgendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgendaQuery({
 *   variables: {
 *      sFilter: // value for 'sFilter'
 *      eFilter: // value for 'eFilter'
 *   },
 * });
 */
export function useGetAgendaQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAgendaQuery,
		GetAgendaQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetAgendaQuery, GetAgendaQueryVariables>(
		GetAgendaDocument,
		options,
	)
}
export function useGetAgendaLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAgendaQuery,
		GetAgendaQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetAgendaQuery, GetAgendaQueryVariables>(
		GetAgendaDocument,
		options,
	)
}
export function useGetAgendaSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetAgendaQuery, GetAgendaQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetAgendaQuery, GetAgendaQueryVariables>(
		GetAgendaDocument,
		options,
	)
}
export type GetAgendaQueryHookResult = ReturnType<typeof useGetAgendaQuery>
export type GetAgendaLazyQueryHookResult = ReturnType<
	typeof useGetAgendaLazyQuery
>
export type GetAgendaSuspenseQueryHookResult = ReturnType<
	typeof useGetAgendaSuspenseQuery
>
export type GetAgendaQueryResult = Apollo.QueryResult<
	GetAgendaQuery,
	GetAgendaQueryVariables
>
export const GetCompletionDocument = gql`
	query GetCompletion($input: CompletionInput!) {
		completion(input: $input) {
			_id
			choices {
				text
			}
		}
	}
`

/**
 * __useGetCompletionQuery__
 *
 * To run a query within a React component, call `useGetCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompletionQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCompletionQuery,
		GetCompletionQueryVariables
	> &
		(
			| { variables: GetCompletionQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetCompletionQuery, GetCompletionQueryVariables>(
		GetCompletionDocument,
		options,
	)
}
export function useGetCompletionLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCompletionQuery,
		GetCompletionQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetCompletionQuery, GetCompletionQueryVariables>(
		GetCompletionDocument,
		options,
	)
}
export function useGetCompletionSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetCompletionQuery,
				GetCompletionQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetCompletionQuery,
		GetCompletionQueryVariables
	>(GetCompletionDocument, options)
}
export type GetCompletionQueryHookResult = ReturnType<
	typeof useGetCompletionQuery
>
export type GetCompletionLazyQueryHookResult = ReturnType<
	typeof useGetCompletionLazyQuery
>
export type GetCompletionSuspenseQueryHookResult = ReturnType<
	typeof useGetCompletionSuspenseQuery
>
export type GetCompletionQueryResult = Apollo.QueryResult<
	GetCompletionQuery,
	GetCompletionQueryVariables
>
export const GetContactDocument = gql`
	query GetContact($id: ID!) {
		contact(id: $id) {
			...ContactFragment
			user {
				_id
			}
			facilities {
				_key
				nodes {
					_id
					name
				}
			}
			tags {
				_key
				nodes {
					...TagFragment
				}
			}
			company {
				_id
				name
				role
			}
		}
	}
	${ContactFragmentFragmentDoc}
	${TagFragmentFragmentDoc}
`

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetContactQuery,
		GetContactQueryVariables
	> &
		(
			| { variables: GetContactQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(
		GetContactDocument,
		options,
	)
}
export function useGetContactLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetContactQuery,
		GetContactQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(
		GetContactDocument,
		options,
	)
}
export function useGetContactSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetContactQuery,
				GetContactQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetContactQuery, GetContactQueryVariables>(
		GetContactDocument,
		options,
	)
}
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>
export type GetContactLazyQueryHookResult = ReturnType<
	typeof useGetContactLazyQuery
>
export type GetContactSuspenseQueryHookResult = ReturnType<
	typeof useGetContactSuspenseQuery
>
export type GetContactQueryResult = Apollo.QueryResult<
	GetContactQuery,
	GetContactQueryVariables
>
export const GetFacilitiesCountDocument = gql`
	query GetFacilitiesCount($filter: FacilitiesFilter) {
		facilities(filter: $filter) {
			count
			_key
		}
	}
`

/**
 * __useGetFacilitiesCountQuery__
 *
 * To run a query within a React component, call `useGetFacilitiesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilitiesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilitiesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFacilitiesCountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetFacilitiesCountQuery,
		GetFacilitiesCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetFacilitiesCountQuery,
		GetFacilitiesCountQueryVariables
	>(GetFacilitiesCountDocument, options)
}
export function useGetFacilitiesCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFacilitiesCountQuery,
		GetFacilitiesCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetFacilitiesCountQuery,
		GetFacilitiesCountQueryVariables
	>(GetFacilitiesCountDocument, options)
}
export function useGetFacilitiesCountSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetFacilitiesCountQuery,
				GetFacilitiesCountQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetFacilitiesCountQuery,
		GetFacilitiesCountQueryVariables
	>(GetFacilitiesCountDocument, options)
}
export type GetFacilitiesCountQueryHookResult = ReturnType<
	typeof useGetFacilitiesCountQuery
>
export type GetFacilitiesCountLazyQueryHookResult = ReturnType<
	typeof useGetFacilitiesCountLazyQuery
>
export type GetFacilitiesCountSuspenseQueryHookResult = ReturnType<
	typeof useGetFacilitiesCountSuspenseQuery
>
export type GetFacilitiesCountQueryResult = Apollo.QueryResult<
	GetFacilitiesCountQuery,
	GetFacilitiesCountQueryVariables
>
export const GetFacilityDocument = gql`
	query GetFacility($id: ID!) {
		facility(id: $id) {
			...FacilityFragment
		}
	}
	${FacilityFragmentFragmentDoc}
`

/**
 * __useGetFacilityQuery__
 *
 * To run a query within a React component, call `useGetFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFacilityQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetFacilityQuery,
		GetFacilityQueryVariables
	> &
		(
			| { variables: GetFacilityQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetFacilityQuery, GetFacilityQueryVariables>(
		GetFacilityDocument,
		options,
	)
}
export function useGetFacilityLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFacilityQuery,
		GetFacilityQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetFacilityQuery, GetFacilityQueryVariables>(
		GetFacilityDocument,
		options,
	)
}
export function useGetFacilitySuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetFacilityQuery,
				GetFacilityQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetFacilityQuery, GetFacilityQueryVariables>(
		GetFacilityDocument,
		options,
	)
}
export type GetFacilityQueryHookResult = ReturnType<typeof useGetFacilityQuery>
export type GetFacilityLazyQueryHookResult = ReturnType<
	typeof useGetFacilityLazyQuery
>
export type GetFacilitySuspenseQueryHookResult = ReturnType<
	typeof useGetFacilitySuspenseQuery
>
export type GetFacilityQueryResult = Apollo.QueryResult<
	GetFacilityQuery,
	GetFacilityQueryVariables
>
export const GetFileDocument = gql`
	query GetFile($id: ID!) {
		file(id: $id) {
			...FileFragment
		}
	}
	${FileFragmentFragmentDoc}
`

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileQuery(
	baseOptions: Apollo.QueryHookOptions<GetFileQuery, GetFileQueryVariables> &
		({ variables: GetFileQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetFileQuery, GetFileQueryVariables>(
		GetFileDocument,
		options,
	)
}
export function useGetFileLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFileQuery,
		GetFileQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetFileQuery, GetFileQueryVariables>(
		GetFileDocument,
		options,
	)
}
export function useGetFileSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetFileQuery, GetFileQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetFileQuery, GetFileQueryVariables>(
		GetFileDocument,
		options,
	)
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>
export type GetFileSuspenseQueryHookResult = ReturnType<
	typeof useGetFileSuspenseQuery
>
export type GetFileQueryResult = Apollo.QueryResult<
	GetFileQuery,
	GetFileQueryVariables
>
export const GetInvoiceDocument = gql`
	query GetInvoice($id: ID!) {
		invoice(id: $id) {
			_id
			date
			isDraft
			isArchived
			isSaleNote
			isCanceled
			type
			paymentForm
			paymentMethod
			currency
			subTotal
			total
			taxAmount
			transactions {
				paymentLink
			}
			items {
				product {
					_id
				}
				price
				quantity
				currency
				discounts
				taxes
				description
			}
			company {
				_id
				name
				legalName
			}
			number {
				organization
				company
			}
			stamp {
				date
				seal
			}
			file {
				url
			}
		}
	}
`

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetInvoiceQuery,
		GetInvoiceQueryVariables
	> &
		(
			| { variables: GetInvoiceQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(
		GetInvoiceDocument,
		options,
	)
}
export function useGetInvoiceLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetInvoiceQuery,
		GetInvoiceQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(
		GetInvoiceDocument,
		options,
	)
}
export function useGetInvoiceSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetInvoiceQuery,
				GetInvoiceQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(
		GetInvoiceDocument,
		options,
	)
}
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>
export type GetInvoiceLazyQueryHookResult = ReturnType<
	typeof useGetInvoiceLazyQuery
>
export type GetInvoiceSuspenseQueryHookResult = ReturnType<
	typeof useGetInvoiceSuspenseQuery
>
export type GetInvoiceQueryResult = Apollo.QueryResult<
	GetInvoiceQuery,
	GetInvoiceQueryVariables
>
export const GetInvoiceEditDocument = gql`
	query GetInvoiceEdit($id: ID!) {
		invoice(id: $id) {
			...InvoiceFragment
		}
	}
	${InvoiceFragmentFragmentDoc}
`

/**
 * __useGetInvoiceEditQuery__
 *
 * To run a query within a React component, call `useGetInvoiceEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceEditQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetInvoiceEditQuery,
		GetInvoiceEditQueryVariables
	> &
		(
			| { variables: GetInvoiceEditQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetInvoiceEditQuery, GetInvoiceEditQueryVariables>(
		GetInvoiceEditDocument,
		options,
	)
}
export function useGetInvoiceEditLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetInvoiceEditQuery,
		GetInvoiceEditQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetInvoiceEditQuery, GetInvoiceEditQueryVariables>(
		GetInvoiceEditDocument,
		options,
	)
}
export function useGetInvoiceEditSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetInvoiceEditQuery,
				GetInvoiceEditQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetInvoiceEditQuery,
		GetInvoiceEditQueryVariables
	>(GetInvoiceEditDocument, options)
}
export type GetInvoiceEditQueryHookResult = ReturnType<
	typeof useGetInvoiceEditQuery
>
export type GetInvoiceEditLazyQueryHookResult = ReturnType<
	typeof useGetInvoiceEditLazyQuery
>
export type GetInvoiceEditSuspenseQueryHookResult = ReturnType<
	typeof useGetInvoiceEditSuspenseQuery
>
export type GetInvoiceEditQueryResult = Apollo.QueryResult<
	GetInvoiceEditQuery,
	GetInvoiceEditQueryVariables
>
export const GetLocalFieldsDocument = gql`
	query GetLocalFields {
		oAuth @client
		selectedFacility @client
	}
`

/**
 * __useGetLocalFieldsQuery__
 *
 * To run a query within a React component, call `useGetLocalFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocalFieldsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetLocalFieldsQuery,
		GetLocalFieldsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetLocalFieldsQuery, GetLocalFieldsQueryVariables>(
		GetLocalFieldsDocument,
		options,
	)
}
export function useGetLocalFieldsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetLocalFieldsQuery,
		GetLocalFieldsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetLocalFieldsQuery, GetLocalFieldsQueryVariables>(
		GetLocalFieldsDocument,
		options,
	)
}
export function useGetLocalFieldsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetLocalFieldsQuery,
				GetLocalFieldsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetLocalFieldsQuery,
		GetLocalFieldsQueryVariables
	>(GetLocalFieldsDocument, options)
}
export type GetLocalFieldsQueryHookResult = ReturnType<
	typeof useGetLocalFieldsQuery
>
export type GetLocalFieldsLazyQueryHookResult = ReturnType<
	typeof useGetLocalFieldsLazyQuery
>
export type GetLocalFieldsSuspenseQueryHookResult = ReturnType<
	typeof useGetLocalFieldsSuspenseQuery
>
export type GetLocalFieldsQueryResult = Apollo.QueryResult<
	GetLocalFieldsQuery,
	GetLocalFieldsQueryVariables
>
export const GetOrganizationInsightsDocument = gql`
	query GetOrganizationInsights {
		self {
			_id
			organization {
				_id
				insights {
					charts {
						id
						title
						size
					}
				}
			}
		}
	}
`

/**
 * __useGetOrganizationInsightsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationInsightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationInsightsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationInsightsQuery,
		GetOrganizationInsightsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetOrganizationInsightsQuery,
		GetOrganizationInsightsQueryVariables
	>(GetOrganizationInsightsDocument, options)
}
export function useGetOrganizationInsightsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationInsightsQuery,
		GetOrganizationInsightsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetOrganizationInsightsQuery,
		GetOrganizationInsightsQueryVariables
	>(GetOrganizationInsightsDocument, options)
}
export function useGetOrganizationInsightsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetOrganizationInsightsQuery,
				GetOrganizationInsightsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetOrganizationInsightsQuery,
		GetOrganizationInsightsQueryVariables
	>(GetOrganizationInsightsDocument, options)
}
export type GetOrganizationInsightsQueryHookResult = ReturnType<
	typeof useGetOrganizationInsightsQuery
>
export type GetOrganizationInsightsLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationInsightsLazyQuery
>
export type GetOrganizationInsightsSuspenseQueryHookResult = ReturnType<
	typeof useGetOrganizationInsightsSuspenseQuery
>
export type GetOrganizationInsightsQueryResult = Apollo.QueryResult<
	GetOrganizationInsightsQuery,
	GetOrganizationInsightsQueryVariables
>
export const GetOrganizationPreferencesDocument = gql`
	query GetOrganizationPreferences {
		self {
			_id
			organization {
				_id
				preferences {
					language
					timeZone
					billingEmail
					notifications {
						slack {
							enabled
							webhookUrl
						}
					}
				}
			}
		}
	}
`

/**
 * __useGetOrganizationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationPreferencesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationPreferencesQuery,
		GetOrganizationPreferencesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetOrganizationPreferencesQuery,
		GetOrganizationPreferencesQueryVariables
	>(GetOrganizationPreferencesDocument, options)
}
export function useGetOrganizationPreferencesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationPreferencesQuery,
		GetOrganizationPreferencesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetOrganizationPreferencesQuery,
		GetOrganizationPreferencesQueryVariables
	>(GetOrganizationPreferencesDocument, options)
}
export function useGetOrganizationPreferencesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetOrganizationPreferencesQuery,
				GetOrganizationPreferencesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetOrganizationPreferencesQuery,
		GetOrganizationPreferencesQueryVariables
	>(GetOrganizationPreferencesDocument, options)
}
export type GetOrganizationPreferencesQueryHookResult = ReturnType<
	typeof useGetOrganizationPreferencesQuery
>
export type GetOrganizationPreferencesLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationPreferencesLazyQuery
>
export type GetOrganizationPreferencesSuspenseQueryHookResult = ReturnType<
	typeof useGetOrganizationPreferencesSuspenseQuery
>
export type GetOrganizationPreferencesQueryResult = Apollo.QueryResult<
	GetOrganizationPreferencesQuery,
	GetOrganizationPreferencesQueryVariables
>
export const GetPendingTasksDocument = gql`
	query GetPendingTasks {
		self {
			_id
			organization {
				_id
				address {
					zipCode
				}
				legalName
				taxId
				taxSystem
				isPayoutOnboardingCompleted
			}
		}
		clients {
			_key
			count
		}
	}
`

/**
 * __useGetPendingTasksQuery__
 *
 * To run a query within a React component, call `useGetPendingTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingTasksQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetPendingTasksQuery,
		GetPendingTasksQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetPendingTasksQuery, GetPendingTasksQueryVariables>(
		GetPendingTasksDocument,
		options,
	)
}
export function useGetPendingTasksLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetPendingTasksQuery,
		GetPendingTasksQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetPendingTasksQuery,
		GetPendingTasksQueryVariables
	>(GetPendingTasksDocument, options)
}
export function useGetPendingTasksSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetPendingTasksQuery,
				GetPendingTasksQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetPendingTasksQuery,
		GetPendingTasksQueryVariables
	>(GetPendingTasksDocument, options)
}
export type GetPendingTasksQueryHookResult = ReturnType<
	typeof useGetPendingTasksQuery
>
export type GetPendingTasksLazyQueryHookResult = ReturnType<
	typeof useGetPendingTasksLazyQuery
>
export type GetPendingTasksSuspenseQueryHookResult = ReturnType<
	typeof useGetPendingTasksSuspenseQuery
>
export type GetPendingTasksQueryResult = Apollo.QueryResult<
	GetPendingTasksQuery,
	GetPendingTasksQueryVariables
>
export const GetProductDocument = gql`
	query GetProduct($id: ID!) {
		product(id: $id) {
			...ProductFragment
		}
	}
	${ProductFragmentFragmentDoc}
`

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetProductQuery,
		GetProductQueryVariables
	> &
		(
			| { variables: GetProductQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
		GetProductDocument,
		options,
	)
}
export function useGetProductLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetProductQuery,
		GetProductQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
		GetProductDocument,
		options,
	)
}
export function useGetProductSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetProductQuery,
				GetProductQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetProductQuery, GetProductQueryVariables>(
		GetProductDocument,
		options,
	)
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>
export type GetProductLazyQueryHookResult = ReturnType<
	typeof useGetProductLazyQuery
>
export type GetProductSuspenseQueryHookResult = ReturnType<
	typeof useGetProductSuspenseQuery
>
export type GetProductQueryResult = Apollo.QueryResult<
	GetProductQuery,
	GetProductQueryVariables
>
export const GetProfileDocument = gql`
	query GetProfile {
		self {
			_id
			email
			webAuthn {
				name
			}
		}
		oAuth @client
	}
`

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetProfileQuery,
		GetProfileQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
		GetProfileDocument,
		options,
	)
}
export function useGetProfileLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetProfileQuery,
		GetProfileQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
		GetProfileDocument,
		options,
	)
}
export function useGetProfileSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetProfileQuery,
				GetProfileQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetProfileQuery, GetProfileQueryVariables>(
		GetProfileDocument,
		options,
	)
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>
export type GetProfileLazyQueryHookResult = ReturnType<
	typeof useGetProfileLazyQuery
>
export type GetProfileSuspenseQueryHookResult = ReturnType<
	typeof useGetProfileSuspenseQuery
>
export type GetProfileQueryResult = Apollo.QueryResult<
	GetProfileQuery,
	GetProfileQueryVariables
>
export const GetSearchDocument = gql`
	query GetSearch($input: SearchInput!) {
		search(input: $input) {
			_key
			nodes {
				... on Employee {
					__typename
					_id
					name
					surname
				}
				... on Unit {
					__typename
					_id
					number
					type
					company {
						_id
						name
					}
				}
				... on File {
					__typename
					_id
					url
					name
				}
				... on Product {
					__typename
					_id
					name
				}
				... on Client {
					__typename
					_id
					name
					legalName
				}
				... on Contact {
					__typename
					_id
					name
					surname
					companyContact: company {
						_id
						name
					}
				}
				... on Facility {
					__typename
					_id
					name
					company {
						_id
						name
					}
				}
			}
		}
	}
`

/**
 * __useGetSearchQuery__
 *
 * To run a query within a React component, call `useGetSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetSearchQuery,
		GetSearchQueryVariables
	> &
		(
			| { variables: GetSearchQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetSearchQuery, GetSearchQueryVariables>(
		GetSearchDocument,
		options,
	)
}
export function useGetSearchLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetSearchQuery,
		GetSearchQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetSearchQuery, GetSearchQueryVariables>(
		GetSearchDocument,
		options,
	)
}
export function useGetSearchSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetSearchQuery, GetSearchQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetSearchQuery, GetSearchQueryVariables>(
		GetSearchDocument,
		options,
	)
}
export type GetSearchQueryHookResult = ReturnType<typeof useGetSearchQuery>
export type GetSearchLazyQueryHookResult = ReturnType<
	typeof useGetSearchLazyQuery
>
export type GetSearchSuspenseQueryHookResult = ReturnType<
	typeof useGetSearchSuspenseQuery
>
export type GetSearchQueryResult = Apollo.QueryResult<
	GetSearchQuery,
	GetSearchQueryVariables
>
export const GetSelfDocument = gql`
	query GetSelf {
		selectedFacility @client
		self {
			...UserFragment
		}
	}
	${UserFragmentFragmentDoc}
`

/**
 * __useGetSelfQuery__
 *
 * To run a query within a React component, call `useGetSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfQuery(
	baseOptions?: Apollo.QueryHookOptions<GetSelfQuery, GetSelfQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetSelfQuery, GetSelfQueryVariables>(
		GetSelfDocument,
		options,
	)
}
export function useGetSelfLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetSelfQuery,
		GetSelfQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetSelfQuery, GetSelfQueryVariables>(
		GetSelfDocument,
		options,
	)
}
export function useGetSelfSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetSelfQuery, GetSelfQueryVariables>(
		GetSelfDocument,
		options,
	)
}
export type GetSelfQueryHookResult = ReturnType<typeof useGetSelfQuery>
export type GetSelfLazyQueryHookResult = ReturnType<typeof useGetSelfLazyQuery>
export type GetSelfSuspenseQueryHookResult = ReturnType<
	typeof useGetSelfSuspenseQuery
>
export type GetSelfQueryResult = Apollo.QueryResult<
	GetSelfQuery,
	GetSelfQueryVariables
>
export const GetServiceUnitsDocument = gql`
	query getServiceUnits($id: ID!) {
		service(id: $id) {
			_id
			date
			url
			employees {
				_key
				nodes {
					_id
					name
					surname
					user {
						_id
					}
				}
			}
			units {
				_key
				count
				nodes {
					_id
					number
					type
				}
			}
		}
	}
`

/**
 * __useGetServiceUnitsQuery__
 *
 * To run a query within a React component, call `useGetServiceUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceUnitsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetServiceUnitsQuery,
		GetServiceUnitsQueryVariables
	> &
		(
			| { variables: GetServiceUnitsQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetServiceUnitsQuery, GetServiceUnitsQueryVariables>(
		GetServiceUnitsDocument,
		options,
	)
}
export function useGetServiceUnitsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetServiceUnitsQuery,
		GetServiceUnitsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetServiceUnitsQuery,
		GetServiceUnitsQueryVariables
	>(GetServiceUnitsDocument, options)
}
export function useGetServiceUnitsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetServiceUnitsQuery,
				GetServiceUnitsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetServiceUnitsQuery,
		GetServiceUnitsQueryVariables
	>(GetServiceUnitsDocument, options)
}
export type GetServiceUnitsQueryHookResult = ReturnType<
	typeof useGetServiceUnitsQuery
>
export type GetServiceUnitsLazyQueryHookResult = ReturnType<
	typeof useGetServiceUnitsLazyQuery
>
export type GetServiceUnitsSuspenseQueryHookResult = ReturnType<
	typeof useGetServiceUnitsSuspenseQuery
>
export type GetServiceUnitsQueryResult = Apollo.QueryResult<
	GetServiceUnitsQuery,
	GetServiceUnitsQueryVariables
>
export const GetServicesCountDocument = gql`
	query getServicesCount($filter: ServicesFilter, $limit: Int) {
		services(filter: $filter, limit: $limit) {
			_key
			count
		}
	}
`

/**
 * __useGetServicesCountQuery__
 *
 * To run a query within a React component, call `useGetServicesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetServicesCountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetServicesCountQuery,
		GetServicesCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetServicesCountQuery, GetServicesCountQueryVariables>(
		GetServicesCountDocument,
		options,
	)
}
export function useGetServicesCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetServicesCountQuery,
		GetServicesCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetServicesCountQuery,
		GetServicesCountQueryVariables
	>(GetServicesCountDocument, options)
}
export function useGetServicesCountSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetServicesCountQuery,
				GetServicesCountQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetServicesCountQuery,
		GetServicesCountQueryVariables
	>(GetServicesCountDocument, options)
}
export type GetServicesCountQueryHookResult = ReturnType<
	typeof useGetServicesCountQuery
>
export type GetServicesCountLazyQueryHookResult = ReturnType<
	typeof useGetServicesCountLazyQuery
>
export type GetServicesCountSuspenseQueryHookResult = ReturnType<
	typeof useGetServicesCountSuspenseQuery
>
export type GetServicesCountQueryResult = Apollo.QueryResult<
	GetServicesCountQuery,
	GetServicesCountQueryVariables
>
export const GetServicesWithReportsDocument = gql`
	query GetServicesWithReports(
		$filter: ServicesFilter!
		$limit: Int
		$sort: ServicesSort!
	) {
		services(filter: $filter, limit: $limit, sort: $sort) {
			_key
			nodes {
				_id
				type
				url
				date
				thumbnail
			}
		}
	}
`

/**
 * __useGetServicesWithReportsQuery__
 *
 * To run a query within a React component, call `useGetServicesWithReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesWithReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesWithReportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetServicesWithReportsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetServicesWithReportsQuery,
		GetServicesWithReportsQueryVariables
	> &
		(
			| { variables: GetServicesWithReportsQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetServicesWithReportsQuery,
		GetServicesWithReportsQueryVariables
	>(GetServicesWithReportsDocument, options)
}
export function useGetServicesWithReportsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetServicesWithReportsQuery,
		GetServicesWithReportsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetServicesWithReportsQuery,
		GetServicesWithReportsQueryVariables
	>(GetServicesWithReportsDocument, options)
}
export function useGetServicesWithReportsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetServicesWithReportsQuery,
				GetServicesWithReportsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetServicesWithReportsQuery,
		GetServicesWithReportsQueryVariables
	>(GetServicesWithReportsDocument, options)
}
export type GetServicesWithReportsQueryHookResult = ReturnType<
	typeof useGetServicesWithReportsQuery
>
export type GetServicesWithReportsLazyQueryHookResult = ReturnType<
	typeof useGetServicesWithReportsLazyQuery
>
export type GetServicesWithReportsSuspenseQueryHookResult = ReturnType<
	typeof useGetServicesWithReportsSuspenseQuery
>
export type GetServicesWithReportsQueryResult = Apollo.QueryResult<
	GetServicesWithReportsQuery,
	GetServicesWithReportsQueryVariables
>
export const GetMessageTemplateDocument = gql`
	query GetMessageTemplate($id: ID!) {
		messageTemplate(id: $id) {
			...MessageTemplateFragment
		}
	}
	${MessageTemplateFragmentFragmentDoc}
`

/**
 * __useGetMessageTemplateQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageTemplateQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMessageTemplateQuery,
		GetMessageTemplateQueryVariables
	> &
		(
			| { variables: GetMessageTemplateQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetMessageTemplateQuery,
		GetMessageTemplateQueryVariables
	>(GetMessageTemplateDocument, options)
}
export function useGetMessageTemplateLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMessageTemplateQuery,
		GetMessageTemplateQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetMessageTemplateQuery,
		GetMessageTemplateQueryVariables
	>(GetMessageTemplateDocument, options)
}
export function useGetMessageTemplateSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetMessageTemplateQuery,
				GetMessageTemplateQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetMessageTemplateQuery,
		GetMessageTemplateQueryVariables
	>(GetMessageTemplateDocument, options)
}
export type GetMessageTemplateQueryHookResult = ReturnType<
	typeof useGetMessageTemplateQuery
>
export type GetMessageTemplateLazyQueryHookResult = ReturnType<
	typeof useGetMessageTemplateLazyQuery
>
export type GetMessageTemplateSuspenseQueryHookResult = ReturnType<
	typeof useGetMessageTemplateSuspenseQuery
>
export type GetMessageTemplateQueryResult = Apollo.QueryResult<
	GetMessageTemplateQuery,
	GetMessageTemplateQueryVariables
>
export const GetTransportsDocument = gql`
	query GetTransports {
		transports {
			_key
			nodes {
				_id
				name
				cost
				surfaceArea
			}
		}
	}
`

/**
 * __useGetTransportsQuery__
 *
 * To run a query within a React component, call `useGetTransportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransportsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetTransportsQuery,
		GetTransportsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetTransportsQuery, GetTransportsQueryVariables>(
		GetTransportsDocument,
		options,
	)
}
export function useGetTransportsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetTransportsQuery,
		GetTransportsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetTransportsQuery, GetTransportsQueryVariables>(
		GetTransportsDocument,
		options,
	)
}
export function useGetTransportsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetTransportsQuery,
				GetTransportsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetTransportsQuery,
		GetTransportsQueryVariables
	>(GetTransportsDocument, options)
}
export type GetTransportsQueryHookResult = ReturnType<
	typeof useGetTransportsQuery
>
export type GetTransportsLazyQueryHookResult = ReturnType<
	typeof useGetTransportsLazyQuery
>
export type GetTransportsSuspenseQueryHookResult = ReturnType<
	typeof useGetTransportsSuspenseQuery
>
export type GetTransportsQueryResult = Apollo.QueryResult<
	GetTransportsQuery,
	GetTransportsQueryVariables
>
export const GetUserDocument = gql`
	query GetUser($id: ID!) {
		user(id: $id) {
			_id
			lastLogin
			hasActiveToken
			organization {
				_id
			}
			contact {
				_id
				email
				company {
					_id
					facilities {
						_key
						nodes {
							_id
							name
						}
					}
				}
			}
			roles {
				...RoleFragment
			}
			permissions {
				effect
				action
				resource
			}
			isEmailBouncing
		}
	}
	${RoleFragmentFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
	baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> &
		({ variables: GetUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
		GetUserDocument,
		options,
	)
}
export function useGetUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetUserQuery,
		GetUserQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
		GetUserDocument,
		options,
	)
}
export function useGetUserSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(
		GetUserDocument,
		options,
	)
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserSuspenseQueryHookResult = ReturnType<
	typeof useGetUserSuspenseQuery
>
export type GetUserQueryResult = Apollo.QueryResult<
	GetUserQuery,
	GetUserQueryVariables
>
export const ListActivitiesDocument = gql`
	query ListActivities($filter: ActivitiesFilter!, $sort: ActivitiesSort) {
		activities(filter: $filter, sort: $sort) {
			_key
			nodes {
				...ActivityFragment
			}
		}
	}
	${ActivityFragmentFragmentDoc}
`

/**
 * __useListActivitiesQuery__
 *
 * To run a query within a React component, call `useListActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListActivitiesQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListActivitiesQuery,
		ListActivitiesQueryVariables
	> &
		(
			| { variables: ListActivitiesQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListActivitiesQuery, ListActivitiesQueryVariables>(
		ListActivitiesDocument,
		options,
	)
}
export function useListActivitiesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListActivitiesQuery,
		ListActivitiesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListActivitiesQuery, ListActivitiesQueryVariables>(
		ListActivitiesDocument,
		options,
	)
}
export function useListActivitiesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListActivitiesQuery,
				ListActivitiesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListActivitiesQuery,
		ListActivitiesQueryVariables
	>(ListActivitiesDocument, options)
}
export type ListActivitiesQueryHookResult = ReturnType<
	typeof useListActivitiesQuery
>
export type ListActivitiesLazyQueryHookResult = ReturnType<
	typeof useListActivitiesLazyQuery
>
export type ListActivitiesSuspenseQueryHookResult = ReturnType<
	typeof useListActivitiesSuspenseQuery
>
export type ListActivitiesQueryResult = Apollo.QueryResult<
	ListActivitiesQuery,
	ListActivitiesQueryVariables
>
export const ListActivityDocument = gql`
	query ListActivity(
		$filter: ActivitiesFilter!
		$sort: ActivitiesSort
		$limit: Int
	) {
		activities(filter: $filter, sort: $sort, limit: $limit) {
			_key
			nodes {
				_id
				ipAddress
				userAgent
				createdAt
				metadata
			}
		}
	}
`

/**
 * __useListActivityQuery__
 *
 * To run a query within a React component, call `useListActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivityQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListActivityQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListActivityQuery,
		ListActivityQueryVariables
	> &
		(
			| { variables: ListActivityQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListActivityQuery, ListActivityQueryVariables>(
		ListActivityDocument,
		options,
	)
}
export function useListActivityLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListActivityQuery,
		ListActivityQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListActivityQuery, ListActivityQueryVariables>(
		ListActivityDocument,
		options,
	)
}
export function useListActivitySuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListActivityQuery,
				ListActivityQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<ListActivityQuery, ListActivityQueryVariables>(
		ListActivityDocument,
		options,
	)
}
export type ListActivityQueryHookResult = ReturnType<
	typeof useListActivityQuery
>
export type ListActivityLazyQueryHookResult = ReturnType<
	typeof useListActivityLazyQuery
>
export type ListActivitySuspenseQueryHookResult = ReturnType<
	typeof useListActivitySuspenseQuery
>
export type ListActivityQueryResult = Apollo.QueryResult<
	ListActivityQuery,
	ListActivityQueryVariables
>
export const ListAreaDetailDocument = gql`
	query ListAreaDetail($facility: ID!, $filter: AreasFilter) {
		self {
			_id
			organization {
				_id
				logo {
					url
				}
			}
		}
		facility(id: $facility) {
			_id
			company {
				_id
			}
		}
		areas(filter: $filter) {
			_key
			nodes {
				_id
				name
				type
				isExterior
				active
				units {
					_key
					count
				}
			}
		}
	}
`

/**
 * __useListAreaDetailQuery__
 *
 * To run a query within a React component, call `useListAreaDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAreaDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAreaDetailQuery({
 *   variables: {
 *      facility: // value for 'facility'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAreaDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListAreaDetailQuery,
		ListAreaDetailQueryVariables
	> &
		(
			| { variables: ListAreaDetailQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListAreaDetailQuery, ListAreaDetailQueryVariables>(
		ListAreaDetailDocument,
		options,
	)
}
export function useListAreaDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListAreaDetailQuery,
		ListAreaDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListAreaDetailQuery, ListAreaDetailQueryVariables>(
		ListAreaDetailDocument,
		options,
	)
}
export function useListAreaDetailSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListAreaDetailQuery,
				ListAreaDetailQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListAreaDetailQuery,
		ListAreaDetailQueryVariables
	>(ListAreaDetailDocument, options)
}
export type ListAreaDetailQueryHookResult = ReturnType<
	typeof useListAreaDetailQuery
>
export type ListAreaDetailLazyQueryHookResult = ReturnType<
	typeof useListAreaDetailLazyQuery
>
export type ListAreaDetailSuspenseQueryHookResult = ReturnType<
	typeof useListAreaDetailSuspenseQuery
>
export type ListAreaDetailQueryResult = Apollo.QueryResult<
	ListAreaDetailQuery,
	ListAreaDetailQueryVariables
>
export const ListAreasDocument = gql`
	query ListAreas($filter: AreasFilter) {
		areas(filter: $filter) {
			_key
			count
			nodes {
				_id
				name
			}
		}
	}
`

/**
 * __useListAreasQuery__
 *
 * To run a query within a React component, call `useListAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAreasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAreasQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListAreasQuery,
		ListAreasQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListAreasQuery, ListAreasQueryVariables>(
		ListAreasDocument,
		options,
	)
}
export function useListAreasLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListAreasQuery,
		ListAreasQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListAreasQuery, ListAreasQueryVariables>(
		ListAreasDocument,
		options,
	)
}
export function useListAreasSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<ListAreasQuery, ListAreasQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<ListAreasQuery, ListAreasQueryVariables>(
		ListAreasDocument,
		options,
	)
}
export type ListAreasQueryHookResult = ReturnType<typeof useListAreasQuery>
export type ListAreasLazyQueryHookResult = ReturnType<
	typeof useListAreasLazyQuery
>
export type ListAreasSuspenseQueryHookResult = ReturnType<
	typeof useListAreasSuspenseQuery
>
export type ListAreasQueryResult = Apollo.QueryResult<
	ListAreasQuery,
	ListAreasQueryVariables
>
export const ListClientsWithServicesDocument = gql`
	query ListClientsWithServices(
		$servicesFilter: ServicesFilter
		$clientsFilter: CompaniesFilter
	) {
		clients(filter: $clientsFilter) {
			_key
			nodes {
				...ClientFragment
				services(filter: $servicesFilter) {
					_key
					count
				}
			}
		}
	}
	${ClientFragmentFragmentDoc}
`

/**
 * __useListClientsWithServicesQuery__
 *
 * To run a query within a React component, call `useListClientsWithServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientsWithServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientsWithServicesQuery({
 *   variables: {
 *      servicesFilter: // value for 'servicesFilter'
 *      clientsFilter: // value for 'clientsFilter'
 *   },
 * });
 */
export function useListClientsWithServicesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListClientsWithServicesQuery,
		ListClientsWithServicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ListClientsWithServicesQuery,
		ListClientsWithServicesQueryVariables
	>(ListClientsWithServicesDocument, options)
}
export function useListClientsWithServicesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListClientsWithServicesQuery,
		ListClientsWithServicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ListClientsWithServicesQuery,
		ListClientsWithServicesQueryVariables
	>(ListClientsWithServicesDocument, options)
}
export function useListClientsWithServicesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListClientsWithServicesQuery,
				ListClientsWithServicesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListClientsWithServicesQuery,
		ListClientsWithServicesQueryVariables
	>(ListClientsWithServicesDocument, options)
}
export type ListClientsWithServicesQueryHookResult = ReturnType<
	typeof useListClientsWithServicesQuery
>
export type ListClientsWithServicesLazyQueryHookResult = ReturnType<
	typeof useListClientsWithServicesLazyQuery
>
export type ListClientsWithServicesSuspenseQueryHookResult = ReturnType<
	typeof useListClientsWithServicesSuspenseQuery
>
export type ListClientsWithServicesQueryResult = Apollo.QueryResult<
	ListClientsWithServicesQuery,
	ListClientsWithServicesQueryVariables
>
export const ListCompaniesDocument = gql`
	query ListCompanies($limit: Int, $offset: Int, $filter: CompaniesFilter) {
		clients(limit: $limit, offset: $offset, filter: $filter) {
			_key
			nodes {
				_id
				name
				taxId
				legalName
				taxSystem
				address {
					zipCode
				}
			}
		}
	}
`

/**
 * __useListCompaniesQuery__
 *
 * To run a query within a React component, call `useListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCompaniesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListCompaniesQuery,
		ListCompaniesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(
		ListCompaniesDocument,
		options,
	)
}
export function useListCompaniesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListCompaniesQuery,
		ListCompaniesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(
		ListCompaniesDocument,
		options,
	)
}
export function useListCompaniesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListCompaniesQuery,
				ListCompaniesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListCompaniesQuery,
		ListCompaniesQueryVariables
	>(ListCompaniesDocument, options)
}
export type ListCompaniesQueryHookResult = ReturnType<
	typeof useListCompaniesQuery
>
export type ListCompaniesLazyQueryHookResult = ReturnType<
	typeof useListCompaniesLazyQuery
>
export type ListCompaniesSuspenseQueryHookResult = ReturnType<
	typeof useListCompaniesSuspenseQuery
>
export type ListCompaniesQueryResult = Apollo.QueryResult<
	ListCompaniesQuery,
	ListCompaniesQueryVariables
>
export const ListCompanyFacilitiesDocument = gql`
	query ListCompanyFacilities($id: ID!) {
		client(id: $id) {
			_id
			role
			contacts {
				_key
				count
			}
			facilities {
				_key
				count
				nodes {
					_id
					name
				}
			}
		}
	}
`

/**
 * __useListCompanyFacilitiesQuery__
 *
 * To run a query within a React component, call `useListCompanyFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyFacilitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListCompanyFacilitiesQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListCompanyFacilitiesQuery,
		ListCompanyFacilitiesQueryVariables
	> &
		(
			| { variables: ListCompanyFacilitiesQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ListCompanyFacilitiesQuery,
		ListCompanyFacilitiesQueryVariables
	>(ListCompanyFacilitiesDocument, options)
}
export function useListCompanyFacilitiesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListCompanyFacilitiesQuery,
		ListCompanyFacilitiesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ListCompanyFacilitiesQuery,
		ListCompanyFacilitiesQueryVariables
	>(ListCompanyFacilitiesDocument, options)
}
export function useListCompanyFacilitiesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListCompanyFacilitiesQuery,
				ListCompanyFacilitiesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListCompanyFacilitiesQuery,
		ListCompanyFacilitiesQueryVariables
	>(ListCompanyFacilitiesDocument, options)
}
export type ListCompanyFacilitiesQueryHookResult = ReturnType<
	typeof useListCompanyFacilitiesQuery
>
export type ListCompanyFacilitiesLazyQueryHookResult = ReturnType<
	typeof useListCompanyFacilitiesLazyQuery
>
export type ListCompanyFacilitiesSuspenseQueryHookResult = ReturnType<
	typeof useListCompanyFacilitiesSuspenseQuery
>
export type ListCompanyFacilitiesQueryResult = Apollo.QueryResult<
	ListCompanyFacilitiesQuery,
	ListCompanyFacilitiesQueryVariables
>
export const ListContactsDocument = gql`
	query ListContacts(
		$limit: Int
		$offset: Int
		$filter: ContactsFilter
		$cFilter: CompaniesFilter
	) {
		contacts(limit: $limit, offset: $offset, filter: $filter) {
			_key
			nodes {
				...ContactFragment
			}
		}
		clients(filter: $cFilter) {
			_key
			nodes {
				_id
				name
			}
		}
	}
	${ContactFragmentFragmentDoc}
`

/**
 * __useListContactsQuery__
 *
 * To run a query within a React component, call `useListContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContactsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      cFilter: // value for 'cFilter'
 *   },
 * });
 */
export function useListContactsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListContactsQuery,
		ListContactsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListContactsQuery, ListContactsQueryVariables>(
		ListContactsDocument,
		options,
	)
}
export function useListContactsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListContactsQuery,
		ListContactsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListContactsQuery, ListContactsQueryVariables>(
		ListContactsDocument,
		options,
	)
}
export function useListContactsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListContactsQuery,
				ListContactsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<ListContactsQuery, ListContactsQueryVariables>(
		ListContactsDocument,
		options,
	)
}
export type ListContactsQueryHookResult = ReturnType<
	typeof useListContactsQuery
>
export type ListContactsLazyQueryHookResult = ReturnType<
	typeof useListContactsLazyQuery
>
export type ListContactsSuspenseQueryHookResult = ReturnType<
	typeof useListContactsSuspenseQuery
>
export type ListContactsQueryResult = Apollo.QueryResult<
	ListContactsQuery,
	ListContactsQueryVariables
>
export const ListEmployeeAndContactUsersDocument = gql`
	query ListEmployeeAndContactUsers(
		$eFilter: EmployeesFilter!
		$cFilter: ContactsFilter!
	) {
		employees(filter: $eFilter) {
			_key
			nodes {
				user {
					_id
					name
					surname
					photo {
						url
					}
				}
			}
		}
		contacts(filter: $cFilter) {
			_key
			nodes {
				user {
					_id
					name
					surname
					photo {
						url
					}
				}
			}
		}
	}
`

/**
 * __useListEmployeeAndContactUsersQuery__
 *
 * To run a query within a React component, call `useListEmployeeAndContactUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeAndContactUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeAndContactUsersQuery({
 *   variables: {
 *      eFilter: // value for 'eFilter'
 *      cFilter: // value for 'cFilter'
 *   },
 * });
 */
export function useListEmployeeAndContactUsersQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListEmployeeAndContactUsersQuery,
		ListEmployeeAndContactUsersQueryVariables
	> &
		(
			| { variables: ListEmployeeAndContactUsersQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ListEmployeeAndContactUsersQuery,
		ListEmployeeAndContactUsersQueryVariables
	>(ListEmployeeAndContactUsersDocument, options)
}
export function useListEmployeeAndContactUsersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListEmployeeAndContactUsersQuery,
		ListEmployeeAndContactUsersQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ListEmployeeAndContactUsersQuery,
		ListEmployeeAndContactUsersQueryVariables
	>(ListEmployeeAndContactUsersDocument, options)
}
export function useListEmployeeAndContactUsersSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListEmployeeAndContactUsersQuery,
				ListEmployeeAndContactUsersQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListEmployeeAndContactUsersQuery,
		ListEmployeeAndContactUsersQueryVariables
	>(ListEmployeeAndContactUsersDocument, options)
}
export type ListEmployeeAndContactUsersQueryHookResult = ReturnType<
	typeof useListEmployeeAndContactUsersQuery
>
export type ListEmployeeAndContactUsersLazyQueryHookResult = ReturnType<
	typeof useListEmployeeAndContactUsersLazyQuery
>
export type ListEmployeeAndContactUsersSuspenseQueryHookResult = ReturnType<
	typeof useListEmployeeAndContactUsersSuspenseQuery
>
export type ListEmployeeAndContactUsersQueryResult = Apollo.QueryResult<
	ListEmployeeAndContactUsersQuery,
	ListEmployeeAndContactUsersQueryVariables
>
export const ListEmployeeLocationsDocument = gql`
	query ListEmployeeLocations(
		$employeesFilter: EmployeesFilter
		$locationsFilter: LocationsFilter
	) {
		employees(filter: $employeesFilter) {
			_key
			nodes {
				_id
				user {
					_id
					name
					surname
					device
					photo {
						url
					}
					locations(filter: $locationsFilter) {
						nodes {
							coordinates
							at
						}
					}
				}
			}
		}
	}
`

/**
 * __useListEmployeeLocationsQuery__
 *
 * To run a query within a React component, call `useListEmployeeLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeLocationsQuery({
 *   variables: {
 *      employeesFilter: // value for 'employeesFilter'
 *      locationsFilter: // value for 'locationsFilter'
 *   },
 * });
 */
export function useListEmployeeLocationsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListEmployeeLocationsQuery,
		ListEmployeeLocationsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ListEmployeeLocationsQuery,
		ListEmployeeLocationsQueryVariables
	>(ListEmployeeLocationsDocument, options)
}
export function useListEmployeeLocationsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListEmployeeLocationsQuery,
		ListEmployeeLocationsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ListEmployeeLocationsQuery,
		ListEmployeeLocationsQueryVariables
	>(ListEmployeeLocationsDocument, options)
}
export function useListEmployeeLocationsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListEmployeeLocationsQuery,
				ListEmployeeLocationsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListEmployeeLocationsQuery,
		ListEmployeeLocationsQueryVariables
	>(ListEmployeeLocationsDocument, options)
}
export type ListEmployeeLocationsQueryHookResult = ReturnType<
	typeof useListEmployeeLocationsQuery
>
export type ListEmployeeLocationsLazyQueryHookResult = ReturnType<
	typeof useListEmployeeLocationsLazyQuery
>
export type ListEmployeeLocationsSuspenseQueryHookResult = ReturnType<
	typeof useListEmployeeLocationsSuspenseQuery
>
export type ListEmployeeLocationsQueryResult = Apollo.QueryResult<
	ListEmployeeLocationsQuery,
	ListEmployeeLocationsQueryVariables
>
export const ListEmployeesDocument = gql`
	query ListEmployees($limit: Int, $offset: Int, $filter: EmployeesFilter) {
		employees(limit: $limit, offset: $offset, filter: $filter) {
			_key
			nodes {
				...EmployeeFragment
			}
		}
	}
	${EmployeeFragmentFragmentDoc}
`

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListEmployeesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListEmployeesQuery,
		ListEmployeesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(
		ListEmployeesDocument,
		options,
	)
}
export function useListEmployeesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListEmployeesQuery,
		ListEmployeesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(
		ListEmployeesDocument,
		options,
	)
}
export function useListEmployeesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListEmployeesQuery,
				ListEmployeesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListEmployeesQuery,
		ListEmployeesQueryVariables
	>(ListEmployeesDocument, options)
}
export type ListEmployeesQueryHookResult = ReturnType<
	typeof useListEmployeesQuery
>
export type ListEmployeesLazyQueryHookResult = ReturnType<
	typeof useListEmployeesLazyQuery
>
export type ListEmployeesSuspenseQueryHookResult = ReturnType<
	typeof useListEmployeesSuspenseQuery
>
export type ListEmployeesQueryResult = Apollo.QueryResult<
	ListEmployeesQuery,
	ListEmployeesQueryVariables
>
export const ListFacilitiesDocument = gql`
	query ListFacilities($filter: FacilitiesFilter) {
		facilities(filter: $filter) {
			_key
			count
			nodes {
				_id
				name
			}
		}
	}
`

/**
 * __useListFacilitiesQuery__
 *
 * To run a query within a React component, call `useListFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFacilitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListFacilitiesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListFacilitiesQuery,
		ListFacilitiesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListFacilitiesQuery, ListFacilitiesQueryVariables>(
		ListFacilitiesDocument,
		options,
	)
}
export function useListFacilitiesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListFacilitiesQuery,
		ListFacilitiesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListFacilitiesQuery, ListFacilitiesQueryVariables>(
		ListFacilitiesDocument,
		options,
	)
}
export function useListFacilitiesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListFacilitiesQuery,
				ListFacilitiesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListFacilitiesQuery,
		ListFacilitiesQueryVariables
	>(ListFacilitiesDocument, options)
}
export type ListFacilitiesQueryHookResult = ReturnType<
	typeof useListFacilitiesQuery
>
export type ListFacilitiesLazyQueryHookResult = ReturnType<
	typeof useListFacilitiesLazyQuery
>
export type ListFacilitiesSuspenseQueryHookResult = ReturnType<
	typeof useListFacilitiesSuspenseQuery
>
export type ListFacilitiesQueryResult = Apollo.QueryResult<
	ListFacilitiesQuery,
	ListFacilitiesQueryVariables
>
export const ListFacilitiesUnitsDocument = gql`
	query ListFacilitiesUnits($filter: FacilitiesFilter) {
		facilities(filter: $filter) {
			_key
			count
			nodes {
				_id
				name
				units {
					_key
					count
					nodes {
						_id
						number
						type
						area {
							name
							isExterior
						}
					}
				}
			}
		}
	}
`

/**
 * __useListFacilitiesUnitsQuery__
 *
 * To run a query within a React component, call `useListFacilitiesUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFacilitiesUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFacilitiesUnitsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListFacilitiesUnitsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListFacilitiesUnitsQuery,
		ListFacilitiesUnitsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ListFacilitiesUnitsQuery,
		ListFacilitiesUnitsQueryVariables
	>(ListFacilitiesUnitsDocument, options)
}
export function useListFacilitiesUnitsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListFacilitiesUnitsQuery,
		ListFacilitiesUnitsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ListFacilitiesUnitsQuery,
		ListFacilitiesUnitsQueryVariables
	>(ListFacilitiesUnitsDocument, options)
}
export function useListFacilitiesUnitsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListFacilitiesUnitsQuery,
				ListFacilitiesUnitsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListFacilitiesUnitsQuery,
		ListFacilitiesUnitsQueryVariables
	>(ListFacilitiesUnitsDocument, options)
}
export type ListFacilitiesUnitsQueryHookResult = ReturnType<
	typeof useListFacilitiesUnitsQuery
>
export type ListFacilitiesUnitsLazyQueryHookResult = ReturnType<
	typeof useListFacilitiesUnitsLazyQuery
>
export type ListFacilitiesUnitsSuspenseQueryHookResult = ReturnType<
	typeof useListFacilitiesUnitsSuspenseQuery
>
export type ListFacilitiesUnitsQueryResult = Apollo.QueryResult<
	ListFacilitiesUnitsQuery,
	ListFacilitiesUnitsQueryVariables
>
export const ListFilesDocument = gql`
	query ListFiles($filter: FilesFilter!) {
		files(filter: $filter) {
			_key
			nodes {
				...FileFragment
			}
		}
	}
	${FileFragmentFragmentDoc}
`

/**
 * __useListFilesQuery__
 *
 * To run a query within a React component, call `useListFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListFilesQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListFilesQuery,
		ListFilesQueryVariables
	> &
		(
			| { variables: ListFilesQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListFilesQuery, ListFilesQueryVariables>(
		ListFilesDocument,
		options,
	)
}
export function useListFilesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListFilesQuery,
		ListFilesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListFilesQuery, ListFilesQueryVariables>(
		ListFilesDocument,
		options,
	)
}
export function useListFilesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<ListFilesQuery, ListFilesQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<ListFilesQuery, ListFilesQueryVariables>(
		ListFilesDocument,
		options,
	)
}
export type ListFilesQueryHookResult = ReturnType<typeof useListFilesQuery>
export type ListFilesLazyQueryHookResult = ReturnType<
	typeof useListFilesLazyQuery
>
export type ListFilesSuspenseQueryHookResult = ReturnType<
	typeof useListFilesSuspenseQuery
>
export type ListFilesQueryResult = Apollo.QueryResult<
	ListFilesQuery,
	ListFilesQueryVariables
>
export const ListInvoicesDocument = gql`
	query ListInvoices(
		$filter: InvoicesFilter
		$sort: InvoicesSort
		$limit: Int
	) {
		invoices(filter: $filter, sort: $sort, limit: $limit) {
			_key
			count
			nodes {
				...InvoiceFragment
			}
		}
	}
	${InvoiceFragmentFragmentDoc}
`

/**
 * __useListInvoicesQuery__
 *
 * To run a query within a React component, call `useListInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListInvoicesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListInvoicesQuery,
		ListInvoicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListInvoicesQuery, ListInvoicesQueryVariables>(
		ListInvoicesDocument,
		options,
	)
}
export function useListInvoicesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListInvoicesQuery,
		ListInvoicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListInvoicesQuery, ListInvoicesQueryVariables>(
		ListInvoicesDocument,
		options,
	)
}
export function useListInvoicesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListInvoicesQuery,
				ListInvoicesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<ListInvoicesQuery, ListInvoicesQueryVariables>(
		ListInvoicesDocument,
		options,
	)
}
export type ListInvoicesQueryHookResult = ReturnType<
	typeof useListInvoicesQuery
>
export type ListInvoicesLazyQueryHookResult = ReturnType<
	typeof useListInvoicesLazyQuery
>
export type ListInvoicesSuspenseQueryHookResult = ReturnType<
	typeof useListInvoicesSuspenseQuery
>
export type ListInvoicesQueryResult = Apollo.QueryResult<
	ListInvoicesQuery,
	ListInvoicesQueryVariables
>
export const ListMessageTemplatesDocument = gql`
	query ListMessageTemplates {
		messageTemplates {
			_key
			nodes {
				_id
				name
				body
				createdAt
				createdBy {
					_id
					name
				}
			}
		}
	}
`

/**
 * __useListMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useListMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessageTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMessageTemplatesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListMessageTemplatesQuery,
		ListMessageTemplatesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ListMessageTemplatesQuery,
		ListMessageTemplatesQueryVariables
	>(ListMessageTemplatesDocument, options)
}
export function useListMessageTemplatesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListMessageTemplatesQuery,
		ListMessageTemplatesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ListMessageTemplatesQuery,
		ListMessageTemplatesQueryVariables
	>(ListMessageTemplatesDocument, options)
}
export function useListMessageTemplatesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListMessageTemplatesQuery,
				ListMessageTemplatesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		ListMessageTemplatesQuery,
		ListMessageTemplatesQueryVariables
	>(ListMessageTemplatesDocument, options)
}
export type ListMessageTemplatesQueryHookResult = ReturnType<
	typeof useListMessageTemplatesQuery
>
export type ListMessageTemplatesLazyQueryHookResult = ReturnType<
	typeof useListMessageTemplatesLazyQuery
>
export type ListMessageTemplatesSuspenseQueryHookResult = ReturnType<
	typeof useListMessageTemplatesSuspenseQuery
>
export type ListMessageTemplatesQueryResult = Apollo.QueryResult<
	ListMessageTemplatesQuery,
	ListMessageTemplatesQueryVariables
>
export const GetProductsDocument = gql`
	query getProducts($filter: ProductsFilter) {
		products(filter: $filter) {
			_key
			nodes {
				...ProductFragment
			}
		}
	}
	${ProductFragmentFragmentDoc}
`

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetProductsQuery,
		GetProductsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(
		GetProductsDocument,
		options,
	)
}
export function useGetProductsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetProductsQuery,
		GetProductsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
		GetProductsDocument,
		options,
	)
}
export function useGetProductsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetProductsQuery,
				GetProductsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetProductsQuery, GetProductsQueryVariables>(
		GetProductsDocument,
		options,
	)
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>
export type GetProductsLazyQueryHookResult = ReturnType<
	typeof useGetProductsLazyQuery
>
export type GetProductsSuspenseQueryHookResult = ReturnType<
	typeof useGetProductsSuspenseQuery
>
export type GetProductsQueryResult = Apollo.QueryResult<
	GetProductsQuery,
	GetProductsQueryVariables
>
export const ListServicesDocument = gql`
	query ListServices(
		$filter: ServicesFilter
		$limit: Int
		$sort: ServicesSort
		$hasAnalytics: Boolean!
	) {
		services(filter: $filter, limit: $limit, sort: $sort) {
			_key
			count
			nodes {
				...ServiceFragment
				completionTime
				estimatedCompletionTime @include(if: $hasAnalytics)
				tags {
					_key
					nodes {
						...TagFragment
					}
				}
			}
		}
	}
	${ServiceFragmentFragmentDoc}
	${TagFragmentFragmentDoc}
`

/**
 * __useListServicesQuery__
 *
 * To run a query within a React component, call `useListServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListServicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      hasAnalytics: // value for 'hasAnalytics'
 *   },
 * });
 */
export function useListServicesQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListServicesQuery,
		ListServicesQueryVariables
	> &
		(
			| { variables: ListServicesQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListServicesQuery, ListServicesQueryVariables>(
		ListServicesDocument,
		options,
	)
}
export function useListServicesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListServicesQuery,
		ListServicesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListServicesQuery, ListServicesQueryVariables>(
		ListServicesDocument,
		options,
	)
}
export function useListServicesSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				ListServicesQuery,
				ListServicesQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<ListServicesQuery, ListServicesQueryVariables>(
		ListServicesDocument,
		options,
	)
}
export type ListServicesQueryHookResult = ReturnType<
	typeof useListServicesQuery
>
export type ListServicesLazyQueryHookResult = ReturnType<
	typeof useListServicesLazyQuery
>
export type ListServicesSuspenseQueryHookResult = ReturnType<
	typeof useListServicesSuspenseQuery
>
export type ListServicesQueryResult = Apollo.QueryResult<
	ListServicesQuery,
	ListServicesQueryVariables
>
export const ListTagsDocument = gql`
	query ListTags($filter: TagsFilter) {
		tags(filter: $filter) {
			_key
			nodes {
				...TagFragment
			}
		}
	}
	${TagFragmentFragmentDoc}
`

/**
 * __useListTagsQuery__
 *
 * To run a query within a React component, call `useListTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListTagsQuery(
	baseOptions?: Apollo.QueryHookOptions<ListTagsQuery, ListTagsQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ListTagsQuery, ListTagsQueryVariables>(
		ListTagsDocument,
		options,
	)
}
export function useListTagsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListTagsQuery,
		ListTagsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ListTagsQuery, ListTagsQueryVariables>(
		ListTagsDocument,
		options,
	)
}
export function useListTagsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<ListTagsQuery, ListTagsQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<ListTagsQuery, ListTagsQueryVariables>(
		ListTagsDocument,
		options,
	)
}
export type ListTagsQueryHookResult = ReturnType<typeof useListTagsQuery>
export type ListTagsLazyQueryHookResult = ReturnType<
	typeof useListTagsLazyQuery
>
export type ListTagsSuspenseQueryHookResult = ReturnType<
	typeof useListTagsSuspenseQuery
>
export type ListTagsQueryResult = Apollo.QueryResult<
	ListTagsQuery,
	ListTagsQueryVariables
>
export const GetSelfAndContactDocument = gql`
	query GetSelfAndContact {
		self {
			...UserFragment
			contact {
				...ClientContactFragment
			}
		}
	}
	${UserFragmentFragmentDoc}
	${ClientContactFragmentFragmentDoc}
`

/**
 * __useGetSelfAndContactQuery__
 *
 * To run a query within a React component, call `useGetSelfAndContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAndContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAndContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfAndContactQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetSelfAndContactQuery,
		GetSelfAndContactQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetSelfAndContactQuery,
		GetSelfAndContactQueryVariables
	>(GetSelfAndContactDocument, options)
}
export function useGetSelfAndContactLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetSelfAndContactQuery,
		GetSelfAndContactQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetSelfAndContactQuery,
		GetSelfAndContactQueryVariables
	>(GetSelfAndContactDocument, options)
}
export function useGetSelfAndContactSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetSelfAndContactQuery,
				GetSelfAndContactQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetSelfAndContactQuery,
		GetSelfAndContactQueryVariables
	>(GetSelfAndContactDocument, options)
}
export type GetSelfAndContactQueryHookResult = ReturnType<
	typeof useGetSelfAndContactQuery
>
export type GetSelfAndContactLazyQueryHookResult = ReturnType<
	typeof useGetSelfAndContactLazyQuery
>
export type GetSelfAndContactSuspenseQueryHookResult = ReturnType<
	typeof useGetSelfAndContactSuspenseQuery
>
export type GetSelfAndContactQueryResult = Apollo.QueryResult<
	GetSelfAndContactQuery,
	GetSelfAndContactQueryVariables
>
export const CreateUnitDocument = gql`
	mutation CreateUnit($input: UnitInput!) {
		unit(input: $input) {
			...FacilityUnitFragment
		}
	}
	${FacilityUnitFragmentFragmentDoc}
`
export type CreateUnitMutationFn = Apollo.MutationFunction<
	CreateUnitMutation,
	CreateUnitMutationVariables
>

/**
 * __useCreateUnitMutation__
 *
 * To run a mutation, you first call `useCreateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitMutation, { data, loading, error }] = useCreateUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnitMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateUnitMutation,
		CreateUnitMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreateUnitMutation, CreateUnitMutationVariables>(
		CreateUnitDocument,
		options,
	)
}
export type CreateUnitMutationHookResult = ReturnType<
	typeof useCreateUnitMutation
>
export type CreateUnitMutationResult = Apollo.MutationResult<CreateUnitMutation>
export type CreateUnitMutationOptions = Apollo.BaseMutationOptions<
	CreateUnitMutation,
	CreateUnitMutationVariables
>
export const GetFacilityAreasDocument = gql`
	query GetFacilityAreas($facility: ID!) {
		facility(id: $facility) {
			_id
			areas {
				_key
				nodes {
					_id
					name
					type
					isExterior
				}
			}
			company {
				_id
			}
		}
	}
`

/**
 * __useGetFacilityAreasQuery__
 *
 * To run a query within a React component, call `useGetFacilityAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityAreasQuery({
 *   variables: {
 *      facility: // value for 'facility'
 *   },
 * });
 */
export function useGetFacilityAreasQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetFacilityAreasQuery,
		GetFacilityAreasQueryVariables
	> &
		(
			| { variables: GetFacilityAreasQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetFacilityAreasQuery, GetFacilityAreasQueryVariables>(
		GetFacilityAreasDocument,
		options,
	)
}
export function useGetFacilityAreasLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFacilityAreasQuery,
		GetFacilityAreasQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetFacilityAreasQuery,
		GetFacilityAreasQueryVariables
	>(GetFacilityAreasDocument, options)
}
export function useGetFacilityAreasSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetFacilityAreasQuery,
				GetFacilityAreasQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetFacilityAreasQuery,
		GetFacilityAreasQueryVariables
	>(GetFacilityAreasDocument, options)
}
export type GetFacilityAreasQueryHookResult = ReturnType<
	typeof useGetFacilityAreasQuery
>
export type GetFacilityAreasLazyQueryHookResult = ReturnType<
	typeof useGetFacilityAreasLazyQuery
>
export type GetFacilityAreasSuspenseQueryHookResult = ReturnType<
	typeof useGetFacilityAreasSuspenseQuery
>
export type GetFacilityAreasQueryResult = Apollo.QueryResult<
	GetFacilityAreasQuery,
	GetFacilityAreasQueryVariables
>
export const CreateEmployeeDocument = gql`
	mutation CreateEmployee($input: EmployeeInput!) {
		employee(input: $input) {
			...EmployeeFragment
		}
	}
	${EmployeeFragmentFragmentDoc}
`
export type CreateEmployeeMutationFn = Apollo.MutationFunction<
	CreateEmployeeMutation,
	CreateEmployeeMutationVariables
>

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateEmployeeMutation,
		CreateEmployeeMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateEmployeeMutation,
		CreateEmployeeMutationVariables
	>(CreateEmployeeDocument, options)
}
export type CreateEmployeeMutationHookResult = ReturnType<
	typeof useCreateEmployeeMutation
>
export type CreateEmployeeMutationResult =
	Apollo.MutationResult<CreateEmployeeMutation>
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<
	CreateEmployeeMutation,
	CreateEmployeeMutationVariables
>
export const CreateServiceDocument = gql`
	mutation CreateService($input: ServiceInput!) {
		services: service(input: $input) {
			...ServiceFragment
		}
	}
	${ServiceFragmentFragmentDoc}
`
export type CreateServiceMutationFn = Apollo.MutationFunction<
	CreateServiceMutation,
	CreateServiceMutationVariables
>

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateServiceMutation,
		CreateServiceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateServiceMutation,
		CreateServiceMutationVariables
	>(CreateServiceDocument, options)
}
export type CreateServiceMutationHookResult = ReturnType<
	typeof useCreateServiceMutation
>
export type CreateServiceMutationResult =
	Apollo.MutationResult<CreateServiceMutation>
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<
	CreateServiceMutation,
	CreateServiceMutationVariables
>
export const GetTransportOptionsDocument = gql`
	query GetTransportOptions($clientsFilter: CompaniesFilter) {
		transports {
			_key
			nodes {
				_id
				name
				cost
			}
		}
	}
`

/**
 * __useGetTransportOptionsQuery__
 *
 * To run a query within a React component, call `useGetTransportOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportOptionsQuery({
 *   variables: {
 *      clientsFilter: // value for 'clientsFilter'
 *   },
 * });
 */
export function useGetTransportOptionsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetTransportOptionsQuery,
		GetTransportOptionsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetTransportOptionsQuery,
		GetTransportOptionsQueryVariables
	>(GetTransportOptionsDocument, options)
}
export function useGetTransportOptionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetTransportOptionsQuery,
		GetTransportOptionsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetTransportOptionsQuery,
		GetTransportOptionsQueryVariables
	>(GetTransportOptionsDocument, options)
}
export function useGetTransportOptionsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetTransportOptionsQuery,
				GetTransportOptionsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetTransportOptionsQuery,
		GetTransportOptionsQueryVariables
	>(GetTransportOptionsDocument, options)
}
export type GetTransportOptionsQueryHookResult = ReturnType<
	typeof useGetTransportOptionsQuery
>
export type GetTransportOptionsLazyQueryHookResult = ReturnType<
	typeof useGetTransportOptionsLazyQuery
>
export type GetTransportOptionsSuspenseQueryHookResult = ReturnType<
	typeof useGetTransportOptionsSuspenseQuery
>
export type GetTransportOptionsQueryResult = Apollo.QueryResult<
	GetTransportOptionsQuery,
	GetTransportOptionsQueryVariables
>
export const PlaguePresenceDocument = gql`
	query plaguePresence(
		$options: PlaguePresenceOptions
		$filter: PlaguePresenceFilter!
	) {
		plaguePresence(options: $options, filter: $filter) {
			_key
			nodes {
				date
				plagueCount {
					plagueType
					count
				}
			}
		}
	}
`

/**
 * __usePlaguePresenceQuery__
 *
 * To run a query within a React component, call `usePlaguePresenceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaguePresenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaguePresenceQuery({
 *   variables: {
 *      options: // value for 'options'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePlaguePresenceQuery(
	baseOptions: Apollo.QueryHookOptions<
		PlaguePresenceQuery,
		PlaguePresenceQueryVariables
	> &
		(
			| { variables: PlaguePresenceQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<PlaguePresenceQuery, PlaguePresenceQueryVariables>(
		PlaguePresenceDocument,
		options,
	)
}
export function usePlaguePresenceLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		PlaguePresenceQuery,
		PlaguePresenceQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<PlaguePresenceQuery, PlaguePresenceQueryVariables>(
		PlaguePresenceDocument,
		options,
	)
}
export function usePlaguePresenceSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				PlaguePresenceQuery,
				PlaguePresenceQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		PlaguePresenceQuery,
		PlaguePresenceQueryVariables
	>(PlaguePresenceDocument, options)
}
export type PlaguePresenceQueryHookResult = ReturnType<
	typeof usePlaguePresenceQuery
>
export type PlaguePresenceLazyQueryHookResult = ReturnType<
	typeof usePlaguePresenceLazyQuery
>
export type PlaguePresenceSuspenseQueryHookResult = ReturnType<
	typeof usePlaguePresenceSuspenseQuery
>
export type PlaguePresenceQueryResult = Apollo.QueryResult<
	PlaguePresenceQuery,
	PlaguePresenceQueryVariables
>
export const GenerateReportDocument = gql`
	mutation GenerateReport($input: SendReportInput!) {
		generated: sendReport(input: $input) {
			url
			key
		}
	}
`
export type GenerateReportMutationFn = Apollo.MutationFunction<
	GenerateReportMutation,
	GenerateReportMutationVariables
>

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReportMutation(
	baseOptions?: Apollo.MutationHookOptions<
		GenerateReportMutation,
		GenerateReportMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		GenerateReportMutation,
		GenerateReportMutationVariables
	>(GenerateReportDocument, options)
}
export type GenerateReportMutationHookResult = ReturnType<
	typeof useGenerateReportMutation
>
export type GenerateReportMutationResult =
	Apollo.MutationResult<GenerateReportMutation>
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<
	GenerateReportMutation,
	GenerateReportMutationVariables
>
export const GetTransportInputOptionsDocument = gql`
	query GetTransportInputOptions(
		$employeesFilter: EmployeesFilter
		$clientsFilter: CompaniesFilter
	) {
		products {
			_key
			nodes {
				_id
				name
				applications {
					dosis
					method
					throwput
				}
			}
		}
		clients(filter: $clientsFilter) {
			_key
			nodes {
				_id
				name
			}
		}
		employees(filter: $employeesFilter) {
			_key
			nodes {
				...EmployeeFragment
			}
		}
		transports {
			_key
			nodes {
				_id
				name
				cost
				surfaceArea
			}
		}
	}
	${EmployeeFragmentFragmentDoc}
`

/**
 * __useGetTransportInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetTransportInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportInputOptionsQuery({
 *   variables: {
 *      employeesFilter: // value for 'employeesFilter'
 *      clientsFilter: // value for 'clientsFilter'
 *   },
 * });
 */
export function useGetTransportInputOptionsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetTransportInputOptionsQuery,
		GetTransportInputOptionsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetTransportInputOptionsQuery,
		GetTransportInputOptionsQueryVariables
	>(GetTransportInputOptionsDocument, options)
}
export function useGetTransportInputOptionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetTransportInputOptionsQuery,
		GetTransportInputOptionsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetTransportInputOptionsQuery,
		GetTransportInputOptionsQueryVariables
	>(GetTransportInputOptionsDocument, options)
}
export function useGetTransportInputOptionsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetTransportInputOptionsQuery,
				GetTransportInputOptionsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<
		GetTransportInputOptionsQuery,
		GetTransportInputOptionsQueryVariables
	>(GetTransportInputOptionsDocument, options)
}
export type GetTransportInputOptionsQueryHookResult = ReturnType<
	typeof useGetTransportInputOptionsQuery
>
export type GetTransportInputOptionsLazyQueryHookResult = ReturnType<
	typeof useGetTransportInputOptionsLazyQuery
>
export type GetTransportInputOptionsSuspenseQueryHookResult = ReturnType<
	typeof useGetTransportInputOptionsSuspenseQuery
>
export type GetTransportInputOptionsQueryResult = Apollo.QueryResult<
	GetTransportInputOptionsQuery,
	GetTransportInputOptionsQueryVariables
>
export const CreateRoleDocument = gql`
	mutation CreateRole($input: RoleInput!) {
		role(input: $input) {
			...RoleFragment
		}
	}
	${RoleFragmentFragmentDoc}
`
export type CreateRoleMutationFn = Apollo.MutationFunction<
	CreateRoleMutation,
	CreateRoleMutationVariables
>

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateRoleMutation,
		CreateRoleMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
		CreateRoleDocument,
		options,
	)
}
export type CreateRoleMutationHookResult = ReturnType<
	typeof useCreateRoleMutation
>
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
	CreateRoleMutation,
	CreateRoleMutationVariables
>
export const UpdateRoleDocument = gql`
	mutation UpdateRole($id: ID!, $input: UpdateRoleInput!) {
		role: updateRole(id: $id, input: $input) {
			...RoleFragment
		}
	}
	${RoleFragmentFragmentDoc}
`
export type UpdateRoleMutationFn = Apollo.MutationFunction<
	UpdateRoleMutation,
	UpdateRoleMutationVariables
>

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateRoleMutation,
		UpdateRoleMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
		UpdateRoleDocument,
		options,
	)
}
export type UpdateRoleMutationHookResult = ReturnType<
	typeof useUpdateRoleMutation
>
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
	UpdateRoleMutation,
	UpdateRoleMutationVariables
>
export const GetUnitDocument = gql`
	query getUnit($id: ID!) {
		unit(id: $id) {
			...UnitFragment
		}
	}
	${UnitFragmentFragmentDoc}
`

/**
 * __useGetUnitQuery__
 *
 * To run a query within a React component, call `useGetUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitQuery(
	baseOptions: Apollo.QueryHookOptions<GetUnitQuery, GetUnitQueryVariables> &
		({ variables: GetUnitQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetUnitQuery, GetUnitQueryVariables>(
		GetUnitDocument,
		options,
	)
}
export function useGetUnitLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetUnitQuery,
		GetUnitQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetUnitQuery, GetUnitQueryVariables>(
		GetUnitDocument,
		options,
	)
}
export function useGetUnitSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetUnitQuery, GetUnitQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken
			? baseOptions
			: { ...defaultOptions, ...baseOptions }
	return Apollo.useSuspenseQuery<GetUnitQuery, GetUnitQueryVariables>(
		GetUnitDocument,
		options,
	)
}
export type GetUnitQueryHookResult = ReturnType<typeof useGetUnitQuery>
export type GetUnitLazyQueryHookResult = ReturnType<typeof useGetUnitLazyQuery>
export type GetUnitSuspenseQueryHookResult = ReturnType<
	typeof useGetUnitSuspenseQuery
>
export type GetUnitQueryResult = Apollo.QueryResult<
	GetUnitQuery,
	GetUnitQueryVariables
>
