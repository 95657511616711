import { lazy, Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

const Login = lazy(() => import('@/containers/Login'))
const SignUp = lazy(() => import('@/containers/SignUp'))
const Uninstall = lazy(() => import('@/containers/Uninstall'))

const Renew = lazy(() => import('@/containers/Renew'))

import AppSkeleton from '@/modules/AppSkeleton'

import { useNotify } from '@/utils/context'

import ProtectedRoutes from './ProtectedRoutes'

function useNotifyConnectionStatus() {
	const NOTIFICATION_DURATION = 6 // seconds

	const notify = useNotify()
	const { t } = useTranslation()

	useEffect(() => {
		if (window.navigator.onLine === false) {
			notify.banner(
				t('connection.noConnection'),
				t('connection.noConnection.message'),
				{ duration: NOTIFICATION_DURATION },
			)
		}

		window.addEventListener('online', () =>
			notify.banner(
				t('connection.restoredConnection'),
				t('connection.restoredConnection.message'),
			),
		)
		window.addEventListener('offline', () =>
			notify.banner(
				t('connection.lostConnection'),
				t('connection.lostConnection.message'),
			),
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t])
}

export default function Router() {
	useNotifyConnectionStatus()

	return (
		<Suspense fallback={<AppSkeleton />}>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/auth/sign-in" element={<Login />} />
				<Route path="/renew" element={<Renew />} />
				<Route path="/magic" element={<Login />} />
				<Route path="/reset" element={<Login />} />
				<Route path="/forgot" element={<Login />} />
				<Route path="/sign-up" element={<SignUp />} />
				<Route path="/forgot-org" element={<Login />} />

				{/* By having the '_' prefix this route won't be cached by the SW */}
				<Route path="/_uninstall" element={<Uninstall />} />

				<Route path="*" element={<ProtectedRoutes />} />
			</Routes>
		</Suspense>
	)
}
