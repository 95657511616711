/* eslint-disable no-magic-numbers */
import ADMIN_MODULES from './ADMIN_MODULES.json'
import ALL_FILES from './ALL_FILES.json'
import { AREA_CATEGORIES, AREA_TYPES } from './areas'
import { CLIENT_TYPES } from './clients'
import COUNTRY_CODES from './COUNTRY_CODES.json'
import { ISSUE_PRIORITY, ISSUE_STATUS, ISSUE_TYPES } from './issues'
import SERVICE_FREQUENCIES from './SERVICE_FREQUENCIES.json'
import SERVICES_TYPES from './SERVICES_TYPES.json'
import SERVICES_TYPES_GROUPED from './SERVICES_TYPES_GROUPED.json'
import { SITES_TYPES } from './sites'
import UNIT_TYPES_GROUPED from './UNIT_TYPES_GROUPED.json'
import USER_MODULES from './USER_MODULES.json'

export {
	SERVICES_TYPES_GROUPED,
	ADMIN_MODULES,
	UNIT_TYPES_GROUPED,
	USER_MODULES,
	ALL_FILES,
	AREA_TYPES,
	SERVICE_FREQUENCIES,
	AREA_CATEGORIES,
	SERVICES_TYPES,
	CLIENT_TYPES,
	SITES_TYPES,
	ISSUE_TYPES,
	ISSUE_PRIORITY,
	ISSUE_STATUS,
	COUNTRY_CODES,
}

export enum modules {
	clients = 'clients',
	insights = 'insights',
	contacts = 'contacts',
	services = 'services',
	facilities = 'facilities',
	share = 'share',
	links = 'links',
	map = 'map',
	employees = 'employees',
	employee = 'employee',
	products = 'products',
	quotes = 'quotes',
	settings = 'settings',
	resources = 'resources',
	transports = 'transports',
	transactions = 'transactions',
	notifications = 'notifications',
	invoices = 'invoices',
	issues = 'issues',
	payments = 'payments',
}

export const modals = modules

export enum flags {
	hasLayout = 'hasLayout',
	hasClientPortal = 'hasClientPortal',
	hasMagicLink = 'hasMagicLink',
	hasAnalytics = 'hasAnalytics',
}

export enum AREA_TYPE_ENUM {
	Rodents,
	Insects,
	Birds,
	WildLife,
	UVA,
}

export enum SERVICE_TYPE_ENUM {
	Transports,
	RodentsControl,
	InsectsControl,
	BirdsControl,
	WildLifeControl,
	RodentsMonitoring,
	InsectsMonitoring,
	BirdsMonitoring,
	WildLifeMonitoring,
	RiskAnalysis,
	Disinfection,
	UVATubesChange,
	UltrasonicFrequencyChange,
	ServiceSupervision,
}

export const GENERAL_UNIT_STATUSES = ['0', '2', 'E', 'F'] as const

export const SPECIFIC_UNIT_STATUSES = [
	'00',
	'01',
	'02',
	'03',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'A0',
	'A1',
	'B0',
] as const

export const ORGANISMS = [
	'000',
	'300',
	'301',
	'302',
	'303',
	'304',
	'305',
	'306',
	'307',
	'308',
	'309',
	'30A',
	'30B',
	'30C',
	'30D',
	'30E',
	'30G',
	'30H',
	'30I',
	'30J',
	'30K',
	'30L',
	'30M',
	'30N',
	'30O',
	'30P',
	'30Q',
	'H00',
	'H01',
	'H02',
	'H03',
	'H04',
	'H06',
	'H07',
	'H08',
	'H09',
	'X00',
] as const

export const UNIT_TYPES = {
	'000': { name: 'Side Kick' },
	'001': { name: 'Protecta' },
	'002': { name: 'Protecta LP' },
	'003': { name: 'Trapper 24/7' },
	'004': { name: 'Triángulo' },
	'005': { name: 'Stick All' },
	'006': { name: 'Rock' },
	'007': { name: 'Snap Trap' },
	'008': { name: 'Monitor' },
	'009': { name: 'Protecta Ambush' },
	'010': { name: 'Mouse Master' },
	'011': { name: 'T-Rex' },
	'012': { name: 'Aegis-PP' },
	'022': { name: 'Catch Mouse' },

	'700': { name: 'Falso búho (con alas)' },
	'701': { name: 'Falso búho (oservador)' },
	'702': { name: 'Falso búho (giratorio)' },
	'703': { name: 'X Peller Pro' },
	'704': { name: 'X Peller Pro 4' },
	'705': { name: 'QV4' },
	'706': { name: 'Terror eyes' },
	'707': { name: 'Bird' },

	'400': { name: 'Vector clásico' },
	'401': { name: 'Plasma' },
	'402': { name: 'Plasma 1' },
	'403': { name: 'Mantis 1x2' },
	'404': { name: 'Mantis 2x2' },
	'405': { name: 'Up Light' },
	'406': { name: 'Viper Cobra' },
	'407': { name: 'ILLUME' },
	'408': { name: 'LED' },
} as const

export enum SERVICES {
	RODENTS_CONTROL = 1,
	INSECTS_CONTROL = 2,
	BIRDS_CONTROL = 3,
	FAUNA_CONTROL = 4,
	RODENTS_MONITORING = 5,
	INSECTS_MONITORING = 6,
	BIRDS_MONITORING = 7,
	FAUNA_MONITORING = 8,
	RISK_ANALYSIS = 9,
	DISINFECTION = 10,
	UVA_LIGHTS_CHANGE = 11,
	ULTRASONIC_FREQUENCY_CHANGE = 12,
	SUPERVISION = 13,
}

export const JWT_NAMESPACE = 'https://cretia.app/jwt/claims'

export const POSTHOG_PROJECT_ID =
	'phc_qPGUNsJwfKESgzm4xwRih9bgsAWCTRccCCGTZovgO3e'
export const POSTHOG_API_HOST = 'https://app.posthog.com'

// Local Storage keys
export const LANGUAGE_KEY = 'i18nextLng'
export const ORG_KEY = '__org'

export const IS_CYPRESS =
	typeof window !== 'undefined' && (window as any).Cypress

export const GOOGLE_MAPS_TILES = {
	STREET: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
	HYBRID: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
	SATELLITE: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
	TERRAIN: 'https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
}

export const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
		),
)

export const serviceHumanDate = {
	day: 'numeric',
	month: 'long',
} as Intl.DateTimeFormatOptions

export const UNLAYER_PROJECT_ID = 33351

export const currencyFormat = new Intl.NumberFormat('es-MX', {
	style: 'currency',
	currency: 'MXN',
})

export const RESOURCES_ENDPOINT = `${(
	import.meta.env.VITE_APP_GRAPHQL_URI ?? ''
).replace('graphql', '')}v1/resources` as const

export const PDFJS_WORKER_VERSION = '3.11.174'

export const PDF_WORKER_URL = `https://unpkg.com/pdfjs-dist@${PDFJS_WORKER_VERSION}/build/pdf.worker.min.js`
