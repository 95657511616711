import { useEffect } from 'react'
import type { ApolloError } from '@apollo/client'
import { useReactiveVar } from '@apollo/client'
import { Button, Flex, Pill, TextInput } from '@cretia/components'
import { captureException } from '@sentry/react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { AppVersion } from '@/containers/Login/styled'

import { isDevelopment, isTesting } from '@/utils'
import { updateAvailableVar } from '@/utils/apollo/cache'
import { useNotify } from '@/utils/context'
import { FORBIDDEN, NOT_FOUND } from '@/utils/serverErrors'

import errorRat from './error-rat.png'
import * as Styles from './styles'

type Props = {
	fullSize?: boolean
	error?: ApolloError | Error | undefined
}

const ORGANIZATION_SLUG = 'fucesa'
const PROJECT_SLUG = 'client'

function AppError(props: Props) {
	const { fullSize, error } = props

	const { t } = useTranslation()
	const updateStatus = useReactiveVar(updateAvailableVar)
	const notify = useNotify()

	const formik = useFormik({
		initialValues: {
			event_id: '',
			comments: '',
		},
		onSubmit(values) {
			formik.resetForm()

			fetch(
				`https://sentry.io/api/0/projects/${ORGANIZATION_SLUG}/${PROJECT_SLUG}/user-feedback/`,
				{
					headers: {
						Authorization: `Bearer 50836a72c855418eaef7e281a6cf1feb6d9a3966f9d6455c9d6f3946b365fb53`,
					},
					method: 'POST',
					body: JSON.stringify(values),
				},
			)
				.then((res) => res.json())
				.finally(() => {
					notify.banner(t`thankYou`, t`commentsSent`)
				})
		},
	})

	const isOldVersion =
		updateStatus === 'newContent' || updateStatus === 'waiting'
	const isForbidden = (error as any)?.graphQLErrors?.some(
		({ extensions: { code } }: any) => code === FORBIDDEN,
	)
	const isNotFound = (error as any)?.graphQLErrors?.some(
		({ extensions: { code } }: any) => code === NOT_FOUND,
	)
	const isServer = !isForbidden && !isNotFound

	let errorKey = 'server'
	if (isForbidden) {
		errorKey = 'forbidden'
	} else if (isNotFound) {
		errorKey = 'notFound'
	} else if (isOldVersion) {
		errorKey = 'oldVersion'
	}

	useEffect(() => {
		// eslint-disable-next-line no-console
		if (error && (isDevelopment || isTesting)) console.error(error)

		if (error && !(isForbidden || isNotFound)) {
			const evtId = captureException(error)

			if (fullSize) formik.setFieldValue('event_id', evtId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Styles.Container $fullSize={fullSize} data-testid="app-error">
			<Flex flexDirection="column" alignItems="center" justifyContent="center">
				<Pill message="Hay una nueva Cretia disponible, visita cretia.app" />

				<h2>{t(`error.${errorKey}.title`)}</h2>
				<span
					style={{
						maxWidth: 500,
						color: 'var(--human-color--text-secondary)',
						margin: '0 auto',
						display: 'block',
					}}
				>
					{t(`error.${errorKey}.body`)}
				</span>

				{Boolean(isServer) && <img className="rat" src={errorRat} alt="" />}
				{Boolean(formik.values.event_id) && (
					<Styles.Form onSubmit={formik.handleSubmit}>
						<p>{t`error.feedback`}</p>
						<TextInput
							value={formik.values.comments}
							onChange={formik.handleChange}
							label={t`description`}
							name="comments"
							multiline
						/>
						<Button disabled={!formik.values.comments} submit small>
							{t('sendReport')}
						</Button>
					</Styles.Form>
				)}
				{Boolean(isDevelopment || isTesting) && (
					// eslint-disable-next-line no-magic-numbers
					<code>{JSON.stringify(error, null, 20)}</code>
				)}
				{Boolean(isServer) && (
					<p>
						{`${t`error.pleaseTry`} `}
						<button type="button" onClick={() => window?.location.reload()}>
							{t`error.reloadPage`}
						</button>
						{`, ${t`error.pleaseTry2`} `}
						<a href="/login">{t`error.loginAgain`}</a>
					</p>
				)}
				{Boolean(isServer) && (
					<Styles.ReportMessage>{t`error.disclaimer`}</Styles.ReportMessage>
				)}
				<AppVersion>{__APP_VERSION__}</AppVersion>
			</Flex>
		</Styles.Container>
	)
}

export default AppError
